import { SupportedChainId } from './../../constants/chain'
import { createAction } from '@reduxjs/toolkit'
import { ConnectionType } from '~/connection/type'
import { WalletType } from '~/constants/walletType'

export interface PoolSharesDetail {
  address: string
  boundCode: string
  feeFactor: number
  holdTFT: boolean
  /**
   * @deprecated do not use this field
   */
  lonBalance?: number
  power: number
  powerRatio: number
  round: number
  roundDate: number
  /**
   * xLON 等价的 LON 数量
   */
  totalEqualLon: number
}

export const updatePoolShares = createAction<{
  chainId: number
  poolShares: PoolSharesDetail
}>('user/updatePoolShares')

export const updateWalletType = createAction<WalletType | null>(
  'user/updateWalletType',
)
export const updatePendingChainId = createAction<SupportedChainId | null>(
  'user/updatePendingChainId',
)

export const updateUnstoppableDomainCallbackUri = createAction<string | null>(
  'user/updateUnstoppableDomainCallbackUri',
)

export const updateSelectedConnectionType = createAction<ConnectionType | null>(
  'user/updateSelectedConnectionType',
)

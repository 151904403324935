import { useTranslation, Trans } from 'react-i18next'
import { useModalToggle, useModalOpened } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import Modal from '~/components/Modal'
import Button from '~/components/Button/PrimaryButton'
import { LOCALES } from '~/locales'
import styled from 'styled-components'
import IconWarning from '~/assets/images/warning-black.svg'

export const IpBlockedModal = () => {
  const isOpen = useModalOpened(ApplicationModal.IP_BLOCKED)
  const toggleModal = useModalToggle(ApplicationModal.IP_BLOCKED)
  const { t, i18n } = useTranslation()
  const isZh = i18n.resolvedLanguage === LOCALES.ZH
  const lang = isZh ? 'zh-cn' : 'en-us'
  const link = `https://support.tokenlon.im/hc/${lang}/articles/35824650452116`

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={toggleModal}
      animate={false}
      contentStyle={{ maxWidth: '500px', padding: '32px' }}
    >
      <StyledContainer>
        <StyledContent>
          <StyledIcon src={IconWarning} alt="warning" />
          <StyledTitle>{t('ip_blocked_modal.title')}</StyledTitle>
          <StyledDesc>
            <Trans i18nKey="ip_blocked_modal.desc">
              抱歉，该功能无法在当前地区使用，敬请谅解。
              <a href={link} target="_blank">
                了解更多
              </a>
              。
            </Trans>
          </StyledDesc>
        </StyledContent>
        <Button
          text={t('ip_blocked_modal.btn')}
          handleClick={toggleModal}
          style={{ maxWidth: '300px', marginTop: '20px' }}
        />
      </StyledContainer>
    </Modal>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border-radius: 20px;
  background-color: #f7f7f9;

  p {
    margin: 0;
  }
`

const StyledTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #3c3c44;
`

const StyledDesc = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #81808d;

  a {
    color: ${({ theme }) => theme.colors.linkPrimary};
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.colors.linkPrimary};
    }
  }
`

const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`

import { useEffect } from 'react'
import { usePoolShareUpdater } from './hooks'

export const UserUpdater = () => {
  const updatePoolShares = usePoolShareUpdater()

  useEffect(() => {
    updatePoolShares()
  }, [updatePoolShares])

  return null
}

import { axios } from '../common'
import { BuybackFee, BuybackHistory } from './types'

export * from './types'

export const getBuybackFee = async (): Promise<BuybackFee> => {
  return axios.get(`/referral/buyback/fee`)
}

export const getBuybackHistory = async (): Promise<BuybackHistory[]> => {
  return axios.get('/referral/lon/buyback_history')
}

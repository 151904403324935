import { Dispatch } from 'redux'
import { Web3ReactHooks } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { BreakPoints } from '~/hooks'

export enum ConnectionType {
  ImTokenCallApp = 'imToken Call App',
  ImTokenWalletConnect = 'imToken Wallet Connect',
  Injected = 'Injected',
  Network = 'Network',
  WalletConnectV1 = 'WalletConnect V1',
  WalletConnectV2 = 'WalletConnect V2',
  GnosisSafe = 'Gnosis Safe',
  ImKey = 'ImKey',
  UnstoppableDomain = 'Unstoppable Domain',
  Coinbase = 'Coinbase',
  HoT = 'Home of Token',
}

export interface Connection {
  name: string
  icon?: string
  shouldDisplay(
    breakpoint: BreakPoints,
    client: {
      isDesktop: boolean
      isImTokenApp: boolean
      isMetaMaskWallet: boolean
    },
  ): boolean
  connector: Connector
  hooks: Web3ReactHooks
  type: ConnectionType
  activate?(
    connector: Connector,
    dispatch: Dispatch,
    chainId?: number,
  ): Promise<void>
}

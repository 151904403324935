import { Web3Provider } from '@ethersproject/providers'
import {
  ArbitrumOne,
  ArbitrumRinkeby,
  Base,
  BSC,
  Optimism,
  Polygon,
  zkSync,
} from '~/model/chain/ethereum'

export const NETWORK_POLLING_INTERVALS: { [chainId: number]: number } = {
  [ArbitrumOne.chainId]: 1000,
  [ArbitrumRinkeby.chainId]: 1000,
  [Base.chainId]: 100,
  [BSC.chainId]: 100,
  [Optimism.chainId]: 100,
  [Polygon.chainId]: 100,
  [zkSync.chainId]: 200,
}

export const getLibrary = (provider: any): Web3Provider => {
  const chainIdOrAny =
    typeof provider.chainId === 'number'
      ? provider.chainId
      : typeof provider.chainId === 'string'
      ? parseInt(provider.chainId, 16)
      : 'any' // @note: why use `any` https://github.com/ethers-io/ethers.js/issues/866

  const library = new Web3Provider(provider, chainIdOrAny)

  library.pollingInterval = 15_000

  library.detectNetwork().then((network) => {
    const networkPollingInterval = NETWORK_POLLING_INTERVALS[network.chainId]
    if (networkPollingInterval) {
      console.debug('Setting polling interval', networkPollingInterval)
      library.pollingInterval = networkPollingInterval
    }
  })

  return library
}

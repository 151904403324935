import { TokenlonToken, TradableToken } from '@tokenlon/sdk'
import { TokenListItem } from '@tokenlon/v6-sdk/dist/types/types/token'
import { zeroAddress } from 'viem'
import { WETH } from '~/constants/addresses'
import { isEqualIgnoreCase } from './is'

export const spotSwapTokenWrapper = (
  tokens: TokenlonToken[],
): SpotSwapToken[] => {
  return (tokens || []).map((item) => {
    const token: SpotSwapToken = {
      // @ts-ignore
      chainId: item.chainId,
      symbol: item.symbol,
      name: item.name,
      logo: item.logo,
      address: item.contractAddress,
      decimals: item.decimal,
      recommend: item.recommend,
      gasLimit: item.gasLimit,
      isOptimal: item.isOptimal,
      opposites: item.opposites,
      maxTradeAmount: item.maxTradeAmount,
      minTradeAmount: item.minTradeAmount,
      precision: item.precision,
    }
    return token
  })
}

export const v6ToSpotSwapToken = (t: TokenListItem) => {
  const token: SpotSwapToken = {
    chainId: Number(t.chainId),
    symbol: t.symbol,
    name: t.name,
    logo: t.logoURL,
    address: t.address,
    decimals: t.decimal,
    recommend: t.displayType === 1,
    slippage: t.slippage,
    crossChain: t.crossChain,
  }
  return token
}

export const v6SpotSwapTokenWrapper = (
  tokens: TokenListItem[],
): SpotSwapToken[] => {
  return tokens.map((token) => v6ToSpotSwapToken(token))
}

export const tokenlonTokenWrapper = (spotSwapToken: SpotSwapToken) => {
  const token: TokenlonToken = {
    symbol: spotSwapToken.symbol,
    name: spotSwapToken.name,
    logo: spotSwapToken.logo,
    gasLimit: spotSwapToken.gasLimit,
    contractAddress: spotSwapToken.address,
    decimal: spotSwapToken.decimals,
    isOptimal: spotSwapToken.isOptimal,
    recommend: spotSwapToken.recommend,
    opposites: spotSwapToken.opposites,
    maxTradeAmount: spotSwapToken.maxTradeAmount,
    minTradeAmount: spotSwapToken.minTradeAmount,
    precision: spotSwapToken.precision,
  }
  return token
}

export const limitSwapTokenWrapper = (
  tokens: TradableToken[],
): LimitSwapToken[] => {
  return (tokens || []).map((item) => {
    const token: LimitSwapToken = {
      chainId: item.chainId,
      symbol: item.symbol,
      name: item.name,
      logo: item.logoURI,
      address: item.address,
      decimals: item.decimals,
      blocked: item.displayType == -1,
      displayType: item.displayType,
      recommend: item.recommend,
    }
    return token
  })
}

export const findTokenByTokenSymbol = (
  tokens: SpotSwapToken[],
  tokenSymbol: string,
): SpotSwapToken => tokens?.find(({ symbol }) => symbol === tokenSymbol)

export const getOppositesByTokenSymbol = (
  tokens: SpotSwapToken[],
  tokenSymbol: string,
): SpotSwapToken[] => {
  const token = findTokenByTokenSymbol(tokens, tokenSymbol)
  const opposites = token?.opposites
  return opposites
    ?.map((symbol) => findTokenByTokenSymbol(tokens, symbol))
    ?.filter((token) => !!token)
}

export const getOppositesByToken = (
  tokens: SpotSwapToken[],
  token: SpotSwapToken,
): SpotSwapToken[] => getOppositesByTokenSymbol(tokens, token?.symbol)

export const findTokenByAddress = (tokens: SpotSwapToken[], address: string) =>
  tokens.find((token) => token.address?.toLowerCase() === address.toLowerCase())

export const compareAddress = (first: string, second: string) => {
  return first?.toLowerCase() === second?.toLowerCase()
}

export const isSameToken = (first: SpotSwapToken, second: SpotSwapToken) => {
  const isSameChain = first?.chainId === second?.chainId
  const isSameAddress = compareAddress(first?.address, second?.address)
  return isSameChain && isSameAddress
}

export const constructTokenId = (chainId, address, symbol = '') => {
  return `${chainId}-${address}-${symbol}`.toLowerCase()
}

export const getIsNativeToken = (address: string) => {
  return address?.toLowerCase() === zeroAddress.toLowerCase()
}

export const getIsWeth = (address: string, chainId: number) => {
  return address?.toLowerCase() === WETH[chainId]?.toLowerCase()
}

export const getIsETH = (address: string, symbol: string) => {
  return (
    isEqualIgnoreCase(address, zeroAddress) && isEqualIgnoreCase(symbol, 'ETH')
  )
}

import { useWeb3React } from '@web3-react/core'
import { useUnstoppedDomainName } from './useUnstoppedDomainName'

export const useDomainName = () => {
  const { ENSName } = useWeb3React()
  const unstoppableDomainName = useUnstoppedDomainName()

  return (
    [unstoppableDomainName, { name: ENSName }].find((domain) =>
      Boolean(domain.name),
    ) || {
      name: '',
    }
  )
}

/**
 * for persist serialize, use string as Wallet Type Symbol
 * instead of Connector Instance
 */
export enum WalletType {
  IMTOKEN_CALL_APP = 'imToken Call App',
  IMTOKEN_WALLET_CONNECT = 'imToken Wallet Connect',
  IMTOKEN_INJECTED = 'imToken Injected',
  INJECTED = 'MetaMask',
  METAMASK_WALLET_CONNECT = 'MetaMask Wallet Connect',
  METAMASK_MOBILE = 'METAMASK_MOBILE',
  WALLET_CONNECT = 'WalletConnect',
  IMKEY = 'imKey',
  UNSTOPPABLE_DOMAIN = 'Unstoppable Domain',
  COINBASE = 'Coinbase',
}

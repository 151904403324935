import { BigNumber } from 'ethers'

export const gasSpeedup = (value: BigNumber): BigNumber => {
  return value.mul(120).div(100)
}

/**
 * gasUsed 比 estimateGas 偏低，乘 1.1 倍
 */
export const applyGasUsedBuffer = (gasUsed: bigint): bigint => {
  return (gasUsed * 110n) / 100n
}

import ThemeProvider from '~/theme'
import { PersistGate as ReduxPersistProvider } from 'redux-persist/integration/react'
import store, { persistor } from '~/state'
import { Provider as ReduxProvider } from 'react-redux'
import { Web3Provider } from '../Web3Provider'
import { SWRConfig as SWRProvider } from 'swr'
import { WETHProvider } from '~/components/WETH/context'

const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ThemeProvider>
      <ReduxProvider store={store}>
        <ReduxPersistProvider loading={null} persistor={persistor}>
          <Web3Provider>
            <SWRProvider value={{ revalidateOnFocus: false }}>
              <WETHProvider>{children}</WETHProvider>
            </SWRProvider>
          </Web3Provider>
        </ReduxPersistProvider>
      </ReduxProvider>
    </ThemeProvider>
  )
}

export default GlobalProvider

import { useEffect, useState } from 'react'
import { createPublicClient, http, Address, isAddress } from 'viem'
import {
  mainnet,
  arbitrum,
  base,
  bsc,
  optimism,
  polygon,
  sepolia,
} from 'viem/chains'
import { getChainById } from '~/utils/chain'

const chainClients = new Map(
  [mainnet, arbitrum, base, bsc, optimism, polygon, sepolia].map((chain) => [
    chain.id,
    createPublicClient({
      chain,
      transport: http(getChainById(chain.id).rpcUrl),
    }),
  ]),
)

const getChainClient = (chainId: number) => {
  // @ts-ignore
  return chainClients.get(chainId) ?? chainClients.get(1)
}

export const useIsContractAddress = (
  address: string,
  selectedChainId: number = 1,
) => {
  const [isContractAddress, setIsContractAddress] = useState(false)

  useEffect(() => {
    let mounted = true

    const calcIsContractAddress = async () => {
      if (!address || !isAddress(address)) return

      try {
        const client = getChainClient(selectedChainId)
        const bytecode = await client.getBytecode({
          address: address as Address,
        })

        if (mounted) {
          setIsContractAddress(!!bytecode)
        }
      } catch (error) {
        console.error('Failed to check contract address:', error)
        if (mounted) {
          setIsContractAddress(false)
        }
      }
    }

    calcIsContractAddress()

    return () => {
      mounted = false
    }
  }, [address, selectedChainId])

  return isContractAddress
}

import { motion, AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import styled from 'styled-components'
import Close from '~/assets/images/close.png'
import CloseWhite from './assets/modal-close-white.svg'
import { MediaBreakPointUp } from '~/theme'
import { lockBody } from '~/utils/lockBody'
import Portal from '../Portal'

export const Modal = ({ children }) => {
  return children
}

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
}

export const Icon = styled.i<{
  url: string
  light?: boolean
}>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 2;
  transition: background-color ease-in-out 0.2s;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  background-position: center;

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url(${({ url }) => url});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &:hover {
    background-color: ${({ light }) => (light ? 'transparent' : '#f5f7fb')};
  }
`

const DefaultHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #eaecf6;
  background-color: #fff;
  margin: -1rem;
  margin-bottom: 2rem;
  border-radius: 1rem 1rem 0 0;
  padding: 12px 10px;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-left: -30px;
    margin-right: -30px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    padding: 0 30px;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
    min-width: 24px;
  }

  &.addCustomToken {
    margin-bottom: 10px;
  }

  &.switchNetworkHeader {
    margin-bottom: 20px;
  }

  &.editRecipientHeader {
    margin-bottom: 0px;
  }
`

export interface HeaderProps {
  left?: React.ReactNode
  right?: React.ReactNode
  title?: React.ReactNode
  onDismiss?: () => void
  headerClassName?: string
}
const Header: React.FC<HeaderProps> = ({
  children,
  left,
  right,
  title,
  onDismiss,
  headerClassName,
}) => {
  let content = title || children

  if (!content && !left && !right) return null

  left = left || <Icon url={Close.src} onClick={onDismiss} />

  return (
    <DefaultHeader className={headerClassName}>
      <div className="left">{left}</div>
      <div className="content">{content}</div>
      <div className="right">{right}</div>
    </DefaultHeader>
  )
}
Modal.Header = Header

const ModalClose: React.FC<{
  onDismiss: () => void
  light?: boolean
  className?: string
}> = ({ onDismiss, light, className }) => {
  return (
    <Icon
      className={className}
      light={light}
      url={light ? CloseWhite : Close}
      onClick={onDismiss}
    />
  )
}

Modal.Close = ModalClose

const Footer = styled.div``
Modal.Footer = Footer

const DefaultContent = styled(motion.div)`
  z-index: 2;
  background-color: #fff;
  width: 85vw;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 435px;

  @media screen and (max-width: ${MediaBreakPointUp.sm}) {
    width: calc(100% - 20px);
    margin: 0 10px;
    box-sizing: border-box;
  }

  &.lon-reward {
    padding: 10px;
    background: linear-gradient(180deg, #e5e0ff 0%, #ffffff 66.09%);
    max-width: 355px;
  }

  &.fee-intro {
    margin: 15px 0;
    padding: 24px 24px 20px;
    background: linear-gradient(180deg, #e5e0ff 0%, #ffffff 31.48%);
    max-width: 400px;
    overflow: auto;
  }

  &.pay-with-tokens-intro {
    max-width: 355px;
    padding: 32px 20px 20px;
    z-index: 3;
    overflow: auto;
  }

  &.permit2-intro {
    max-width: 355px;
    padding: 45px 24px 20px;
    z-index: 3;
    overflow: auto;
  }

  &.mini-set-pay-mode {
    max-width: 355px;
    padding: 32px 20px 20px;
  }

  &.lo-preview-warning {
    padding: 45px 24px 20px;
    max-width: 355px;
  }

  &.new-modal-base-content {
    padding: 32px 20px 20px;
    max-width: 368px;
  }

  &.smart-routing-details {
    background: linear-gradient(180deg, #e9e4ff 0%, #fff 23.67%);
  }

  &.switchNetworkContent {
    padding-bottom: 20px;
  }
`

export interface ContentProps {
  className?: string
  animate?: boolean
  contentStyle?: React.CSSProperties
}

const Content: React.FC<ContentProps> = ({
  children,
  className,
  contentStyle,
  animate = true,
}) => {
  const animation = animate ? dropIn : {}
  return (
    <DefaultContent
      onClick={(e) => e.stopPropagation()}
      className={className}
      variants={animation}
      initial="hidden"
      animate="visible"
      exit="exit"
      style={contentStyle}
    >
      {children}
    </DefaultContent>
  )
}
Modal.Content = Content

export interface OverlayProps {
  className?: string
  animate?: boolean
  onDismiss?: () => void
}

const DefaultOverlay = styled(motion.div)`
  background: radial-gradient(
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.6)
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 101;
`
const Overlay: React.FC<OverlayProps> = ({
  children,
  className,
  onDismiss,
  animate = true,
}) => {
  const animation = animate
    ? {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
      }
    : {}
  const onEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onDismiss && onDismiss()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', onEsc)

    return () => {
      document.removeEventListener('keydown', onEsc)
    }
  }, [])
  return (
    <DefaultOverlay onClick={onDismiss} className={className} {...animation}>
      {children}
    </DefaultOverlay>
  )
}
Modal.Overlay = Overlay

export interface DefaultModalProps {
  isOpen: boolean
  onDismiss: () => any
  animate?: boolean
  title?: string
  left?: React.ReactNode
  contentClassName?: string
  contentStyle?: React.CSSProperties
  headerClassName?: string
}

const DefaultModal: React.FC<DefaultModalProps> = ({
  isOpen,
  onDismiss,
  children,
  title = '',
  left,
  animate = true,
  contentClassName,
  contentStyle,
  headerClassName,
}) => {
  useEffect(() => {
    lockBody(isOpen)
  }, [isOpen])

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <Modal.Overlay onDismiss={onDismiss} animate={animate}>
            <Modal.Content
              animate={animate}
              className={contentClassName}
              contentStyle={contentStyle}
            >
              <Modal.Header
                title={title}
                left={left}
                onDismiss={onDismiss}
                headerClassName={headerClassName}
              />
              {children}
            </Modal.Content>
          </Modal.Overlay>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export const SeamlessModal: React.FC<DefaultModalProps> = ({
  isOpen,
  onDismiss,
  children,
  title = '',
  animate = true,
  contentClassName,
}) => {
  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <Modal.Content animate={animate} className={contentClassName}>
            <Modal.Header title={title} onDismiss={onDismiss} />
            {children}
          </Modal.Content>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default DefaultModal

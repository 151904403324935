import styled from 'styled-components'
import {
  useModalOpened,
  useModalState,
  useModalToggle,
  useApprovalModalStateDispatch,
} from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import { Modal } from '../Modal'
import Portal from '../Portal'
import TokenLogo from '../TokenLogo'
import { ApproveStatusLabel } from './ApproveStatusLabel'
import { useEffect } from 'react'
import { lockBody } from '~/utils/lockBody'
import { ApprovalProgress } from './ApproveStatusLabel'

export const ApprovalModal = () => {
  const isOpen = useModalOpened(ApplicationModal.APPROVAL_STATUS)
  const updateApprovalState = useApprovalModalStateDispatch()
  const toggleApprovalModal = useModalToggle(ApplicationModal.APPROVAL_STATUS)
  const {
    token,
    showLogo,
    title,
    description1,
    description2,
    progress,
  } = useModalState(ApplicationModal.APPROVAL_STATUS)

  const onApprovalDismiss = () => {
    toggleApprovalModal()
  }

  useEffect(() => {
    lockBody(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (isOpen && progress === ApprovalProgress.APPROVE_SUCCESS) {
      const timeoutId = setTimeout(() => {
        toggleApprovalModal()
        updateApprovalState({
          progress: ApprovalProgress.APPROVING,
        })
      }, 2000)

      return () => clearTimeout(timeoutId)
    }
  }, [progress, isOpen])

  return (
    <Portal>
      {isOpen && (
        <Modal.Overlay onDismiss={onApprovalDismiss}>
          <StyledApprovalContent>
            <div className="token-info">
              <StyledIconClose light onDismiss={onApprovalDismiss} />
              <h2>{title}</h2>
              <p>{description1}</p>
              <p>{description2}</p>
              {showLogo && (
                <div className="token-logo">
                  <TokenLogo
                    logo={token?.logo}
                    symbol={token?.symbol}
                    size={50}
                  />
                  <span className="logo-symbol">{token?.symbol}</span>
                </div>
              )}
            </div>
            <ApproveStatusLabel />
          </StyledApprovalContent>
        </Modal.Overlay>
      )}
    </Portal>
  )
}

const StyledApprovalContent = styled(Modal.Content)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: rgb(38, 44, 75);
  width: 360px;
  padding: 0;

  .token-info {
    padding: 30px 25px 0;
    margin-bottom: 20px;
  }

  h2 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 20px;
  }

  p {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    color: var(--i-14, #9c9fb4);
  }

  .token-logo {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;
  }

  span {
    &.logo-symbol {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
`

const StyledIconClose = styled(Modal.Close)`
  position: absolute;
  right: 18px;
  top: 18px;
  height: 12px;
  width: 12px;
  cursor: pointer;

  &::after {
    content: '';
    width: 12px;
    height: 12px;
  }
`

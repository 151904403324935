import { useWeb3React } from '@web3-react/core'
import { useInterfaceMulticall } from '~/hooks/useContract'
import { useBlockNumber } from '~/state/application/hooks'
import { multicall } from './instance'

// Create Updater wrappers that pull needed info from store
export default function Updater() {
  const latestBlockNumber = useBlockNumber()
  const { chainId } = useWeb3React()
  const multicall2Contract = useInterfaceMulticall()
  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={multicall2Contract}
    />
  )
}

import { captureExceptionWithLabel } from '~/utils/captureExceptionWithLabel'
import { Profile } from '@tokenlon/sdk'
import { useCallback } from 'react'
import { Mainnet, Sepolia } from '~/model/chain/ethereum'
import { AppState } from '..'
import { useAppDispatch, useAppSelector } from '../hooks'
import { updatePoolShares } from './actions'
import { useWeb3React } from '@web3-react/core'

export const usePoolShareUpdater = () => {
  const { account, chainId } = useWeb3React()
  const dispatch = useAppDispatch()

  return useCallback(async () => {
    if (!account || !chainId) return
    const supportedChainIds = [Mainnet.chainId, Sepolia.chainId]
    // @todo SDK support ArbitrumOne chain
    if (!supportedChainIds.includes(chainId)) return
    try {
      const poolShares = await Profile.getReferralPool({
        address: account,
        chainId: chainId,
      })
      dispatch(updatePoolShares({ chainId, poolShares }))
    } catch (error) {
      captureExceptionWithLabel('update pool shares error', error)
    }
  }, [account, chainId, dispatch])
}

export const useTotalEqualLon = () => {
  const { chainId } = useWeb3React()
  return useAppSelector(
    (state: AppState) => state.user.poolShares[chainId]?.totalEqualLon ?? 0,
  )
}

export const useFeeFactor = (chainId?: number) => {
  const { chainId: currentChainId } = useWeb3React()
  const targetChainId = chainId ?? currentChainId

  return useAppSelector(
    (state: AppState) => state.user.poolShares[targetChainId]?.feeFactor ?? 30,
  )
}

export const useConnectType = () => {
  return useAppSelector((state: AppState) => state.user.connectWallet)
}

export const useSelectedConnectionType = () => {
  return useAppSelector((state: AppState) => state.user.selectedConnectionType)
}

export const usePendingChainId = () => {
  return useAppSelector((state: AppState) => state.user.pendingChainId)
}

export const useUnstoppedDomainCallbackUri = () => {
  return useAppSelector(
    (state: AppState) => state.user.unstoppableDomainCallbackUri || '/',
  )
}

import useSWR from 'swr'
import { axios } from '~/apis/common'

interface TxDataSummary {
  successRate: number
  totalTxs: number
  totalVolume: number
  totalWallets: number
}

const fallbackData: TxDataSummary = {
  successRate: 0.9963,
  totalTxs: 1024653,
  totalVolume: 27984252942.54,
  totalWallets: 230383,
}

const getTxDataSummary = async (): Promise<TxDataSummary> => {
  return await axios.get(`/referral/tx_data_summary`)
}

export const useTxDataSummary = () => {
  const { data, error } = useSWR('useTxDataSummary', getTxDataSummary)

  if (error) {
    console.error(error)
    return fallbackData
  }

  if (!data) return fallbackData

  return data
}

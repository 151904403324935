import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { TokenlonToken } from '@tokenlon/sdk'
import { getAddress } from 'ethers/lib/utils'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { TradeDirection } from '~/components/Exchange/types'
import { ENV, RUNTIME_ENV } from '~/constants/env'
import { AppConfig } from '@tokenlon/v6-sdk'
import {
  addCustomLimitTokens,
  addCustomSpotTokens,
  addLimitTokens,
  addSpotTokens,
  removeTokensPriceList,
  updateConfig,
  updateV6Config,
  updateLimitOrderSubmitStatus,
  updateLimitOrderUserConfig,
  updateSpotSwapUserConfig,
  updateLimitSwapAmount,
  updateLimitSwapPair,
  updateLimitSwapPairAmount,
  updateLimitSwapLimitPrice,
  updateLimitSwapPairToken,
  updateSpotSwapAmount,
  updateSpotSwapPair,
  updateSpotSwapPairAmount,
  updateSpotSwapPairToken,
  updateTokensPrice,
  updateTokensPriceList,
  updateSpotSwapUserTradeStatus,
  updateSpotSwapRecommendTokens,
  updateIsDisableTrade,
} from './actions'
import {
  LimitOrderExpiration,
  LimitOrderSubmitStatus,
  LimitOrderUserConfig,
  PriceInfo,
  SpotSwapUserConfig,
  SpotSwapUserTradeStatus,
} from './type'

export interface DexState {
  spotTokens: {
    [chainId: number]: {
      [tokenAddress: string]: SpotSwapToken
    }
  }
  limitTokens: {
    [chainId: number]: {
      [tokenAddress: string]: LimitSwapToken
    }
  }
  // 这里的 user 指的是 client，不和 Wallet 绑定，地址切换后，也应该能看到
  // 通过另外一个钱包添加的 token
  userCustomSpotTokens: {
    [chainId: number]: {
      [tokenAddress: string]: SpotSwapToken
    }
  }
  userCustomLimitTokens: {
    [chainId: number]: {
      [tokenAddress: string]: LimitSwapToken
    }
  }
  config: {
    [chainId: number]: TokenlonConfig
  }
  v6Config: AppConfig
  prices: {
    [chainId: number]: {
      [tokenAddress: string]: PriceInfo
    }
  }
  exchangePair: [TokenlonToken, TokenlonToken] | []
  spotSwapPair: [SpotSwapToken, SpotSwapToken] | []
  limitSwapPair: [LimitSwapToken, LimitSwapToken] | []
  spotSwapInputAmount: string
  spotSwapOutputAmount: string
  limitSwapInputAmount: string
  limitSwapOutputAmount: string
  limitSwapLimitPrice: string
  limitOrderSubmitStatus: LimitOrderSubmitStatus
  limitOrderUserConfig: LimitOrderUserConfig
  spotSwapUserConfig: SpotSwapUserConfig
  spotSwapUserTradeStatus: SpotSwapUserTradeStatus
  spotSwapRecommendTokens: SpotSwapToken[]
  isDisableTrade: boolean
}

export const initialState: DexState = {
  spotTokens: {},
  limitTokens: {},
  userCustomSpotTokens: {},
  userCustomLimitTokens: {},
  exchangePair: [null, null],
  spotSwapPair: [null, null],
  limitSwapPair: [null, null],
  limitSwapInputAmount: '',
  limitSwapOutputAmount: '',
  limitSwapLimitPrice: '',
  spotSwapInputAmount: '',
  spotSwapOutputAmount: '',
  prices: {},
  config: {},
  v6Config: {
    networkConfig: [],
    sanity: '0',
    slippageConfig: {
      min: 0.1,
      max: 1,
      step: 0.1,
      default: 0.5,
    },
  },
  limitOrderSubmitStatus: LimitOrderSubmitStatus.Idle,
  limitOrderUserConfig: {
    expiration: LimitOrderExpiration.Day7,
    isInputRateDirection: true,
  },
  spotSwapUserConfig: {
    sendByRelayer: false,
    isCrossSwap: true,
    recipient: '',
  },
  spotSwapUserTradeStatus: SpotSwapUserTradeStatus.NORMAL,
  spotSwapRecommendTokens: [],
  isDisableTrade: false,
}

export const dexReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(addSpotTokens, (state, { payload: { tokens, chainId } }) => {
      if (!state.spotTokens) state.spotTokens = {}
      state.spotTokens[chainId] = state.spotTokens[chainId] || {}
      tokens?.forEach((token) => {
        state.spotTokens[chainId][token.address] = token
      })
    })
    .addCase(addCustomSpotTokens, (state, { payload: { tokens, chainId } }) => {
      if (!state.userCustomSpotTokens) state.userCustomSpotTokens = {}
      state.userCustomSpotTokens[chainId] =
        state.userCustomSpotTokens[chainId] || {}
      tokens?.forEach((token) => {
        state.userCustomSpotTokens[chainId][token.address] = token
      })
    })
    .addCase(addLimitTokens, (state, { payload: { tokens, chainId } }) => {
      if (!state.limitTokens) state.limitTokens = {}
      state.limitTokens[chainId] = state.limitTokens[chainId] || {}
      tokens?.forEach((token) => {
        state.limitTokens[chainId][token.address] = token
      })
    })
    .addCase(
      addCustomLimitTokens,
      (state, { payload: { tokens, chainId } }) => {
        if (!state.userCustomLimitTokens) state.userCustomLimitTokens = {}
        state.userCustomLimitTokens[chainId] =
          state.userCustomLimitTokens[chainId] || {}
        tokens?.forEach((token) => {
          state.userCustomLimitTokens[chainId][token.address] = token
        })
      },
    )
    .addCase(
      updateSpotSwapPairToken,
      (state, { payload: { direction, token } }) => {
        if (!state.spotSwapPair) state.spotSwapPair = []
        if (direction === TradeDirection.Input) state.spotSwapPair[0] = token
        if (direction === TradeDirection.Output) state.spotSwapPair[1] = token
      },
    )
    .addCase(
      updateSpotSwapPair,
      (state, { payload: { inputToken, outputToken } }) => {
        state.spotSwapPair = [inputToken, outputToken]
      },
    )
    .addCase(
      updateLimitSwapPairToken,
      (state, { payload: { direction, token } }) => {
        if (!state.limitSwapPair) state.limitSwapPair = []
        if (direction === TradeDirection.Input) state.limitSwapPair[0] = token
        if (direction === TradeDirection.Output) state.limitSwapPair[1] = token
      },
    )
    .addCase(
      updateLimitSwapAmount,
      (state, { payload: { direction, amount } }) => {
        if (direction === TradeDirection.Input)
          state.limitSwapInputAmount = amount
        if (direction === TradeDirection.Output)
          state.limitSwapOutputAmount = amount
      },
    )
    .addCase(updateLimitSwapLimitPrice, (state, { payload }) => {
      state.limitSwapLimitPrice = payload
    })
    .addCase(
      updateSpotSwapAmount,
      (state, { payload: { direction, amount } }) => {
        if (direction === TradeDirection.Input)
          state.spotSwapInputAmount = amount
        if (direction === TradeDirection.Output)
          state.spotSwapOutputAmount = amount
      },
    )
    .addCase(
      updateSpotSwapPairAmount,
      (state, { payload: { inputAmount, outputAmount } }) => {
        state.spotSwapInputAmount = inputAmount
        state.spotSwapOutputAmount = outputAmount
      },
    )
    .addCase(
      updateLimitSwapPairAmount,
      (state, { payload: { inputAmount, outputAmount } }) => {
        state.limitSwapInputAmount = inputAmount
        state.limitSwapOutputAmount = outputAmount
      },
    )
    .addCase(
      updateLimitSwapPair,
      (state, { payload: { inputToken, outputToken } }) => {
        state.limitSwapPair = [inputToken, outputToken]
      },
    )
    .addCase(updateConfig, (state, { payload: { chainId, config } }) => {
      if (!state.config) state.config = {}

      state.config[chainId] = config
    })
    .addCase(updateV6Config, (state, { payload: { v6Config } }) => {
      state.v6Config = v6Config
    })
    .addCase(updateLimitOrderSubmitStatus, (state, { payload }) => {
      state.limitOrderSubmitStatus = payload
    })
    .addCase(updateLimitOrderUserConfig, (state, { payload }) => {
      if (!state.limitOrderUserConfig)
        state.limitOrderUserConfig = {} as LimitOrderUserConfig

      Object.keys(payload).forEach((key) => {
        state.limitOrderUserConfig[key] = payload[key]
      })
    })
    .addCase(updateSpotSwapUserConfig, (state, { payload }) => {
      if (!state.spotSwapUserConfig)
        state.spotSwapUserConfig = {} as SpotSwapUserConfig

      Object.keys(payload).forEach((key) => {
        state.spotSwapUserConfig[key] = payload[key]
      })
    })
    .addCase(updateSpotSwapRecommendTokens, (state, { payload }) => {
      state.spotSwapRecommendTokens = payload
    })
    .addCase(
      updateTokensPrice,
      (
        state,
        { payload: { marketPrices, chainId, blockNumber, currency } },
      ) => {
        if (!state.prices) state.prices = {}
        if (!state.prices[chainId]) state.prices[chainId] = {}

        marketPrices
          .filter((p) => Boolean(p.address))
          .forEach((p) => {
            if (!state.prices[chainId][getAddress(p.address)])
              state.prices[chainId][getAddress(p.address)] = {}

            let price = p.price || 0

            if (!price && (ENV.development || ENV.local)) {
              price = (Number(p.address) % 100) + new Date().getMinutes()
            }
            state.prices[chainId][getAddress(p.address)][currency] = {
              price: String(price),
              lastUpdateBlockNumber: blockNumber,
            }
          })
      },
    )
    .addCase(
      updateTokensPriceList,
      (state, { payload: { chainId, addresses, currency } }) => {
        if (!state.prices) state.prices = []
        if (!state.prices[chainId]) state.prices[chainId] = {}

        addresses.forEach((addr) => {
          const address = getAddress(addr)
          if (state.prices[chainId][address]) return
          state.prices[chainId][address] = {
            [currency]: {},
          }
        })
      },
    )
    .addCase(
      removeTokensPriceList,
      (state, { payload: { addresses, chainId, currency } }) => {
        if (!state.prices) return
        if (!state.prices[chainId]) return

        addresses.forEach((addr) => {
          const address = getAddress(addr)
          if (state.prices[chainId][address]) {
            delete state.prices[chainId][address]
          }
        })
      },
    )
    .addCase(updateSpotSwapUserTradeStatus, (state, { payload: status }) => {
      if (!state.spotSwapUserTradeStatus)
        state.spotSwapUserTradeStatus = SpotSwapUserTradeStatus.NORMAL
      state.spotSwapUserTradeStatus = status
    })
    .addCase(updateIsDisableTrade, (state, { payload }) => {
      state.isDisableTrade = payload
    })
    .addMatcher(
      isAnyOf(updateLimitSwapPair, updateLimitSwapPairToken),
      (state) => {
        state.limitSwapInputAmount = initialState.limitSwapInputAmount
        state.limitSwapOutputAmount = initialState.limitSwapOutputAmount
      },
    )
    .addMatcher(
      isAnyOf(updateSpotSwapPair, updateSpotSwapPairToken),
      (state) => {
        state.spotSwapInputAmount = initialState.spotSwapInputAmount
        state.spotSwapOutputAmount = initialState.spotSwapOutputAmount
      },
    ),
)

export const dexPersistConfig: PersistConfig<DexState> = {
  key: `sub-dex-${RUNTIME_ENV}`,
  storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: [
    'spotSwapInputAmount',
    'spotSwapOutputAmount',
    'limitSwapInputAmount',
    'limitSwapOutputAmount',
    'limitSwapLimitPrice',
    'limitOrderSubmitStatus',
    'limitOrderUserConfig',
  ],
}

export default persistReducer(dexPersistConfig, dexReducer)

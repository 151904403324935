import { NextPage } from 'next'
import { AppProps } from 'next/app'
import '~/i18n'
import ApplicationUpdater from '~/state/application/updater'
import Metas from '~/components/Metas'
import InfoModal from '~/components/InfoModal'
import MulticallUpdater from '~/state/multicall/updater'
import DexUpdater from '~/state/dex/updater'
import { ApprovalModal } from '~/components/ApprovalModal'
import { AllowanceListModal } from '~/components/InfoModal/AllowanceList'
import { TransactionUpdater } from '~/state/transactions/updater'
import { UserUpdater } from '~/state/user/updater'
import { globalTxErrorHandler } from '~/utils/handleTxError'
import { TokenPriceUpdater } from '~/hooks/useTokenPrice'
import { GlobalStyle } from '~/theme/GlobalStyle'
import { ToolModal } from '~/components/ToolModal'
import '~/sentry'
import '~/components/Message/Message.css'
import GlobalProvider from '~/components/GlobalProvider'
import WalletModal from '~/components/WalletModal'
import SwitchNetworkModal from '~/components/NetworkListModal/SwitchNetworkModal'
import SwitchNetworkWarningModal from '~/components/NetworkListModal/SwitchNetworkWarningModal'
import { IpBlockedModal } from '~/components/Modals'

globalTxErrorHandler()

const Updaters = () => {
  return (
    <>
      <ApplicationUpdater />
      <MulticallUpdater />
      <TransactionUpdater />
      <DexUpdater />
      <UserUpdater />
      <TokenPriceUpdater />
    </>
  )
}

const Pops = () => {
  return (
    <>
      <WalletModal />
      <InfoModal />
      <AllowanceListModal />
      <ApprovalModal />
      <ToolModal />
      <SwitchNetworkModal />
      <SwitchNetworkWarningModal />
      <IpBlockedModal />
    </>
  )
}

const Tokenlon: NextPage<AppProps> = ({ Component, pageProps }) => {
  const { openGraphData = [] } = pageProps

  return (
    <>
      <Metas openGraphData={openGraphData} />
      <GlobalStyle />
      <GlobalProvider>
        <Updaters />
        <Component {...pageProps} />
        <Pops />
      </GlobalProvider>
    </>
  )
}

export default Tokenlon

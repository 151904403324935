import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  useModalOpened,
  useModalSwitch,
  useModalToggle,
} from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import Modal from '../Modal'
import { ReactComponent as ArrowIcon } from '~/assets/images/arrow-right-gray.svg'
import { ReactComponent as WrapETHIcon } from '~/assets/images/wrap-eth.svg'
import { Box, Column, Row } from '../Layout'
import { useGA } from '~/hooks'

export const ToolModal = () => {
  const { t } = useTranslation()
  const toggle = useModalToggle(ApplicationModal.TOOL)
  const switchWrap = useModalSwitch(ApplicationModal.WETH_WRAP)
  const isOpen = useModalOpened(ApplicationModal.TOOL)

  return (
    <>
      <Modal
        title={t('tool')}
        isOpen={isOpen}
        onDismiss={toggle}
        animate={false}
      >
        <Box maxHeight="70vh" minHeight="400px" width="100%" align="flex-start">
          <ToolItem
            icon={<WrapETHIcon />}
            title={t('wrap_eth')}
            desc={t('wrap_eth_desc')}
            onClick={() => switchWrap(true)}
          />
        </Box>
      </Modal>
    </>
  )
}

const StyledTool = styled.div`
  border: 1px solid #eff0fa;
  border-radius: 48px;
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: rgba(239, 240, 250, 0.4);
  }

  p {
    margin: 0;
  }

  .title {
    color: #000000;
    font-weight: 500;
    font-size: 15px;
  }
  .desc {
    color: #9598ab;
    font-weight: 400;
    font-size: 13px;
  }
`

const StyledToolArrow = styled(ArrowIcon)`
  margin-left: auto;
`

interface IToolItem {
  icon?: React.ReactNode
  title: string
  desc?: string
  onClick?: () => void
}

const ToolItem: React.FC<IToolItem> = ({ icon, title, desc, onClick }) => {
  const { trackElClickCurring } = useGA()
  return (
    <StyledTool
      data-trackid="webtl_instant_tool_detail"
      data-trackdata={JSON.stringify({ title })}
      onClick={(e) => trackElClickCurring(e)(onClick)}
    >
      <Row>
        <Box width="auto" mr="15px">
          {icon}
        </Box>
        <Column>
          <div className="title">{title}</div>
          <p className="desc">{desc}</p>
        </Column>
      </Row>
      <StyledToolArrow />
    </StyledTool>
  )
}

import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect } from 'react'
import { now } from '~/utils/now'
import { retry, RetryableError, RetryOptions } from '~/utils/retry'
import { getChainById } from '~/utils/chain'
import { useBlockNumber } from '../application/hooks'
import { updateBlockNumber } from '../application/reducer'
import { useAppDispatch } from '../hooks'
import { usePoolShareUpdater } from '../user/hooks'
import { checkedTransaction, finalizeTransaction } from './actions'
import { useAllTransactions } from './hooks'

const DEFAULT_RETRY_OPTIONS: RetryOptions = { n: 1, minWait: 0, maxWait: 0 }

export const TransactionUpdater = () => {
  const { chainId, provider } = useWeb3React()
  const latestBlockNumber = useBlockNumber()

  const dispatch = useAppDispatch()
  const allTransactions = useAllTransactions()
  const updatePoolShares = usePoolShareUpdater()

  const getReceipt = useCallback(
    (hash: string) => {
      if (!provider || !chainId)
        throw new Error('No library or chainId provided')

      const retryOptions =
        getChainById(chainId)?.pendingTransactionsRetryOptions ??
        DEFAULT_RETRY_OPTIONS
      return retry(
        () =>
          provider.getTransactionReceipt(hash).then((receipt) => {
            if (receipt === null) {
              console.info('retry for hash', hash)
              throw new RetryableError()
            }
            return receipt
          }),
        retryOptions,
      )
    },
    [chainId, provider],
  )

  useEffect(() => {
    if (!chainId || !provider || !latestBlockNumber) return

    const cancels = Object.keys(allTransactions)
      .filter((hash) => shouldCheckTx(latestBlockNumber, allTransactions[hash]))
      .map((hash) => {
        const { promise, cancel } = getReceipt(hash)
        promise
          .then((receipt) => {
            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                }),
              )

              // update pool shares
              updatePoolShares()

              if (receipt.blockNumber > latestBlockNumber) {
                dispatch(
                  updateBlockNumber({
                    chainId,
                    blockNumber: receipt.blockNumber,
                  }),
                )
              }
            } else {
              dispatch(
                checkedTransaction({
                  chainId,
                  hash,
                  blockNumber: latestBlockNumber,
                }),
              )
            }
          })
          .catch((error) => {
            if (!error.isCancelledError) {
              console.error(`Failed to check transaction hash: ${hash}`, error)
            }
          })
        return cancel
      })
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [
    allTransactions,
    chainId,
    dispatch,
    latestBlockNumber,
    provider,
    getReceipt,
  ])

  return null
}

interface TxInterface {
  addedTime: number
  receipt?: Record<string, any>
  lastCheckedBlockNumber?: number
}

export const shouldCheckTx = (
  latestBlockNumber: number,
  tx: TxInterface,
): boolean => {
  // already done
  if (tx.receipt) return false

  // never checked
  if (!tx.lastCheckedBlockNumber) return true

  const blocksSinceLastCheck = latestBlockNumber - tx.lastCheckedBlockNumber

  if (blocksSinceLastCheck < 1) return false
  const minutesPending = now() - tx.addedTime / 1000 / 60

  // 打包超过 60/5 分钟以上，可能矿工费给很低，检查次数调低
  if (minutesPending > 60) {
    return blocksSinceLastCheck > 9
  } else if (minutesPending > 5) {
    return blocksSinceLastCheck > 3
  } else {
    // 其他情况，每个区块检查一遍
    return true
  }
}

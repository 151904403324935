import { useTranslation } from 'react-i18next'
import { useGA } from '~/hooks'
import { StyledAgreement } from './styled'

export const Agreement: React.FC = () => {
  const { t } = useTranslation()
  const { trackElClick } = useGA()
  return (
    <StyledAgreement>
      <span className="agreement-info">
        <span>{t('rules_label')}&nbsp;</span>
        <a
          href="/tos"
          target="_blank"
          data-trackid="webtl_walletconnect_agreement"
          onClick={trackElClick}
        >
          {t('user_rules')}
        </a>
      </span>
    </StyledAgreement>
  )
}

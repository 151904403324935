import { useMemo } from 'react'
import styled from 'styled-components'
import { AddressZero } from '@ethersproject/constants'
import { useNativeTokenBalance, useTokenBalance } from '~/state/wallet/hooks'
import { useWeb3React } from '@web3-react/core'
import { useToken } from '~/hooks/useToken'
import { Currency, FixedPointNumber } from '~/utils/fixedPoint'
import { useTokenPrice } from '~/hooks/useTokenPrice'

export const StyledTokenInfo = styled.div`
  background: #fafafc;
  border: 1px solid #eff0fa;
  box-sizing: border-box;
  border-radius: 48px;
  padding: 16px 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .logo {
    width: 28px;
    margin-right: 10px;
  }
  .symbol {
    color: #000000;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
  }
  .info {
    margin-top: 2px;
    color: #9598ab;
    font-size: 11px;
    line-height: 15px;
  }
  .asset {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    text-align: right;
  }
  .balance {
    color: #5f4dbc;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
  }
  .value {
    color: #9598ab;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
  }
`
export interface TokenInfoProps {
  icon: string
  addr?: string
  balance?: string
  value?: string
  symbol?: string
  info?: string | number
}

export const TokenInfo: React.FC<TokenInfoProps> = ({
  icon,
  addr,
  symbol,
  info,
}) => {
  const token = useToken(addr)
  const { account } = useWeb3React()
  const tokenBalance = useTokenBalance(account, token)
  const ethBalance = useNativeTokenBalance()
  const price = useTokenPrice(addr)

  const balance = useMemo(
    () => (addr === AddressZero ? ethBalance : tokenBalance),
    [tokenBalance, ethBalance, addr],
  )

  return (
    <StyledTokenInfo>
      <img className="logo" src={icon} alt="" />
      <span className="symbol">
        <span>{symbol}</span>
        {info && <span className="info">{info}</span>}
      </span>
      <div className="asset">
        <span className="balance">{balance?.toDecimalsFixed(4)}</span>
        <span className="value">
          {balance
            ? new FixedPointNumber(
                balance?.times(price),
                token?.decimals,
              )?.toCurrency(Currency.USD)
            : ''}
        </span>
      </div>
    </StyledTokenInfo>
  )
}

import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import useSWR from 'swr'
import { axios } from '~/apis/common'
import { API_ROOT_DOMAIN } from '~/constants/rootDomain'
import {
  ArbitrumOne,
  ArbitrumRinkeby,
  Mainnet,
  Sepolia,
} from '~/model/chain/ethereum'
import { useRefreshToken } from '~/state/application/hooks'

export enum LimitOrderStatus {
  Pending = 'pending',
  PartialFilled = 'partialfilled',
  Fulfilled = 'fulfilled',
  Cancelled = 'cancelled',
  Expired = 'expired',
}

// @TODO use sdk type define
export interface LimitOrderItem {
  id: number
  chainId: number
  expiry: number
  maker: string
  makerToken: string
  makerTokenAmount: string
  orderHash: string
  salt: string
  status: LimitOrderStatus
  taker: string
  takerToken: string
  takerTokenAmount: string
  makerSig: string
  createdAt: string
  updatedAt: string
  cancelledAt: string
  remainQuota: string
  trades?: Trade[]

  // frontend defined
  progress?: string
  remainInputAmountToDeal?: string
}

export interface Trade {
  makerTokenFilledAmount: string
  status: 'success' | string
  takerTokenFilledAmount: string
  [key: string]: any
}

export const useUserLimitOrderList = (status: LimitOrderStatus[]) => {
  const { chainId, account } = useWeb3React()
  const refreshToken = useRefreshToken()
  const { data, error } = useSWR<LimitOrderItem[]>(
    [chainId, status, account, refreshToken],
    () => {
      if (
        account &&
        [
          ArbitrumOne.chainId,
          ArbitrumRinkeby.chainId,
          Mainnet.chainId,
          Sepolia.chainId,
        ].includes(chainId)
      ) {
        return axios.get<null, LimitOrderItem[]>(
          `https://api.${API_ROOT_DOMAIN}/v5/limit-order/limitorder`,
          {
            params: {
              status: status.join(','),
              maker: account,
              chainId: chainId,
            },
          },
        )
      }
      return Promise.resolve([])
    },
    { refreshInterval: 15000 },
  )

  if (data) {
    data.forEach((item) => {
      item.progress = '0'

      item.remainInputAmountToDeal = item.makerTokenAmount

      if (item.trades && item.trades.length > 0) {
        const filledTrades = item.trades.filter((t) => t.status === 'success')
        const filledTakerAmount = filledTrades.reduce(
          (prev, curr) => prev.plus(curr.takerTokenFilledAmount || '0'),
          new BigNumber(0),
        )
        const filledMakerAmount = filledTrades.reduce(
          (prev, curr) => prev.plus(curr.makerTokenFilledAmount || '0'),
          new BigNumber(0),
        )

        const progress = filledTakerAmount
          .multipliedBy(100)
          .dividedBy(item.takerTokenAmount)
          .toFormat(2)
        item.progress = progress
        item.remainInputAmountToDeal = new BigNumber(item.makerTokenAmount)
          .minus(filledMakerAmount)
          .toString()
      }
    })
  }

  return { data, error }
}

import { Connector } from '@web3-react/types'
import { getChainById, isSupportedChain } from './chain'
import { hexValue } from 'ethers/lib/utils'
import { WalletConnectV2 } from '~/connection/WalletConnectV2'
import { WalletConnectV1 } from '~/connection/WalletConnectV1'
import { Network } from '@web3-react/network'
import { ImKeyConnect } from '~/connection/ImKey'

export const switchChain = async (connector: Connector, chainId: number) => {
  if (!isSupportedChain(chainId)) {
    throw new Error('Unsupported chain')
  } else if (
    connector.provider instanceof WalletConnectV2 ||
    connector instanceof WalletConnectV2 ||
    connector.provider instanceof WalletConnectV1 ||
    connector instanceof WalletConnectV1 ||
    connector.provider instanceof Network
  ) {
    await connector.activate(chainId)
  } else {
    const chain = getChainById(chainId)
    const addChainParams: AddEthereumChainParameter = {
      chainId,
      blockExplorerUrls: [chain.explorer],
      chainName: chain.chainName,
      // @todo: @izayl move all nativeCurrency info to Chain type define
      nativeCurrency: {
        name: chain.nativeSymbol,
        symbol: chain.nativeSymbol,
        decimals: 18,
      },
      rpcUrls: [chain.publicRpcUrl ?? chain.rpcUrl],
    }

    if (connector.provider instanceof ImKeyConnect) {
      await connector.provider.changeChain({
        ...addChainParams,
        chainId: hexValue(chainId),
      })
    } else {
      await connector.activate(addChainParams)
    }
  }
}

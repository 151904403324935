import { Connector } from '@web3-react/types'
import { CONNECTIONS } from '.'
import { ConnectionType } from './type'

export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = CONNECTIONS.find(
      (connection) => connection.connector === c,
    )
    if (!connection) {
      throw new Error('Unsupported connector')
    }
    return connection
  }

  const connection = CONNECTIONS.find((connection) => connection.type === c)

  return connection
}

export function getConnectionName(connectionType: ConnectionType) {
  switch (connectionType) {
    case ConnectionType.Injected:
      return 'MetaMask'
    case ConnectionType.WalletConnectV1:
      return 'WalletConnect V1'
    case ConnectionType.WalletConnectV2:
      return 'WalletConnect'
    case ConnectionType.Coinbase:
      return 'Coinbase Wallet'
    case ConnectionType.GnosisSafe:
      return 'Gnosis Safe'
    case ConnectionType.Network:
      return 'Network'
    case ConnectionType.HoT:
      return 'Home of Token'
  }
}

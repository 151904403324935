import { useEffect, useState } from 'react'
import { UAuthConnector } from '@uauth/web3-react'
import { useWeb3React } from '@web3-react/core'
import { getConnection } from '~/connection/utils'
import { unstoppableDomainConnection } from '~/connection'

export const useUnstoppedDomainName = () => {
  const { account, connector } = useWeb3React()
  const [domain, setDomain] = useState({ name: '', avatarUrl: '' })
  const connection = getConnection(connector)
  const getDomainName = async () => {
    // @todo implement UAuthConnector
    // @ts-ignore
    if (account && connector && connection === unstoppableDomainConnection) {
      const { sub, picture } = await (connector as UAuthConnector).uauth.user()
      // @TODO test picture
      setDomain({ name: sub, avatarUrl: picture })
    } else {
      setDomain({ name: '', avatarUrl: '' })
    }
  }

  useEffect(() => {
    getDomainName()
  }, [account, connector])

  return domain
}

/**
 * # 用来处理数字/浮点数相关的工具函数
 *
 * 统一使用类型为 `import type { BigNumber } from "BigNumber.js"` 的 BigNumber 来处理数字
 *
 * ## why not `JSBI`, `big.js`, `bn.js`, `decimals.js`, `jsbn` or native `BigInt`?
 *
 * `big.js`, `bignumber.js`, `decimals.js` 可以支持 小数 （浮点数），其他 lib 只能支持 整数。
 * 如果需要支持小数，需要自己操作 { amount: uint256, decimals: uint8 } 的结构，同时要处理除法精度问题，比较麻烦。
 * 这三个 lib 都是同一个作者实现的，三者的区别可以看作者的解释：<https://github.com/MikeMcl/big.js/wiki>
 * `big.js` 有最小的包体积和较完整的 API，但 `bignumber.js` 和 `decimals.js` 对大整数运算更快，并直接支持
 * 带`0x`前导的十六进制字符串。同时 `decimals.js` 在计算时会出现精度丢失的情况。
 *
 * 因为，我们最终选择 `bignumber.js` 作为项目的唯一数字/浮点数库。
 */

import BigNumber from 'bignumber.js'

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
})

type BigNumberGens = BigNumber | number | string | bigint | BN

export enum Currency {
  'CNY' = 'CNY',
  'USD' = 'USD',
}

export const CurrencySymbol = {
  [Currency.CNY]: '¥',
  [Currency.USD]: '$',
}
export abstract class FixedPointNumberInterface extends BigNumber {
  protected amount: BigNumber
  protected decimals: number

  /**
   * 将 BigNumber 对象，转换为指定有效数字的字符串
   *
   * 有效数字指科学计算中用以表示一定长度浮点数精度的那些数字。一般指一个用小数形式表示的浮点数中，
   * 从第一个非零的数字算起的所有数字，因此，1.24和0.00124的有效数字都有3位。并且在取有效数字时
   * 会遵循`四舍五入`的进位规则。
   *
   * @docs: [有效数字](https://zh.wikipedia.org/wiki/%E6%9C%89%E6%95%88%E6%95%B0%E5%AD%97)
   * @returns {string}
   */
  public toSignificant(significantNumber: number): string {
    return this.amount
      .shiftedBy(-this.decimals)
      .sd(significantNumber)
      .toString()
  }

  public toDecimalsFixed(
    fixedAt?: number,
    roundedMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
  ): string {
    return this.amount.shiftedBy(-this.decimals).toFixed(fixedAt, roundedMode)
  }

  public toAutoFormat(
    significantNumber: number = 4,
    roundedMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
  ): string {
    const decimalToUnit = new BigNumber(
      this.toDecimalsFixed(this.decimals, roundedMode),
    )

    if (decimalToUnit.gte(1)) return decimalToUnit.decimalPlaces(4).toFormat()

    return this.toSignificant(significantNumber)
  }

  public toCurrency(
    currency: Currency,
    fixedAt: number = 2,
    roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
  ) {
    const symbol = CurrencySymbol[currency]
    const value = this.toDecimalsFixed(fixedAt, roundingMode)
    return `${symbol}${new BigNumber(value).toFormat()}`
  }
}

export class FixedPointNumber extends FixedPointNumberInterface {
  readonly amount: BigNumber = new BigNumber(0)
  readonly decimals: number = 0
  /**
   * 创建一个 FixedPoint 对象
   *
   * 为什么需要 FixedPoint 对象，而不是直接使用 BigNumber 对象？
   *
   * 1. 保留原始值，方便后续计算。
   * 2. 统一格式化输出
   *
   * @param {BigNumberGens} amount the original number value
   * @param {number} decimals fixed point number of decimals
   */
  constructor(
    _amount?: BigNumberGens | FixedPointNumber,
    _decimals: number | string = 0,
  ) {
    super(_amount ? _amount.toString() : '')
    this.amount = _amount ? new BigNumber(_amount.toString()) : new BigNumber(0)

    if (
      _amount instanceof FixedPointNumber &&
      typeof _decimals === 'undefined'
    ) {
      _decimals = _amount.decimals
    }

    this.decimals = Number(_decimals) || 0
  }

  toString() {
    return this.amount.toString()
  }
}

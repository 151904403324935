import useSWR, { Fetcher } from 'swr'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { API_ROOT_DOMAIN } from '~/constants/rootDomain'

type EventItem = {
  id: number
  title: string
  image: string
  url: string
  published_on: string
  published_end: string
}

type EventsAPIResult = {
  size: number
  page: number
  total: number
  items: EventItem[] | null
}

export const useEvents = () => {
  const { i18n } = useTranslation()
  const locale = i18n.resolvedLanguage === 'zh' ? 'zh-CN' : 'en-US'
  const params = { platform: 'web', locale: locale }
  const fetcher: Fetcher<EventsAPIResult, string> = (url) =>
    axios.get(url, { params }).then((res) => res.data)

  const { data, error } = useSWR(
    `https://api.${API_ROOT_DOMAIN}/v5/exchange/events`,
    fetcher,
  )

  if (error) {
    console.error(error)
    return []
  }

  if (!data?.items) return []

  const events = data.items

  return events
}

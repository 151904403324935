import React from 'react'
import { StyledLoading, StyledLoadingRing } from './styled'

interface Props {
  containerStyle?: {}
}

const Loading = (props: Props) => (
  <StyledLoading style={props.containerStyle || {}}>
    <StyledLoadingRing>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoadingRing>
  </StyledLoading>
)

export default Loading

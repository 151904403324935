import { useCallback, CSSProperties } from 'react'
import {
  useNetworkOptions,
  useSpotSwapRouter,
  useLimitOrderRouter,
  useWETHRouter,
} from '~/hooks'
import { useOneOfModalOpened } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import {
  getChainName,
  getWETHSupportedNetworks,
  getSpotSwapSupportedNetworks,
  getLimitOrderSupportedNetworks,
} from '~/utils/chain'
import { ENV } from '~/constants/env'
import { Mainnet, Sepolia } from '~/model/chain/ethereum'
import { StyledOption } from './StyledOptions'
import Checked from '~/assets/images/checked.svg'

interface Props {
  onSelect: (chainId: number | null) => void
  chainId: number | string | null
  containerStyle?: CSSProperties
}

export const NetworkConnectOptions: React.FC<Props> = ({
  onSelect,
  chainId,
  containerStyle,
}) => {
  const networks = useNetworkOptions()
  const isSpotSwapRouter = useSpotSwapRouter()
  const isLimitRouter = useLimitOrderRouter()
  const isWETHRouter = useWETHRouter()

  const wethWrapOpened = useOneOfModalOpened([
    ApplicationModal.WETH_WRAP,
    ApplicationModal.WETH_PREVIEW,
    ApplicationModal.WETH_RESULT,
  ])

  const disable = useCallback(
    (chainId: number) => {
      if (isWETHRouter || wethWrapOpened) {
        const WETHSupportedChains = getWETHSupportedNetworks()
        return !WETHSupportedChains.includes(chainId)
      } else if (isSpotSwapRouter) {
        const SpotSwapSupportedChains = getSpotSwapSupportedNetworks()
        return !SpotSwapSupportedChains.includes(chainId)
      } else if (isLimitRouter) {
        const LOSupportedChains = getLimitOrderSupportedNetworks()
        return !LOSupportedChains.includes(chainId)
      } else {
        const isDev = ENV.development || ENV.local
        const defaultSupportedChainId = isDev
          ? Sepolia.chainId
          : Mainnet.chainId
        return defaultSupportedChainId !== chainId
      }
    },
    [isSpotSwapRouter, isLimitRouter, isWETHRouter, wethWrapOpened],
  )

  return (
    <div style={containerStyle}>
      {networks.map((chain) => (
        <StyledOption
          disabled={disable(chain.chainId)}
          key={chain.chainId}
          onClick={() => onSelect(chain.chainId)}
        >
          <span>
            <img
              src={chain.logoUrl}
              alt={getChainName(chain.chainId)}
              className="logo"
            />
            {getChainName(chain.chainId)}
          </span>

          {Number(chain.chainId) === Number(chainId) ? (
            <img src={Checked} alt="" className="option-checked" />
          ) : (
            <span className="option-unchecked"></span>
          )}
        </StyledOption>
      ))}
    </div>
  )
}

export interface MiningRewards {
  currentPhase: number
  pools: Array<{
    closedAt: number
    distribution: {
      maker: { amount: string; percentage: string }
      referral: { amount: string; percentage: string }
      relayer: { amount: string; percentage: string }
      trader: { amount: string; percentage: string }
    }
    lon: string
    phase: number
    powers: {
      maker: number
      referral: number
      relayer: number
      trader: number
    }
    maker: number
    referral: number
    relayer: number
    trader: number
    startedAt: number
  }>
}

export interface MyMiningData {
  activateInvitations: number
  code: string
  currentPhase: number
  invitations: number
  myReferralPower: number
  myTradePower: number
  myTradeVolume: number
  referralPower: number
  tradePower: number
  tradeVolume: number
  wallet: string
}

export interface RoundTradeMiningReward {
  audited: boolean
  claim: {
    balance?: number
    proof?: string[]
    period?: number
  }
  endAt: number
  grantTime: number
  issued: boolean
  lon: string
  phase: number
  referralStats: {
    activateInvitations: number
    lon: string
    myReferralPower: string
    referralPower: string
  }
  settled: boolean
}

export type RoundTradeMiningRewards = Array<RoundTradeMiningReward>

export enum POOL {
  UNISWAP = 'Uniswap',
  SUSHI = 'Sushiswap',
}

export interface LiquidityPools {
  endTimestamp: number
  pools: Array<{
    closed: boolean
    endTimestamp: number
    id: number
    lpTokenAddress: string
    pool: POOL
    poolUrl: string
    rewardToken: string
    rewardTokenAmount: string
    stakingRewardsAddress: string
    title: string
    totalRewardAmount: string
    type: string
  }>
}

export interface RoundTradeMiningReport {
  lon: number
  volume_in_usd: number
  wallets: number
}

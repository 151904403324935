import { useState } from 'react'

export type HoverProps = Pick<
  React.HTMLAttributes<HTMLElement | SVGElement>,
  'onMouseEnter' | 'onMouseLeave'
>

export const useHover = ({
  mouseEnterDelayMS = 0,
  mouseLeaveDelayMS = 0,
} = {}): [boolean, HoverProps] => {
  const [isHovering, setIsHovering] = useState(false)
  let mouseEnterTimer: NodeJS.Timer | undefined
  let mouseOutTimer: NodeJS.Timer | undefined
  return [
    isHovering,
    {
      onMouseEnter: () => {
        clearTimeout(mouseOutTimer)

        mouseEnterTimer = setTimeout(
          () => setIsHovering(true),
          mouseEnterDelayMS,
        )
      },
      onMouseLeave: () => {
        clearTimeout(mouseEnterTimer)
        mouseOutTimer = setTimeout(
          () => setIsHovering(false),
          mouseLeaveDelayMS,
        )
      },
    },
  ]
}

import { getAddress } from 'ethers/lib/utils'
import { AddressZero } from '@ethersproject/constants'
import { useMemo } from 'react'
import { NEVER_RELOAD, useSingleCallResult } from '~/state/multicall/hooks'
import { useTokenContract } from './useContract'
import { useNativeCurrencySymbol } from '~/state/wallet/hooks'
import { useWeb3React } from '@web3-react/core'

export const useToken = (
  uncheckedAddress?: string,
  useSinger: boolean = true,
): ERC20Token | undefined => {
  const address = useMemo(
    () => (uncheckedAddress ? getAddress(uncheckedAddress) : undefined),
    [uncheckedAddress],
  )
  const nativeSymbol = useNativeCurrencySymbol()
  const { chainId } = useWeb3React()

  // @todo get from state/dex cache
  // const tokens = useToken()

  const tokenContract = useTokenContract(address, useSinger)

  const symbol = useSingleCallResult(
    tokenContract,
    'symbol',
    undefined,
    NEVER_RELOAD,
  )
  const name = useSingleCallResult(
    tokenContract,
    'name',
    undefined,
    NEVER_RELOAD,
  )
  const decimals = useSingleCallResult(
    tokenContract,
    'decimals',
    undefined,
    NEVER_RELOAD,
  )

  const anyLoading = useMemo(() => {
    return [symbol, decimals, name].some(({ loading }) => loading == true)
  }, [symbol, decimals, name])

  return useMemo(() => {
    if (address === AddressZero) {
      return {
        address,
        name: 'Ether',
        symbol: nativeSymbol,
        decimals: 18,
      }
    }
    if (!tokenContract) return undefined
    if (anyLoading) return undefined

    if (symbol.result && decimals.result) {
      return {
        address,
        name: name.result?.[0],
        symbol: symbol.result?.[0],
        decimals: decimals.result?.[0],
      }
    }
    return undefined
  }, [
    address,
    anyLoading,
    symbol.result,
    decimals.result,
    tokenContract,
    chainId,
  ])
}

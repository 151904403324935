import { createReducer } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { RUNTIME_ENV } from '~/constants/env'
import {
  addTransaction,
  checkedTransaction,
  clearAllTransactions,
  finalizeTransaction,
  SerializableTransactionReceipt,
  TransactionInfo,
} from './actions'

const now = () => new Date().getTime()

export interface TransactionDetail {
  hash: string
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  info: TransactionInfo
}

export interface TransactionState {
  [chainId: number]: TransactionDetail
}

export const initialState: TransactionState = {}

export const transactionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      addTransaction,
      (transactions, { payload: { chainId, from, hash, info } }) => {
        if (transactions[chainId]?.[hash]) {
          throw Error(`Transaction ${hash} already exists`)
        }
        const txs = transactions[chainId] ?? ({} as TransactionDetail)
        txs[hash] = { hash, info, from, addTime: now() }
        transactions[chainId] = txs
      },
    )
    .addCase(clearAllTransactions, (transactions, { payload: { chainId } }) => {
      if (!transactions[chainId]) return

      transactions[chainId] = {} as TransactionDetail
    })
    .addCase(
      checkedTransaction,
      (transactions, { payload: { chainId, hash, blockNumber } }) => {
        const tx = transactions[chainId]?.[hash]
        if (!tx) return
        if (!tx.lastCheckedBlockNumber) {
          tx.lastCheckedBlockNumber = blockNumber
        } else {
          tx.lastCheckedBlockNumber = Math.max(
            blockNumber,
            tx.lastCheckedBlockNumber,
          )
        }
      },
    )
    .addCase(
      finalizeTransaction,
      (transactions, { payload: { chainId, hash, receipt } }) => {
        const tx = transactions[chainId]?.[hash]
        if (!tx) return
        tx.receipt = receipt
        tx.confirmedTime = now()
      },
    )
})

export const transactionsPersistConfig: PersistConfig<TransactionState> = {
  key: `sub-transactions-${RUNTIME_ENV}`,
  storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: [],
}

export default persistReducer(transactionsPersistConfig, transactionsReducer)

import { axios } from '../common'
import { Article, Articles } from './types'

export * from './types'

export const ZENDESK_API_URL = 'https://tokenlon.zendesk.com/api/v2'

export const getArticle = async (
  lang,
  articleId,
): Promise<{ article?: Article }> => {
  if (!lang || !articleId) return
  return axios.get(
    `${ZENDESK_API_URL}/help_center/${lang}/articles/${articleId}.json`,
  )
}

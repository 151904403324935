import { useMemo } from 'react'
import { useWETHRouter, useSpotSwapRouter, useLimitOrderRouter } from '~/hooks'
import { useOneOfModalOpened } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import { useCurrentChainId } from './useCurrentChainId'
import {
  getCommonUpdaterChainId,
  getWETHSupportedNetworks,
  getSpotSwapSupportedNetworks,
  getLimitOrderSupportedNetworks,
} from '~/utils/chain'

// 保证用于 updater 的 chainId 永远是对的，和用户的 chainId 无关
export const useUpdaterChainId = () => {
  const isWETHRouter = useWETHRouter()
  const isSpotSwapRouter = useSpotSwapRouter()
  const isLimitOrderRouter = useLimitOrderRouter()
  const wethWrapOpened = useOneOfModalOpened([
    ApplicationModal.WETH_WRAP,
    ApplicationModal.WETH_PREVIEW,
    ApplicationModal.WETH_RESULT,
  ])
  const currentChainId = useCurrentChainId()
  const defaultChainId = getCommonUpdaterChainId()

  return useMemo(() => {
    if (isWETHRouter || wethWrapOpened) {
      const wethSupportedChains = getWETHSupportedNetworks()
      return wethSupportedChains.includes(currentChainId)
        ? currentChainId
        : defaultChainId
    } else if (isSpotSwapRouter) {
      const spotSwapSupportedChains = getSpotSwapSupportedNetworks()
      return spotSwapSupportedChains.includes(currentChainId)
        ? currentChainId
        : defaultChainId
    } else if (isLimitOrderRouter) {
      const loSupportedChains = getLimitOrderSupportedNetworks()
      return loSupportedChains.includes(currentChainId)
        ? currentChainId
        : defaultChainId
    } else {
      return defaultChainId
    }
  }, [
    isWETHRouter,
    isSpotSwapRouter,
    isLimitOrderRouter,
    wethWrapOpened,
    currentChainId,
  ])
}

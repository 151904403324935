import { Signer } from '@tokenlon/sdk'

export const signMessage = async (
  provider,
  message: string,
): Promise<string> => {
  if (!provider || !message) return
  const signer = await new Signer(provider).getSigner()
  return signer.signMessage(message)
}

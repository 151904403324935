import styled, {
  css,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components'

////////////////////////////////////////////////////////////////////////////////////
/**
 * Media Queries
 */
export const MediaBreakPointUp = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
}
export enum MediaBreakPoint {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

const mediaBreakpointUpTemplates: {
  [width in keyof typeof MediaBreakPointUp]: typeof css
} = Object.keys(MediaBreakPointUp).reduce((accumulator, size) => {
  ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MediaBreakPointUp as any)[size]}) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

////////////////////////////////////////////////////////////////////////////////////
/**
 * Typography
 */

const wrapPx = (str?: string | number) => {
  return typeof str === 'number' ? `${str}px` : str
}
export const TextWrapper = styled.div<{
  color?: string
  fontSize?: string | number
  fontWeight?: string | number
  lineHeight?: string | number
}>`
  color: ${({ color }) => color || 'rgba(0, 0, 0, 0.85)'};
  font-size: ${({ fontSize }) => wrapPx(fontSize) || '14px'};
  line-height: ${({ lineHeight }) => wrapPx(lineHeight) || 1};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
`
export const Type = {
  Label: ({ children, ...props }) => (
    <TextWrapper color="#9598ab" fontSize={13} lineHeight={18} {...props}>
      {children}
    </TextWrapper>
  ),
  Address: ({ children, ...props }) => (
    <TextWrapper
      color="#191c1e"
      fontSize={15}
      lineHeight={18}
      fontWeight={500}
      {...props}
    >
      {children}
    </TextWrapper>
  ),
}

const theme = () => {
  return {
    // media queries
    media: mediaBreakpointUpTemplates,
    colors: {
      primary: '#5f4dbc',
      bgColor: '#f6f8fb',
      homeBgColor:
        'linear-gradient(180deg,rgba(209, 215, 255, 0) 0%,   #e4e8ff 100%)',
      headerNormal: '#fff',
      footerNormal: '#f6f8fb',
      btnPrimary: '#5f4dbc',
      btnPrimaryDisabled: 'rgba(95, 77, 188, 0.4)',
      exchangeBtnDisabled: '#BEB8E2',
      btnTextPrimary: '#fff',
      btnSecondary: '#ffffff',
      btnTextSecondary: '#604DBC',
      textBlack: '#191c1e',
      textBlack2: '#2c2842',
      textGrey: '#9598ab',
      textGrey2: '#6f7390',
      linkPrimary: '#5f4dbc',
      alertInfoText: '#006BD6',
      alertInfoBg: '#D6EBFF',
      alertWarningText: '#D6AB3C',
      alertWarningBg: '#FFF7E1',
      alertRiskText: '#D66665',
      alertRiskBg: '#FFEAE9',
      alertVerifiedText: '#3AC073',
      alertVerifiedBg: '#EBF9F1',
    },
  }
}

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return <StyledThemeProvider theme={theme()}>{children}</StyledThemeProvider>
}

export default ThemeProvider

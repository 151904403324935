import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { ApprovalProgress } from '~/components/ApprovalModal/ApproveStatusLabel'
import { AppState } from '..'
import { useAppDispatch, useAppSelector } from '../hooks'
import { ApplicationModal, setModalState, setOpenModal } from './reducer'

export const useBlockNumber = (): number | null => {
  const { chainId } = useWeb3React()

  return useAppSelector(
    (state: AppState) => state.application.blockNumber[chainId],
  )
}

export const useMainnetBlockNumber = (): number | null => {
  return useAppSelector((state: AppState) => state.application.blockNumber[1])
}

export const useModalOpened = (modal: ApplicationModal) => {
  const modalOpened = useAppSelector(
    (state: AppState) => state.application.openModal,
  )

  return modalOpened.includes(modal)
}

export const useOneOfModalOpened = (modals: ApplicationModal[]): boolean => {
  const modalOpened = useAppSelector(
    (state: AppState) => state.application.openModal,
  )

  return modalOpened.some((modal) => modals.includes(modal))
}

export const useModalToggle = (
  modal: ApplicationModal,
  opt?: {
    stack?: boolean
    modalState?: any
  },
) => {
  const { stack, modalState } = opt || { stack: false, modalState: null }
  const dispatch = useAppDispatch()
  const opened = useModalOpened(modal)
  const modalOpened = useAppSelector(
    (state: AppState) => state.application.openModal,
  )

  return useCallback(() => {
    dispatch(
      setOpenModal(
        opened
          ? modalOpened.filter((m) => m !== modal)
          : stack
          ? [...modalOpened, modal]
          : [modal],
      ),
    )
    dispatch(setModalState({ modal, state: modalState }))
  }, [dispatch, modal, opened, modalState, stack, modalOpened])
}

export const useModalSwitch = (modal: ApplicationModal) => {
  const dispatch = useAppDispatch()
  const opened = useModalOpened(modal)
  const modalOpened = useAppSelector(
    (state: AppState) => state.application.openModal,
  )

  return useCallback(
    (
      open: boolean,
      opt?: {
        stack?: boolean
        modalState?: any
      },
    ) => {
      // opened

      if (open === opened) return
      const { stack, modalState } = opt || { stack: false, modalState: null }

      if (stack) {
        dispatch(
          setOpenModal(
            open
              ? [...modalOpened, modal]
              : modalOpened.filter((m) => m !== modal),
          ),
        )
      } else {
        dispatch(setOpenModal(open ? [modal] : []))
      }

      dispatch(setModalState({ modal, state: modalState }))
    },
    [dispatch, modal, opened],
  )
}

export const useModalState = (modal: ApplicationModal) => {
  return useAppSelector(
    (state: AppState) => state.application.modalState[modal] ?? {},
  )
}

export const useApprovalModalStateDispatch = () => {
  const dispatch = useAppDispatch()

  return useCallback(
    ({
      progress,
      hash,
      description1,
      description2,
    }: {
      progress?: ApprovalProgress
      hash?: string
      description1?: string
      description2?: string
    }) => {
      dispatch(
        setModalState({
          modal: ApplicationModal.APPROVAL_STATUS,
          state: {
            progress,
            hash,
            ...(description1 && { description1 }),
            ...(description2 && { description2 }),
          },
        }),
      )
    },
    [dispatch],
  )
}

export const useRefreshToken = () => {
  return useAppSelector((state) => state.application.refreshToken)
}

export const useFeeData = (chainId?: number) => {
  const { chainId: currentChainId } = useWeb3React()
  const targetChainId = chainId ?? currentChainId
  return useAppSelector((state) => state.application.feeData[targetChainId])
}

import { useTranslation } from 'react-i18next'
import { useModalToggle, useModalOpened } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import Modal from '~/components/Modal'
import { LOCALES } from '~/locales'
import styled from 'styled-components'
import IconWarning from '~/assets/images/warning-black.svg'

const ImKeyUnSupportedModal = () => {
  const isOpen = useModalOpened(ApplicationModal.IMKEY_UNSUPPORTED)
  const toggleModal = useModalToggle(ApplicationModal.IMKEY_UNSUPPORTED)
  const { t, i18n } = useTranslation()
  const isZh = i18n.resolvedLanguage === LOCALES.ZH

  return (
    <Modal
      title={t('imkey_unsupported_modal.title')}
      isOpen={isOpen}
      onDismiss={toggleModal}
      animate={false}
      contentStyle={{ maxWidth: '500px' }}
    >
      <StyledContainer>
        <StyledContent>
          <StyledIcon src={IconWarning} alt="warning" />
          <StyledTitle>
            {t('imkey_unsupported_modal.content_title')}
          </StyledTitle>
          <p>{t('imkey_unsupported_modal.content_desc')}</p>
        </StyledContent>
        <StyledLearnMore>
          <span>{t('imkey_unsupported_modal.learn_more')}</span>
          {!isZh && <span>&nbsp;</span>}
          <StyledLink href={'https://imkey.im'} target="_blank">
            {t('learn_more')}
          </StyledLink>
          <span>{t('period')}</span>
        </StyledLearnMore>
      </StyledContainer>
    </Modal>
  )
}

export default ImKeyUnSupportedModal

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px 16px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border-radius: 20px;
  background-color: #f7f7f9;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #81808d;
  }
`

const StyledTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #3c3c44;
`

const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`

const StyledLearnMore = styled.div`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #81808d;
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.linkPrimary};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.linkPrimary};
  }
`

export enum LimitOrderSubmitStatus {
  Idle = 'Idle',
  SignOrderPending = 'SignOrderPending',
  SignOrderSuccess = 'SignOrderSuccess',
  SignOrderFailure = 'SignOrderFailure',
  CancelOrderPending = 'CancelOrderPending',
  CancelOrderRejected = 'CancelOrderRejected',
  CancelOrderSuccess = 'CancelOrderSuccess',
  CancelOrderFailure = 'CancelOrderFailure',
  SignOrderFailureCode1001 = 'sign_limit_order_failed_1001',
  SignOrderFailureCode1002 = 'sign_limit_order_failed_1002',
  SignOrderFailureCode1003 = 'sign_limit_order_failed_1003',
  SignOrderFailureCode1004 = 'sign_limit_order_failed_1004',
  SignOrderFailureCode1005 = 'sign_limit_order_failed_1005',
  SignOrderFailureCode1006 = 'sign_limit_order_failed_1006',
  SignOrderFailureCode1007 = 'sign_limit_order_failed_1007',
  SignOrderFailureCode1008 = 'sign_limit_order_failed_1008',
  SignOrderFailureCode1009 = 'sign_limit_order_failed_1009',
  SignOrderFailureCode1010 = 'sign_limit_order_failed_1010',
  SignOrderFailureCode1011 = 'sign_limit_order_failed_1011',
  SignOrderFailureCode1012 = 'sign_limit_order_failed_1012',
  SignOrderFailureCode1013 = 'sign_limit_order_failed_1013',
  SignOrderFailureCode1014 = 'sign_limit_order_failed_1014',
  SignOrderFailureCode1015 = 'sign_limit_order_failed_1015',
  SignOrderFailureCode1016 = 'sign_limit_order_failed_1016',
}

export enum LimitOrderExpiration {
  Min5 = '5_minutes',
  Min20 = '20_minutes',
  Hour1 = '1_hour',
  Hour12 = '12_hours',
  Day1 = '1_day',
  Day3 = '3_days',
  Day7 = '7_days',
  Week2 = '2_weeks',
  Month6 = '6_months',
}

export interface LimitOrderUserConfig {
  expiration?: LimitOrderExpiration
  isInputRateDirection?: boolean
  [key: string]: any
}

export interface SpotSwapUserConfig {
  sendByRelayer?: boolean
  isCrossSwap?: boolean
  recipient?: string
  [key: string]: any
}

export interface PriceInfo {
  USD?: {
    price: string
    lastUpdateBlockNumber: number
  }
  CNY?: {
    price: string
    lastUpdateBlockNumber: number
  }
}

export interface MarketPriceItem {
  address: string
  caip2: string
  chainType: string
  currency: 'USD' | 'CNY'
  price: number | string
  source: string
  showOption?: ''
}

export enum SpotSwapUserTradeStatus {
  NORMAL = 'normal',
  INQUIRING = 'inquiring',
  PREVIEWING_ORDER = 'previewing_order',
  ORDER_IN_PROGRESS = 'order_in_progress',
  ORDER_COMPLETED = 'order_completed',
  ORDER_FAILED = 'order_failed',
}

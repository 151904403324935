import { networkConnection } from './../connection/index'
import { Connector } from '@web3-react/types'
import { useEffect } from 'react'
import { gnosisSafeConnection } from '~/connection'
import { Connection, ConnectionType } from '~/connection/type'
import { getConnection } from '~/connection/utils'
import { useAppDispatch } from '~/state/hooks'
import { updateSelectedConnectionType } from '~/state/user/actions'
import { useSelectedConnectionType } from '~/state/user/hooks'

const connect = async (connector: Connector) => {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly()
    } else {
      await connector.activate()
    }
  } catch (error) {
    console.warn('useEagerlyConnect: error', error)
  }
}

export const useEagerlyConnect = () => {
  const dispatch = useAppDispatch()
  const selectedConnectionType = useSelectedConnectionType()

  let selectedConnection: Connection | undefined
  if (selectedConnectionType) {
    try {
      const isSelectedHotWallet = selectedConnectionType === ConnectionType.HoT
      if (isSelectedHotWallet) {
        dispatch(updateSelectedConnectionType(null))
      } else {
        selectedConnection = getConnection(selectedConnectionType)
      }
    } catch {
      dispatch(updateSelectedConnectionType(null))
    }
  }

  useEffect(() => {
    connect(gnosisSafeConnection.connector)
    connect(networkConnection.connector)

    if (selectedConnection) {
      connect(selectedConnection.connector)
    }
  }, [])
}

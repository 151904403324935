import { detectImTokenApp } from '~/connection/detect'

export const isClient = typeof window !== 'undefined'

const imToken = typeof window !== 'undefined' ? window['imToken'] : null

export function routeTo(payload) {
  return imToken
    .callPromisifyAPI('navigator.routeTo', payload)
    .catch((err) => console.warn(err))
}

export function goTokenlon(address: string) {
  if (detectImTokenApp()) {
    routeTo({
      screen: 'Tokenlon',
      passProps: {
        xChainReceiver: address,
      },
    })
  } else {
    window.location.href = `${window.location.origin}/instant`
  }
}

export function goV5Tokenlon(address: string) {
  if (detectImTokenApp()) {
    routeTo({
      screen: 'Tokenlon',
      passProps: {
        xChainReceiver: address,
      },
    })
  } else {
    window.open(
      'https://v5.tokenlon.im/instant',
      '_blank',
      'noopener,noreferrer',
    )
  }
}

import styled from 'styled-components'
import { MediaBreakPointUp } from '~/theme'

export interface MenuItemProps {
  icon: string
  title: string
  onClick: Function
}

export const StyledMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #9598ab;
  cursor: pointer;
  padding: 20px;
  transition: all ease-in 0.2s;

  &:hover {
    background: #fafafc;
    border-radius: 4px;
  }

  .icon {
    width: 24px;
    margin-bottom: 4px;
  }
  @media screen and (max-width: ${MediaBreakPointUp.sm}) {
    padding: 20px 0;
  }
`

export const MenuItem: React.FC<MenuItemProps> = ({ icon, title, onClick }) => {
  return (
    <StyledMenuItem onClick={() => onClick()}>
      <img className="icon" src={icon} alt="menu" />
      <span>{title}</span>
    </StyledMenuItem>
  )
}

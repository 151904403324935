import { LimitOrder, AllowanceTarget } from '@tokenlon/sdk'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useMemo, useRef } from 'react'
import { useUpdaterChainId, useV6SDK, useSpotSwapRouter } from '~/hooks'
import { captureExceptionWithLabel } from '~/utils/captureExceptionWithLabel'
import { limitSwapTokenWrapper, v6SpotSwapTokenWrapper } from '~/utils/token'
import { AppState } from '..'
import { useAppSelector, useAppDispatch } from '../hooks'
import {
  addSpotTokens,
  addLimitTokens,
  updateSpotSwapRecommendTokens,
} from './actions'
import {
  permit2Address,
  allowanceTargetAddress as v6AllowanceTargetAddress,
  AppConfig,
  TokenlonV6NetworkConfig,
} from '@tokenlon/v6-sdk'
import { useTokenPriceWithUseAPI } from '~/hooks/useTokenPrice'
import BigNumber from 'bignumber.js'
import { getSpotSwapSupportedNetworks } from '~/utils/chain'
import {
  Mainnet,
  Sepolia,
  ArbitrumOne,
  ArbitrumRinkeby,
  Base,
  BSC,
  Optimism,
  Polygon,
  zkSync,
} from '~/model/chain/ethereum'
import { ENV } from '~/constants/env'

export enum PriceImpactLevel {
  POSITIVE,
  NORMAL,
  WARN,
  DANGER,
}

export const useSpotSwapTokens = (targetChainId?: number) => {
  const { chainId } = useWeb3React()
  const tokens = useAppSelector(
    (state: AppState) => state.dex.spotTokens[targetChainId || chainId] || {},
  )
  const customTokens = useAppSelector(
    (state: AppState) =>
      state.dex.userCustomSpotTokens[targetChainId || chainId] || {},
  )

  return useMemo(() => {
    return { ...tokens, ...customTokens }
  }, [tokens, customTokens])
}

export const useAllSpotSwapTokens = () => {
  const isDev = ENV.development || ENV.local
  const defaultChainId = isDev ? Sepolia.chainId : Mainnet.chainId
  const allChainIds = [
    defaultChainId,
    ArbitrumOne.chainId,
    Base.chainId,
    BSC.chainId,
    Optimism.chainId,
    Polygon.chainId,
    zkSync.chainId,
  ]

  const allTokens = useAppSelector((state: AppState) => {
    return allChainIds.reduce((acc, chainId) => {
      const chainTokens = {
        ...(state.dex.spotTokens[chainId] ?? {}),
        ...(state.dex.userCustomSpotTokens[chainId] ?? {}),
      }
      return [...acc, ...Object.values(chainTokens)]
    }, [] as SpotSwapToken[])
  })

  return allTokens
}

export const useLimitSwapTokens = (targetChainId?: number) => {
  const { chainId } = useWeb3React()
  const tokens = useAppSelector(
    (state: AppState) => state.dex.limitTokens[targetChainId || chainId] || {},
  )
  const customTokens = useAppSelector(
    (state: AppState) =>
      state.dex.userCustomLimitTokens[targetChainId || chainId] || {},
  )

  return useMemo(() => {
    return { ...tokens, ...customTokens }
  }, [tokens, customTokens])
}

export const useAllLimitSwapTokens = () => {
  const isDev = ENV.development || ENV.local
  const supportedChainIds = isDev
    ? [Sepolia.chainId, ArbitrumRinkeby.chainId]
    : [Mainnet.chainId, ArbitrumOne.chainId]

  const allTokens = useAppSelector((state: AppState) => {
    return supportedChainIds.reduce((acc, chainId) => {
      const chainTokens = {
        ...(state.dex.limitTokens[chainId] ?? {}),
        ...(state.dex.userCustomLimitTokens[chainId] ?? {}),
      }
      return [...acc, ...Object.values(chainTokens)]
    }, [] as LimitSwapToken[])
  })

  return allTokens
}

export const useTokenlonConfig = () => {
  const { chainId } = useWeb3React()

  return useAppSelector(
    (state: AppState) => state.dex.config[chainId] || ({} as TokenlonConfig),
  )
}

export const useTokenlonV6NetworkConfigs = () => {
  return useAppSelector(
    (state: AppState) =>
      (state.dex.v6Config.networkConfig || []) as TokenlonV6NetworkConfig[],
  )
}

export const useTokenlonV6NetworkConfig = () => {
  const chainId = useUpdaterChainId()

  const networkConfig = useAppSelector(
    (state: AppState) =>
      (state.dex.v6Config.networkConfig || []) as TokenlonV6NetworkConfig[],
  )
  return (
    networkConfig.find((config) => config.chainId === chainId) ??
    ({} as TokenlonV6NetworkConfig)
  )
}

export const useSpotSwapTokensUpdater = () => {
  const v6SDK = useV6SDK()
  const chainId = useUpdaterChainId()
  const isInitialized = useRef(false)
  const dispatch = useAppDispatch()
  const supportedChains = getSpotSwapSupportedNetworks()

  return useCallback(async () => {
    try {
      // Initialize all chains' tokens on first call
      if (!isInitialized.current) {
        isInitialized.current = true
        await Promise.allSettled(
          supportedChains.map(async (chainId) => {
            const tokens = (await v6SDK.swap.getTokenList(chainId)).filter(
              (token) => token.displayType !== -1,
            )
            const formattedTokens = v6SpotSwapTokenWrapper(tokens || [])
            if (formattedTokens.length > 0) {
              dispatch(addSpotTokens({ tokens: formattedTokens, chainId }))
            }
          }),
        )
      } else {
        // Get current chain's tokens
        if (!chainId) return
        const tokens = (await v6SDK.swap.getTokenList(chainId)).filter(
          (token) => token.displayType !== -1,
        )
        const formattedTokens = v6SpotSwapTokenWrapper(tokens || [])
        if (formattedTokens.length > 0) {
          dispatch(addSpotTokens({ tokens: formattedTokens, chainId }))
        }
      }
    } catch (error) {
      captureExceptionWithLabel('useSpotSwapTokensUpdater Error', error)
      console.error(error)
    }
  }, [chainId])
}

export const useSpotSwapRecommendTokensUpdater = () => {
  const v6SDK = useV6SDK()
  const dispatch = useAppDispatch()

  return useCallback(async () => {
    try {
      const tokens = await v6SDK.swap.trendingList()
      const formattedTokens = v6SpotSwapTokenWrapper(tokens || [])
      dispatch(updateSpotSwapRecommendTokens(formattedTokens))
    } catch (error) {
      console.error(error)
    }
  }, [])
}

export const useLimitSwapTokensUpdater = () => {
  const chainId = useUpdaterChainId()
  const isInitialized = useRef(false)
  const dispatch = useAppDispatch()

  return useCallback(async () => {
    try {
      if (!isInitialized.current) {
        isInitialized.current = true

        const isDev = ENV.development || ENV.local
        const supportedChainIds = isDev
          ? [Sepolia.chainId, ArbitrumRinkeby.chainId]
          : [Mainnet.chainId, ArbitrumOne.chainId]

        await Promise.allSettled(
          supportedChainIds.map(async (chainId) => {
            const tokens = await LimitOrder.getTokenList(chainId)
            const formattedTokens = limitSwapTokenWrapper(tokens || [])
            if (formattedTokens.length > 0) {
              dispatch(addLimitTokens({ tokens: formattedTokens, chainId }))
            }
          }),
        )
      } else {
        if (!chainId) return
        const tokens = await LimitOrder.getTokenList(chainId)

        const formattedTokens = limitSwapTokenWrapper(tokens || [])
        if (formattedTokens.length > 0) {
          dispatch(addLimitTokens({ tokens: formattedTokens, chainId }))
        }
      }
    } catch (error) {
      console.error(error)
      captureExceptionWithLabel('useLimitSwapTokensUpdater Error', error)
    }
  }, [chainId])
}

export const useAllowanceTarget = () => {
  const { addressBookV5 } = useTokenlonConfig()
  const { chainId } = useWeb3React()
  const isInstantRouter = useSpotSwapRouter()
  const isZkSync = chainId === 324

  return useMemo(() => {
    return isInstantRouter
      ? isZkSync
        ? v6AllowanceTargetAddress[chainId]
        : permit2Address[chainId]
      : AllowanceTarget[chainId] || addressBookV5?.AllowanceTarget
  }, [addressBookV5, chainId, isInstantRouter, isZkSync])
}

export const useLimitSwapPair = (): [LimitSwapToken, LimitSwapToken] | [] => {
  return useAppSelector((state: AppState) => state.dex.limitSwapPair || [])
}

export const useSpotSwapPair = (): [SpotSwapToken, SpotSwapToken] | [] => {
  return useAppSelector((state: AppState) => state.dex.spotSwapPair || [])
}

export const useSpotSwapAmount = () => {
  const inputAmount = useAppSelector((state) => state.dex.spotSwapInputAmount)
  const outputAmount = useAppSelector((state) => state.dex.spotSwapOutputAmount)

  return { inputAmount, outputAmount }
}

export const useLimitSwapAmount = () => {
  const inputAmount = useAppSelector((state) => state.dex.limitSwapInputAmount)
  const outputAmount = useAppSelector(
    (state) => state.dex.limitSwapOutputAmount,
  )

  return { inputAmount, outputAmount }
}

export const useLimitSwapLimitPrice = () => {
  return useAppSelector((state) => state.dex.limitSwapLimitPrice)
}

export const useInputAmountVerify = () => {
  return useCallback((value: string) => {
    if (value === '00') return false
    const reg = new RegExp(/^\d*\.?\d*$/)
    if (!reg.test(value) || value.trim().length === 0) {
      console.log('Invalid input amount')
      return false
    }
    return true
  }, [])
}

export const useLimitOrderSubmitStatus = () => {
  return useAppSelector((state) => state.dex.limitOrderSubmitStatus)
}

export const useLimitOrderUserConfig = () => {
  return useAppSelector((state) => state.dex.limitOrderUserConfig)
}

export const useSpotSwapUserConfig = () => {
  return useAppSelector((state) => state.dex.spotSwapUserConfig)
}

export const useSpotSwapUserTradeStatus = () => {
  return useAppSelector((state) => state.dex.spotSwapUserTradeStatus)
}

// 计算价格影响(price impact)
export const useSpotSwapPriceImpact = () => {
  // 1. 先拿到交易对
  const [inputToken, outputToken] = useSpotSwapPair()
  // 2. 获取input和output的数量，及交易对代币的价值
  const { inputAmount, outputAmount } = useSpotSwapAmount()
  const inputTokenUnitPrice = useTokenPriceWithUseAPI({
    tokenAddress: inputToken?.address,
  })
  const outputTokenUnitPrice = useTokenPriceWithUseAPI({
    tokenAddress: outputToken?.address,
    chainId: outputToken?.chainId,
  })
  // 3. 计算出当前交易对的总金额
  const inputUsdValue = useMemo(() => {
    return new BigNumber(inputAmount).times(inputTokenUnitPrice)
  }, [inputAmount, inputTokenUnitPrice])
  const outputUsdValue = useMemo(() => {
    return new BigNumber(outputAmount).times(outputTokenUnitPrice)
  }, [outputAmount, outputTokenUnitPrice])

  const tokenPriceIsValid = inputTokenUnitPrice > 0 && outputTokenUnitPrice > 0
  if (!(inputAmount && outputAmount) || !tokenPriceIsValid) {
    return null
  }
  const priceImpactInUsd = outputUsdValue.minus(inputUsdValue).toFixed(2)
  // 4. 计算价差，使用negated处理，因为如果output value > input value，价差应该是正数
  const priceImpact = new BigNumber(1)
    .minus(outputUsdValue.dividedBy(inputUsdValue))
    .negated()
  // 5. 根据价差率确定所处的等级
  let level = PriceImpactLevel.NORMAL
  if (priceImpact.gt(0.0001)) {
    level = PriceImpactLevel.POSITIVE
  } else if (priceImpact.lt(-0.0001)) {
    level = priceImpact.gte(-0.03)
      ? PriceImpactLevel.WARN
      : PriceImpactLevel.DANGER
  }
  return {
    priceImpact: priceImpact.toFixed(4),
    priceImpactInPercent: priceImpact.multipliedBy(100).toFixed(2),
    priceImpactInUsd,
    level,
  }
}

export const useTokenlonV6ConfigUpdater = () => {
  const chainId = useUpdaterChainId()
  const v6SDK = useV6SDK()

  return useCallback(async (): Promise<AppConfig> => {
    if (!chainId) return
    try {
      return await v6SDK.swap.config()
    } catch (error) {
      console.error(error)
      return {} as AppConfig
    }
  }, [chainId])
}

export const useSpotSwapRecommendTokens = (): SpotSwapToken[] => {
  return useAppSelector(
    (state: AppState) => state.dex.spotSwapRecommendTokens || [],
  )
}

export const useLimitSwapRecommendTokens = (
  chainId: number | null,
): LimitSwapToken[] => {
  const isDev = ENV.development || ENV.local
  const isSelectedAll = chainId === null
  const targetChainIds = isSelectedAll
    ? isDev
      ? [Sepolia.chainId, ArbitrumRinkeby.chainId]
      : [Mainnet.chainId, ArbitrumOne.chainId]
    : [chainId]

  const allTokens = useAppSelector((state: AppState) => {
    return targetChainIds.reduce((acc, chainId) => {
      const chainTokens = state.dex.limitTokens[chainId] ?? {}
      return [...acc, ...Object.values(chainTokens)]
    }, [] as LimitSwapToken[])
  })
  const recommendTokens = allTokens.filter((token) => token.recommend)

  return recommendTokens
}

export const useIsDisableTrade = () => {
  return useAppSelector((state: AppState) => state.dex.isDisableTrade)
}

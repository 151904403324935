import styled from 'styled-components'

export const StyledOptionsContainer = styled.div`
  padding: 12px 16px;
  border-radius: 16px;
  background: #fafafc;
`

export const StyledOption = styled.button<{ disabled?: boolean }>`
  background: ${({ disabled }) => (disabled ? '#FAFAFC' : '#ffffff')};
  border: 1px solid #eff0fa;
  box-sizing: border-box;
  border-radius: 50px;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: ${({ disabled }) => (disabled ? '#D2D7E5' : '#1A1C1E')};
  padding: 10px 17px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color ease-in-out 0.2s;

  &:hover {
    background: #f5f7fb;
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  img {
    opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  }

  .logo {
    width: 24px;
    height: 24px;
    margin-right: 9px;
  }
  .indicator {
    margin-left: auto;
    width: 16px;
    height: 16px;
  }

  .option-checked,
  .option-unchecked {
    margin-left: 8px;
  }

  .option-unchecked {
    border: 1px solid #eff0fa;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
`

export const StyledShield = styled.img`
  width: 16px;
  height: 18px;
  margin-right: 8px;
`

export const StyledOptionsLabel = styled.div`
  margin: 0 0 8px 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  color: #9598ab;
`

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ReactComponent as IconDeny } from './assets/deny-slicing.svg'
import { ReactComponent as IconLoading } from './assets/ellipse.svg'
import { ReactComponent as IconTimeout } from './assets/timeout-slicing.svg'
import { ReactComponent as IconSuccess } from './assets/success-checked.svg'
import {
  useApprovalModalStateDispatch,
  useModalState,
  useModalToggle,
} from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import {
  useTransaction,
  useTransactionIsConfirmed,
} from '~/state/transactions/hooks'
import { BigNumber } from 'ethers'

export enum ApprovalProgress {
  APPROVING = 'APPROVING',
  APPROVE_REJECTED = 'APPROVE_REJECTED',
  APPROVE_SUCCESS = 'APPROVE_SUCCESS',
  CANCEL_APPROVING = 'CANCEL_APPROVING',
  CANCEL_REJECTED = 'CANCEL_REJECTED',
  CANCEL_SUCCESS = 'CANCEL_SUCCESS',
}

export const ApprovalProgressConfig = {
  [ApprovalProgress.APPROVING]: {
    i18n: 'approving_pls_sign',
    icon: IconLoading,
  },
  [ApprovalProgress.APPROVE_REJECTED]: {
    i18n: 'rejected',
    icon: IconDeny,
  },
  [ApprovalProgress.APPROVE_SUCCESS]: {
    i18n: 'approve_success',
    icon: IconSuccess,
  },
  [ApprovalProgress.CANCEL_APPROVING]: {
    i18n: 'unApproving',
    icon: IconLoading,
  },
  [ApprovalProgress.CANCEL_REJECTED]: {
    i18n: 'unApprove_rejected',
    icon: IconDeny,
  },
  [ApprovalProgress.CANCEL_SUCCESS]: {
    i18n: 'unApprove_success',
    icon: IconSuccess,
  },
}

export const ApproveStatusLabel = () => {
  const { t } = useTranslation()
  const { progress, hash, closeModalAfterApprovalConfirmed } = useModalState(
    ApplicationModal.APPROVAL_STATUS,
  )
  const toggleApprovalModal = useModalToggle(ApplicationModal.APPROVAL_STATUS)
  const updateApprovalState = useApprovalModalStateDispatch()
  const setting = ApprovalProgressConfig[progress]
  const txConfirmed = useTransactionIsConfirmed(hash)
  const tx = useTransaction(hash)

  const animationVariants = [
    ApprovalProgress.APPROVING,
    ApprovalProgress.CANCEL_APPROVING,
  ].some((i) => i === progress)
    ? {
        animate: { rotate: [0, 360] },
        transition: {
          duration: 1,
          ease: 'linear',
          repeat: Infinity,
        },
      }
    : {}

  useEffect(() => {
    if (txConfirmed && tx) {
      updateApprovalState({
        progress: BigNumber.from(tx.info.amountToApprove).isZero()
          ? ApprovalProgress.CANCEL_SUCCESS
          : ApprovalProgress.APPROVE_SUCCESS,
      })
      // auto close modal after 2s
      if (closeModalAfterApprovalConfirmed) {
        setTimeout(() => {
          toggleApprovalModal()
        }, 2000)
      }
    }
  }, [txConfirmed, hash])

  if (!setting)
    return (
      <StyledStatusLabel>
        <StyledMotion
          animate={{ rotate: [0, 360] }}
          transition={{
            duration: 1,
            ease: 'linear',
            repeat: Infinity,
          }}
        >
          <IconLoading />
        </StyledMotion>
      </StyledStatusLabel>
    )

  return (
    <StyledStatusLabel>
      <StyledMotion {...animationVariants}>
        <setting.icon />
      </StyledMotion>
      <span className="approve-label">{t(setting.i18n)}</span>
    </StyledStatusLabel>
  )
}

export const StyledMotion = styled(motion.span)`
  width: 16px;
  height: 16px;
`

export const StyledStatusLabel = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;
  background: linear-gradient(180deg, #30375b 0%, rgba(48, 55, 91, 0) 100%);
  border-radius: 6px 6px 0 0;
  height: 63px;
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  img {
    width: 16px;
    height: 16px;
  }

  .approve-label {
    color: rgb(179, 164, 255);
    font-size: 14px;
    font-weight: 500;
    margin-left: 14px;
    line-height: 20px;
    display: inline-block;
  }
`

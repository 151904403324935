import { isAddress } from 'ethers/lib/utils'
import { TransactionRequest } from '@ethersproject/abstract-provider'
import { parseUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'

export const isValidAddress = (address: any) =>
  address ? isAddress(address) : false

// @issue: https://imtoken.atlassian.net/browse/R2D2-1324
// Gas Price >= 21000 （单位：Gwei）
// maxPriorityFeeCap >= 21000（单位：Gwei）
// maxFeePerGas 不限制，maxFeePerGas 用不完会退回来
export const gasProtect = (tx: TransactionRequest) => {
  let { gasPrice, maxPriorityFeePerGas } = tx

  const limitedGasPrice = parseUnits('21000', 'gwei')
  const limitedMaxPriorityFeePerGas = parseUnits('21000', 'gwei')

  if (gasPrice && BigNumber.from(gasPrice).gt(limitedGasPrice)) {
    throw new Error(
      `Gas Price must be less than ${limitedGasPrice.toNumber()}, but given ${BigNumber.from(
        gasPrice,
      ).toNumber()}`,
    )
  }

  if (
    maxPriorityFeePerGas &&
    BigNumber.from(maxPriorityFeePerGas).gt(limitedMaxPriorityFeePerGas)
  ) {
    throw new Error(
      `maxPriorityFeePerGas must be less than ${limitedMaxPriorityFeePerGas.toNumber()}, but given ${BigNumber.from(
        maxPriorityFeePerGas,
      ).toNumber()}`,
    )
  }

  return tx
}

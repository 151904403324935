import { useWeb3React } from '@web3-react/core'
import { ArbitrumOne, ArbitrumRinkeby } from '~/model/chain/ethereum'

export const useNetworkValidator = () => {
  const { chainId } = useWeb3React()

  const ArbChainIds = [ArbitrumOne.chainId, ArbitrumRinkeby.chainId]

  const isArbitrum = ArbChainIds.includes(chainId)

  return {
    isArbitrum,
  }
}

import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useModalOpened, useModalToggle } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import { Agreement } from '../Agreement'
import { useState, useCallback, useMemo } from 'react'
import { useConnectionOptions } from '~/hooks/useConnectionOptions'
import ImKeyUnSupportedModal from './Components/ImKeyUnSupportedModal'
import HoTWarningModal from './Components/HoTWarningModal'
import {
  StyledOption,
  StyledOptionsContainer,
  StyledOptionsLabel,
  StyledShield,
} from './Components/StyledOptions'
import ArrowRight from '~/assets/images/right.png'
import { useAppDispatch } from '~/state/hooks'
import { updateSelectedConnectionType } from '~/state/user/actions'
import { useUpdaterChainId } from '~/hooks'
import { useLocalStorage } from '~/hooks/useLocalStorage'
import { Connection } from '~/connection/type'
import { isSafari, isFirefox } from '~/connection/detect'
import { getChainById } from '~/utils/chain'
// import IconShield from '~/assets/images/greenShield.svg'

const StyledAgreementContent = styled.div`
  margin-top: 12px;
`

interface ConnectionOptionsProps {
  hotCache: boolean
  setHotCache: (cache: boolean) => void
  setIsHotWarningOpen: (open: boolean) => void
  setActivateCallback: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | null>
  >
}

const ConnectionOptions: React.FC<ConnectionOptionsProps> = ({
  hotCache,
  setHotCache,
  setIsHotWarningOpen,
  setActivateCallback,
}) => {
  const options = useConnectionOptions()
  const filteredOptions = useMemo(() => {
    return options.reduce(
      (acc, curr) => {
        if (curr.name.includes('imToken App') || curr.name.includes('imKey')) {
          acc.imConnector.push(curr)
        } else {
          acc.commonConnector.push(curr)
        }
        return acc
      },
      { imConnector: [], commonConnector: [] },
    )
  }, [options])
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const toggleModal = useModalToggle(ApplicationModal.WALLET_CONNECT)
  const toggleImKeyUnsupportedModal = useModalToggle(
    ApplicationModal.IMKEY_UNSUPPORTED,
    { stack: true },
  )
  const targetChainId = useUpdaterChainId()

  const tryActivate = useCallback(
    async (option: Connection) => {
      try {
        if ((isSafari() || isFirefox()) && option.name === 'imKey') {
          toggleImKeyUnsupportedModal()
          return
        } else if (!hotCache && option.name === 'imToken Web (Beta)') {
          setIsHotWarningOpen(true)
          const activatePromise = new Promise<void>((resolve) => {
            setActivateCallback(() => async () => {
              await option.connector.activate(targetChainId)
              setHotCache(true)
              setIsHotWarningOpen(false)
              resolve()
            })
          })
          await activatePromise
        } else if (option.name === 'MetaMask') {
          // Using addChainParams to ensure that when MetaMask lacks the target network and cannot switchChain, it can call addChain to complete the connection
          // https://github.com/Uniswap/web3-react/blob/bd267025f06b5808fd6ccc4e18dde4d400c4076a/packages/metamask/src/index.ts#L166
          const chain = getChainById(targetChainId)
          const addChainParams: AddEthereumChainParameter = {
            chainId: targetChainId,
            blockExplorerUrls: [chain.explorer],
            chainName: chain.chainName,
            nativeCurrency: {
              name: chain.nativeSymbol,
              symbol: chain.nativeSymbol,
              decimals: 18,
            },
            rpcUrls: [chain.publicRpcUrl ?? chain.rpcUrl],
          }
          await option.connector.activate(addChainParams)
        } else if (option.activate) {
          await option.activate(option.connector, dispatch, targetChainId)
        } else {
          await option.connector.activate(targetChainId)
        }
        dispatch(updateSelectedConnectionType(option.type))
        toggleModal()
      } catch (err) {
        console.error('Failed to activate connector', err)
      }
    },
    [dispatch, targetChainId],
  )

  return (
    <div>
      <StyledOptionsContainer>
        {filteredOptions.imConnector.length > 0 && (
          <StyledOptionsLabel>
            <span>{t('connect_im_options_label')}</span>
          </StyledOptionsLabel>
        )}
        {filteredOptions.imConnector.map((option) => (
          <StyledOption key={option.type} onClick={() => tryActivate(option)}>
            <div>
              <img src={option.icon} alt={option.name} className="logo" />
              {option.name}
            </div>

            <div>
              {/* <StyledShield src={IconShield} /> */}
              <img src={ArrowRight.src} alt="" className="indicator" />
            </div>
          </StyledOption>
        ))}
        {filteredOptions.commonConnector.length > 0 && (
          <StyledOptionsLabel>{t('others')}</StyledOptionsLabel>
        )}
        {filteredOptions.commonConnector.map((option) => (
          <StyledOption key={option.type} onClick={() => tryActivate(option)}>
            <div>
              <img src={option.icon} alt={option.name} className="logo" />
              {option.name}
            </div>

            <img src={ArrowRight.src} alt="" className="indicator" />
          </StyledOption>
        ))}
      </StyledOptionsContainer>
    </div>
  )
}

export const WalletModal = () => {
  const isOpen = useModalOpened(ApplicationModal.WALLET_CONNECT)
  const toggleModal = useModalToggle(ApplicationModal.WALLET_CONNECT)
  const { t } = useTranslation()

  const HOT_WARNING_MODAL_KEY = 'hot-warning-modal-visited'
  const [hotCache, setHotCache] = useLocalStorage(HOT_WARNING_MODAL_KEY, false)
  const [isHotWarningOpen, setIsHotWarningOpen] = useState(false)
  const [activateCallback, setActivateCallback] = useState<
    (() => Promise<void>) | null
  >(null)

  const handleConfirm = useCallback(async () => {
    if (activateCallback) {
      await activateCallback()
      setActivateCallback(null)
    }
  }, [activateCallback])

  const handleHotWarningClose = () => {
    setIsHotWarningOpen(false)
    setActivateCallback(null)
  }

  return (
    <>
      <Modal title={t('connect')} isOpen={isOpen} onDismiss={toggleModal}>
        <Agreement />
        <div className="content">
          <StyledAgreementContent>
            <ConnectionOptions
              hotCache={hotCache}
              setHotCache={setHotCache}
              setIsHotWarningOpen={setIsHotWarningOpen}
              setActivateCallback={setActivateCallback}
            />
          </StyledAgreementContent>
        </div>
      </Modal>
      <ImKeyUnSupportedModal />
      {!hotCache && (
        <HoTWarningModal
          isOpen={isHotWarningOpen}
          onClose={handleHotWarningClose}
          onConfirm={handleConfirm}
        />
      )}
    </>
  )
}

export default WalletModal

const trace = {
  click: (
    eventName: string,
    eventParams: { event_label?: string; value?: string; [key: string]: any } = {},
  ) => {
    window.gtag('event', eventName, {
      event_category: 'click',
      ...eventParams,
    })
  },
  view: (
    eventName: string,
    eventParams: { event_label?: string; value?: string; [key: string]: any } = {},
  ) => {
    window.gtag('event', eventName, {
      event_category: 'view',
      ...eventParams,
    })
  },
}

export default trace

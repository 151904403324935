import { createAction } from '@reduxjs/toolkit'

export interface SerializableTransactionReceipt {
  to: string
  from: string
  contractAddress: string
  transactionIndex: number
  blockHash: string
  transactionHash: string
  blockNumber: number
  status?: number
}

export type TransactionInfo = ApproveTransactionInfo
// @todo more txInfo support
// | BasicSwapTransactionInfo
// | ExactInputSwapTransactionInfo
// | ExactOutputSwapTransactionInfo
// | BuybackTransactionInfo
// | AddLiquidTransactionInfo
// | RemoveLiquidTransactionInfo

export enum TransactionType {
  APPROVAL,
}

export interface BaseTransactionInfo {
  type: TransactionType
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.APPROVAL
  tokenAddress: string
  spender: string
  // for serialize, it should be a string
  amountToApprove: string
}

export const addTransaction = createAction<{
  chainId: number
  hash: string
  from: string
  info: TransactionInfo
}>('transactions/addTransaction')

export const checkedTransaction = createAction<{
  chainId: number
  hash: string
  blockNumber: number
}>('transactions/checkedTransaction')

export const clearAllTransactions = createAction<{ chainId: number }>(
  'transactions/clearAllTransactions',
)

export const finalizeTransaction = createAction<{
  chainId: number
  hash: string
  receipt: SerializableTransactionReceipt
}>('transactions/finalizeTransaction')

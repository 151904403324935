import { useEffect, useRef } from 'react'
import { useBlockNumber } from '~/state/application/hooks'

export const useUpdaterByBlock = (
  updater: (blockNumber: number) => any,
  blocksPerFetch = 10,
) => {
  const blockNumber = useBlockNumber()
  const blocksPerFetchRef = useRef(0)

  // first run
  useEffect(() => {
    updater(blockNumber)
  }, [])

  useEffect(() => {
    if (blocksPerFetch === 0) return
    if (!blocksPerFetchRef.current) {
      blocksPerFetchRef.current = blockNumber
      return
    }

    if (blockNumber - blocksPerFetchRef.current >= blocksPerFetch) {
      blocksPerFetchRef.current = blockNumber
    }
  }, [blockNumber])

  useEffect(() => {
    console.log('updater by blockNumber', blocksPerFetchRef.current)
    updater(blocksPerFetchRef.current)
  }, [blocksPerFetchRef.current, updater])
}

export default useUpdaterByBlock

import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { networkConnection } from '~/connection'
import { useIsWindowVisible } from '~/hooks/useIsWindowVisible'
import useUpdaterByBlock from '~/hooks/useUpdaterByBlock'
import { useAppDispatch } from '../hooks'
import { updateBlockNumber, updateChainId, updateFeeData } from './reducer'
import { getBlocksPerFetchForChainId } from '~/utils/getBlocksPerFetchForChainId'
import { isSupportedChain } from '~/utils/chain'

export const Updater: React.FC = () => {
  useNetworkConnectorUpdater()
  useFeeDataUpdater()
  useBlockNumberUpdater()
  const dispatch = useAppDispatch()
  const { chainId } = useWeb3React()

  useEffect(() => {
    dispatch(updateChainId({ chainId }))
  }, [dispatch, chainId])

  return null
}

export const useBlockNumberUpdater = () => {
  const windowVisible = useIsWindowVisible()
  const dispatch = useAppDispatch()
  const { chainId: activeChainId, provider } = useWeb3React()
  const [{ blockNumber }, setChainBlock] = useState<{
    blockNumber?: number
    chainId?: number
  }>({ chainId: activeChainId })
  const onBlock = useCallback(
    (block: number) => {
      setChainBlock((chainBlock) => {
        if (chainBlock.chainId === activeChainId || !chainBlock.chainId) {
          const newChainBlock = {
            blockNumber: Math.max(chainBlock.blockNumber || 0, block),
            chainId: activeChainId,
          }
          dispatch(updateBlockNumber(newChainBlock))
          return newChainBlock
        }
        dispatch(updateBlockNumber(chainBlock))
        return chainBlock
      })
    },
    [activeChainId, setChainBlock],
  )
  useEffect(() => {
    let stale = false
    if (provider && activeChainId && windowVisible) {
      // reset
      setChainBlock((chainBlock) => {
        return chainBlock.chainId === activeChainId
          ? chainBlock
          : { chainId: activeChainId }
      })

      provider
        .getBlockNumber()
        .then((block) => {
          if (!stale) onBlock(block)
        })
        .catch((error) => {
          console.error(
            `Failed to get block number for chainId: ${activeChainId}`,
            error,
          )
        })
      provider.on('block', onBlock)
      return () => {
        stale = true
        provider.removeListener('block', onBlock)
      }
    }
  }, [activeChainId, onBlock, windowVisible, provider])

  return blockNumber
}

export const useFeeDataUpdater = () => {
  const { provider, chainId } = useWeb3React()
  const windowVisible = useIsWindowVisible()
  const dispatch = useAppDispatch()
  const blocksPerFetch = useMemo(() => getBlocksPerFetchForChainId(chainId), [
    chainId,
  ])

  const feeDataCallback = useCallback(() => {
    if (provider && windowVisible) {
      provider
        .getFeeData()
        .then((data) => {
          dispatch(
            updateFeeData({
              chainId,
              feeData: {
                gasPrice: data.gasPrice.toString(),
                maxFeePerGas: data.maxFeePerGas.toString(),
                maxPriorityFeePerGas: data.maxPriorityFeePerGas.toString(),
              },
            }),
          )
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [provider, chainId, windowVisible])

  useUpdaterByBlock(feeDataCallback, blocksPerFetch)
}

export const useNetworkConnectorUpdater = () => {
  const { chainId, connector } = useWeb3React()

  useEffect(() => {
    if (
      chainId &&
      connector !== networkConnection.connector &&
      isSupportedChain(chainId)
    ) {
      networkConnection.connector.activate(chainId)
    }
  }, [chainId, connector])
}

export default Updater

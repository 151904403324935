import { useMemo } from 'react'
import { useCurrentChainId } from './useCurrentChainId'
import { useWETHRouter, useSpotSwapRouter, useLimitOrderRouter } from '~/hooks'
import { useOneOfModalOpened } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import {
  getCommonUpdaterChainId,
  getWETHSupportedNetworks,
  getSpotSwapSupportedNetworks,
  getLimitOrderSupportedNetworks,
} from '~/utils/chain'

export const useIsValidNetwork = () => {
  const chainId = useCurrentChainId()
  const isWETHRouter = useWETHRouter()
  const isSpotSwapRouter = useSpotSwapRouter()
  const isLimitRouter = useLimitOrderRouter()
  const wethWrapOpened = useOneOfModalOpened([
    ApplicationModal.WETH_WRAP,
    ApplicationModal.WETH_PREVIEW,
    ApplicationModal.WETH_RESULT,
  ])

  return useMemo(() => {
    if (isWETHRouter || wethWrapOpened) {
      const WETHSupportedChains = getWETHSupportedNetworks()
      return WETHSupportedChains.includes(chainId)
    } else if (isSpotSwapRouter) {
      const SpotSwapSupportedChains = getSpotSwapSupportedNetworks()
      return SpotSwapSupportedChains.includes(chainId)
    } else if (isLimitRouter) {
      const LOSupportedChains = getLimitOrderSupportedNetworks()
      return LOSupportedChains.includes(chainId)
    } else {
      const defaultSupportedChainId = getCommonUpdaterChainId()
      return defaultSupportedChainId === chainId
    }
  }, [isLimitRouter, isWETHRouter, isSpotSwapRouter, wethWrapOpened, chainId])
}

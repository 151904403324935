import { isClient } from './userAgent'

// all host path ends with '/'
export const host = {
  get origin() {
    if (!isClient) return '/'

    const { origin } = window.location

    if (origin.endsWith('/')) return origin
    return `${origin}/`
  },
  get href() {
    if (!isClient) return '/'

    const host = window.location.href
    if (host.endsWith('/')) return host
    return `${host}/`
  },
}

import { captureException } from '@sentry/nextjs'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'bignumber.js'
import { isAddress } from 'ethers/lib/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUpdaterChainId } from '~/hooks'
import { FixedPointNumber } from '~/utils/fixedPoint'
import { useInterfaceMulticall } from '~/hooks/useContract'
import {
  useMultipleContractSingleData,
  useSingleContractMultipleData,
} from '../multicall/hooks'
import { ERC20Interface } from '~/constants/abi'
import { getChainById } from '~/utils/chain'
import { getBlocksPerFetchForChainId } from '~/utils/getBlocksPerFetchForChainId'
import TokenLogoETH from '~/assets/images/eth.svg'

export const useETHBalance = (withDecimal = true): BigNumber => {
  const { provider, account } = useWeb3React()
  const expectChainId = useUpdaterChainId()
  // 未获取到时为 undefined, 和 balance 为 0 区分开
  const [ethBalance, setETHBalance] = useState<BigNumber | undefined>()
  const getETHBalance = useCallback(async () => {
    if (!provider || !account || !expectChainId) return

    try {
      const balance = await provider.getBalance(account)

      setETHBalance(new BigNumber(balance.toString()))
      console.log('balance', balance.toString())
    } catch (error) {
      console.error('Failed to get ETH balance', error)
      captureException(error)
    }
  }, [expectChainId, account, provider])

  useEffect(() => {
    getETHBalance()

    return () => {
      setETHBalance(undefined)
    }
  }, [getETHBalance])

  return withDecimal ? ethBalance : ethBalance?.shiftedBy(-18)
}

export const useNativeTokenBalances = (
  uncheckedAccounts?: Array<string | undefined>,
): { [account: string]: FixedPointNumber | undefined } => {
  const expectChainId = useUpdaterChainId()
  const multicall = useInterfaceMulticall()

  const validAccounts: [string][] = useMemo(() => {
    return uncheckedAccounts
      .map((r) => (isAddress(r) ? r : false))
      .filter((r): r is string => r !== false)
      .map((address) => [address])
  }, [uncheckedAccounts])

  const listenerOptions = useMemo(
    () => ({ blocksPerFetch: getBlocksPerFetchForChainId(expectChainId) ?? 1 }),
    [expectChainId],
  )

  const results = useSingleContractMultipleData(
    multicall,
    'getEthBalance',
    validAccounts,
    listenerOptions,
  )

  return useMemo(() => {
    return validAccounts.reduce<{ [account: string]: FixedPointNumber }>(
      (memo, [address], i) => {
        const value = results?.[i]?.result?.[0]
        if (value && expectChainId) {
          memo[address] = new FixedPointNumber(value, 18)
        }
        return memo
      },
      {},
    )
  }, [validAccounts, results, expectChainId])
}

export const useNativeTokenBalance = (): FixedPointNumber | undefined => {
  const { account } = useWeb3React()
  const balances = useNativeTokenBalances(account ? [account] : [])?.[account]

  return balances
}

export const useTokenBalances = (
  account?: string,
  tokens?: ERC20Token[],
): { [tokenAddress: string]: FixedPointNumber } => {
  const { chainId } = useWeb3React()

  const validTokens = useMemo(
    () => tokens.filter((t) => t && isAddress(t.address)),
    [tokens],
  )

  const validAddresses = useMemo(() => validTokens.map((t) => t.address), [
    validTokens,
  ])

  const listenerOptions = useMemo(
    () => ({ blocksPerFetch: getBlocksPerFetchForChainId(chainId) ?? 1 }),
    [chainId],
  )

  const balances = useMultipleContractSingleData(
    validAddresses,
    ERC20Interface,
    'balanceOf',
    [account],
    listenerOptions,
  )

  const anyLoading: boolean = useMemo(
    () => balances.some((callState) => callState.loading),
    [balances],
  )

  return useMemo(() => {
    return validAddresses.reduce<{ [tokenAddress: string]: FixedPointNumber }>(
      (memo, address, i) => {
        const value = balances?.[i]?.result?.[0]
        if (value) {
          memo[address] = new FixedPointNumber(
            value,
            Number(validTokens[i].decimals),
          )
        }
        return memo
      },
      {},
    )
  }, [account, anyLoading, balances, validAddresses, validTokens])
}

export const useTokenBalance = (
  account?: string,
  token?: ERC20Token,
): FixedPointNumber | undefined => {
  const tokenBalances = useTokenBalances(account, [token])

  if (!token) return undefined

  return tokenBalances?.[token.address]
}

export const useNativeCurrencySymbol = () => {
  const { chainId } = useWeb3React()

  return useMemo(() => {
    const chain = getChainById(chainId)
    return chain?.nativeSymbol || 'ETH'
  }, [chainId])
}

export const useNativeCurrencyLogo = () => {
  const { chainId } = useWeb3React()

  return useMemo(() => {
    const chain = getChainById(chainId)
    return chain?.nativeCurrencyLogo || TokenLogoETH
  }, [chainId])
}

const LOCK_CLASS_NAME = 'lock-body'
const injectCss = () => {
  const TagId = 'tokenlon-lock-body'
  const injectedCSS = document.getElementById(TagId)
  if (!injectedCSS) {
    const injectedEl = document.createElement('style')
    injectedEl.setAttribute('id', TagId)
    injectedEl.innerHTML = `
      html.${LOCK_CLASS_NAME} {
        overflow-y: hidden !important;
        position: fixed;
        width: 100%;
      }
    `
    document.head.appendChild(injectedEl)
  }
}

const lock = () => {
  document.documentElement.style.top =
    -document.scrollingElement.scrollTop + 'px'
  document.documentElement.classList.add(LOCK_CLASS_NAME)
}

const relieveLock = () => {
  document.documentElement.classList.remove(LOCK_CLASS_NAME)
  document.scrollingElement.scrollTop = -parseInt(
    document.documentElement.style.top,
    10,
  )
  document.documentElement.style.top = ''
}

export const lockBody = (lockBody: boolean) => {
  injectCss()

  lockBody ? lock() : relieveLock()
}

import { CSSProperties, useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { useForceUpdate } from '~/hooks'

export interface PortalProps {
  type?: string
  containerRef?: React.RefObject<HTMLElement>
  direct?: boolean
}

export const Portal: React.FC<PortalProps> = ({
  children,
  type = 'div',
  containerRef,
  direct,
}) => {
  let mountNode = useRef<HTMLDivElement | null>(null)
  let portalNode = useRef<HTMLElement | null>(null)
  let forceUpdate = useForceUpdate()

  useLayoutEffect(() => {
    // This ref may be null when a hot-loader replaces components on the page
    if (!mountNode.current) return
    // It's possible that the content of the portal has, itself, been portaled.
    // In that case, it's important to append to the correct document element.
    let ownerDocument = mountNode.current!.ownerDocument
    let body = containerRef?.current || ownerDocument.body
    portalNode.current = ownerDocument?.createElement(type)!
    body.appendChild(portalNode.current)
    forceUpdate()
    return () => {
      if (portalNode.current && body) {
        body.removeChild(portalNode.current)
      }
    }
  }, [type, forceUpdate, containerRef?.current])

  if (direct && containerRef?.current) {
    return createPortal(children, containerRef.current)
  }

  return portalNode.current ? (
    createPortal(children, portalNode.current)
  ) : (
    <span ref={mountNode} />
  )
}

export const PortalRef: React.FC<{ id: string; style?: CSSProperties }> = ({
  id,
  ...props
}) => {
  return <StyledPortalRef id={id} {...props} />
}

const StyledPortalRef = styled.div`
  height: auto;
`

export default Portal

import { QuoteDTO, TokenlonSDK, Order } from '@tokenlon/v6-sdk'
import { applyGasUsedBuffer } from '~/utils/gasSpeedup'
import { parseUnits } from 'viem'

export interface OrderDisplayInfo {
  rate: string
  fee?: bigint
  protocolFee?: bigint
  minMakerTokenAmount?: bigint
  makerTokenAmount?: bigint
  gasFeeTokenAmount?: bigint
}

interface GetRateOrOrderResult {
  order: Order
  usefulOrderInfo: OrderDisplayInfo
}

interface GetRateOrOrderParams {
  sdk: TokenlonSDK
  inputToken: SpotSwapToken
  outputToken: SpotSwapToken
  account: string
  amount: string
  slippageBPS: number
  isSendByRelayer: boolean
  gasPrice: bigint
  defaultGasLimit: bigint
  gasLimitScale: bigint
  recipient: string
}

export const getRateOrOrder = async ({
  sdk,
  inputToken,
  outputToken,
  account,
  amount,
  slippageBPS,
  isSendByRelayer,
  gasPrice,
  defaultGasLimit,
  gasLimitScale,
  recipient,
}: GetRateOrOrderParams): Promise<GetRateOrOrderResult> => {
  const quoteDTO = {
    userAddress: account ?? '',
    fromToken: inputToken?.address,
    toToken: outputToken?.address,
    amount,
    slippage: slippageBPS,
    chainId: inputToken?.chainId,
    toChainId: outputToken?.chainId,
    recipient,
  } as QuoteDTO

  const isCrossSwap = inputToken?.chainId !== outputToken?.chainId

  const order = await sdk.swap.quote(quoteDTO)
  const gasUsed = BigInt(order.gasUsed)
  const gasUsedWithScale = applyGasUsedBuffer(gasUsed)
  let gasLimit = gasUsedWithScale || defaultGasLimit
  if (!gasUsedWithScale && !!quoteDTO.userAddress) {
    try {
      const estimate = await sdk.swap.estimate({
        order,
        slippage: quoteDTO.slippage,
        account: quoteDTO.userAddress,
      })
      gasLimit = (estimate * gasLimitScale) / 100n
    } catch (error) {}
  }
  const gasFee = gasLimit * gasPrice
  const {
    fee,
    swapData,
    protocolFee,
    gasFeeTokenAmount,
  } = await sdk.swap.prepareOrder({
    order,
    slippage: quoteDTO.slippage,
    sendByRelayer: isSendByRelayer,
    gasFee,
  })

  const makerTokenAmount = isCrossSwap
    ? parseUnits(order.amountOut, outputToken?.decimals)
    : swapData.makerTokenAmount

  const minMakerTokenAmount = isCrossSwap
    ? parseUnits(order.amountOut, outputToken?.decimals)
    : swapData.minMakerTokenAmount

  const usefulOrderInfo = {
    rate: order.rate,
    fee,
    protocolFee,
    makerTokenAmount,
    minMakerTokenAmount,
    gasFeeTokenAmount,
  }
  return {
    order,
    usefulOrderInfo,
  }
}

import { useWeb3React } from '@web3-react/core'
import { useUpdaterChainId } from '~/hooks'
import {
  TokenlonSDK,
  SupportedChain,
  ALL_SUPPORTED_CHAINS,
} from '@tokenlon/v6-sdk'
import { TokenlonEnv, ENV } from '~/constants/env'
import { createPublicClient, http } from 'viem'
import EthereumProvider from 'eip1193-provider'
import { getChainById } from '~/utils/chain'

export const useV6SDK = () => {
  const { provider } = useWeb3React()
  const expectChainId = useUpdaterChainId()
  const rpcUrl = getChainById(expectChainId).rpcUrl

  const env = ENV.production
    ? TokenlonEnv.production
    : ENV.staging
    ? TokenlonEnv.staging
    : TokenlonEnv.development

  const eip1193Provider = new EthereumProvider(rpcUrl)
  const client = createPublicClient({
    chain: ALL_SUPPORTED_CHAINS.find((c) => c.id === expectChainId),
    transport: http(rpcUrl, {
      batch: true,
    }),
  })

  const sdk = new TokenlonSDK({
    chain: expectChainId as SupportedChain,
    env,
    client,
    provider: (provider?.provider ?? eip1193Provider) as any,
    rpcUrl,
  })

  return sdk
}

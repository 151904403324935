import { createElement } from 'react'
import { unmountComponentAtNode, render } from 'react-dom'
import { StyledMessage } from './styled'
// @notice: import at _app.ts
// import './Message.css'
import { TokenlonMessageType, TokenlonMessageProps } from './types'
import trace from '~/ga'

export const TokenlonMessage: React.FC<TokenlonMessageProps> = ({
  type = 'success',
  children,
}) => {
  return <StyledMessage type={type}>{children}</StyledMessage>
}

export class Message {
  container

  destroyAll() {
    while (this.container.firstChild) {
      this.destroy(this.container.firstChild)
    }
  }

  destroy(div: HTMLDivElement) {
    const unmountResult = unmountComponentAtNode(div)
    if (unmountResult && div.parentNode) div.parentNode.removeChild(div)
  }

  showMessageBox(type: TokenlonMessageType) {
    if (typeof window !== 'undefined' && window['imToken']) {
      return (content: string) => {
        window.imToken.callAPI('native.toast', {
          type: type === 'error' ? 'warning' : type,
          message: content,
          align: 'top',
          model: 'banner',
          duration: 3000,
        })
      }
    }
    if (!this.container && typeof document !== 'undefined') {
      this.container = document.createElement('div')
      this.container.classList = 'message-container'
      document.body.appendChild(this.container)
    }
    return function createMessageBox(content: string) {
      const div = document.createElement('div')
      this.container.insertBefore(div, this.container.firstChild)
      const Box = createElement(TokenlonMessage, { type }, content)

      render(Box, div)

      trace.view('view_toast_message', { type, content })

      // todo: @izayl extract duration
      setTimeout(() => {
        this.destroy(div)
      }, 3000)
    }
  }

  error = this.showMessageBox('error')
  success = this.showMessageBox('success')
}

export const message = new Message()

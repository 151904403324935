import { useTranslation } from 'react-i18next'
import Modal from '~/components/Modal'
import styled from 'styled-components'
import IconWarning from '~/assets/images/warning-black.svg'

interface HoTWarningModalProps {
  isOpen: boolean
  onConfirm: () => Promise<void>
  onClose: () => void
}

const HoTWarningModal: React.FC<HoTWarningModalProps> = ({
  isOpen,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('hot_warning_modal.title')}
      isOpen={isOpen}
      onDismiss={onClose}
      animate={false}
      contentStyle={{ maxWidth: '500px' }}
    >
      <StyledContainer>
        <StyledContent>
          <StyledIcon src={IconWarning} alt="warning" />
          <p>{t('hot_warning_modal.content_desc')}</p>
        </StyledContent>
        <StyledBtn onClick={onConfirm}>
          {t('hot_warning_modal.connect_btn')}
        </StyledBtn>
      </StyledContainer>
    </Modal>
  )
}

export default HoTWarningModal

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px 16px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border-radius: 20px;
  background-color: #f7f7f9;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #81808d;
  }
`

const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`

const StyledBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
  padding: 12px 0;
  border-radius: 48px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.btnPrimary};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
`

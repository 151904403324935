import styled, { css } from 'styled-components'

export const StyledSwitchButton = styled.button<{
  active?: boolean
  disabled?: boolean
}>`
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.36s;
  transition: all 0.36s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &,
  &:hover,
  &:focus {
    outline: none;
  }

  ${({ active }) =>
    active &&
    css`
      background: #5e4dbc;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`

export const StyledSwitchDot = styled.span<{
  active?: boolean
  disabled?: boolean
}>`
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);

  ${({ active }) =>
    active &&
    css`
      left: 60%;
      margin-left: -1px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const StyledSwitchLoading = styled.i<{
  active?: boolean
  disabled?: boolean
}>`
  color: #5e4dbc;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';

  display: inline-block;
  -webkit-animation: switchCircle 1s infinite linear;
  animation: switchCircle 1s infinite linear;

  &::before {
    display: inline-block;
    -webkit-animation: switchCircle 1s infinite linear;
    animation: switchCircle 1s infinite linear;
  }

  @-webkit-keyframes switchCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes switchCircle {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  ${({ active }) =>
    active &&
    css`
      left: 60%;
      margin-left: -1px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`

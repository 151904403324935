import { useMemo } from 'react'
import { WETH as WETH_ADDRESSES } from '~/constants/addresses'
import { WETHABI } from '~/constants/abi'
import { useContract } from './useContract'
import { message } from '~/components/Message'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { WETH } from '~/constants/abi/types/WETH'
import { ethers } from 'ethers'
import {
  ArbitrumOne,
  ArbitrumRinkeby,
  Mainnet,
  Sepolia,
} from '~/model/chain/ethereum'
import { useConnectType } from '~/state/user/hooks'
import { WalletType } from '~/constants/walletType'
import { useFeeData } from '~/state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import { getWETHSupportedNetworks } from '~/utils/chain'
import { useCurrentChainId } from './useCurrentChainId'

const OVERRIDES = {
  // @notice: imToken App(2.11.2) arbitrum 网络不支持传入 type=2, 会导致交易失败：maxFee 会被设置为0
  [ArbitrumOne.chainId]: {
    type: 0,
    gasLimit: 210_000,
  },
  [ArbitrumRinkeby.chainId]: { type: 0, gasLimit: 60_000 },
  [Mainnet.chainId]: {
    type: 2,
    gasLimit: 50_000,
  },
  [Sepolia.chainId]: {
    type: 2,
    gasLimit: 50_000,
  },
}

export const useWETH = () => {
  const { chainId } = useWeb3React()
  const WETH_ADDRESS = WETH_ADDRESSES[chainId]
  return useContract<WETH>(WETH_ADDRESS, WETHABI.abi)
}

export const useWETHDefaultOverrides = () => {
  const connectType = useConnectType()
  const feeData = useFeeData()
  const { chainId } = useWeb3React()
  const { type, gasLimit } = OVERRIDES[chainId] || {}
  // @notice: imToken App(2.11.2) wc 连接下不支持传入 type=2, 会导致交易一直处于 pending 中
  const isWalletConnect = [
    WalletType.IMTOKEN_WALLET_CONNECT,
    WalletType.METAMASK_WALLET_CONNECT,
    WalletType.WALLET_CONNECT,
  ].includes(connectType)
  const txType = isWalletConnect ? 0 : type
  const gas =
    txType === 0
      ? {
          gasPrice: feeData?.gasPrice,
        }
      : {
          maxFeePerGas: feeData?.maxFeePerGas,
          maxPriorityFeePerGas: feeData?.maxPriorityFeePerGas,
        }

  return {
    type: txType || 0,
    gasLimit: gasLimit || 210_000,
    ...gas,
  }
}
export const useWETHDepositCallback = () => {
  const WETH = useWETH()
  const overrides = useWETHDefaultOverrides()
  const estimateGas = useCallback(
    (value: ethers.BigNumber = ethers.BigNumber.from(1)) => {
      if (WETH && ethers.BigNumber.isBigNumber(value)) {
        return WETH.estimateGas
          .deposit({ value })
          .then((gas) => ethers.BigNumber.from(gas.toString()))
          .catch((error) => {
            console.warn('failed to estimate WETH deposit', error)
            return ethers.BigNumber.from(overrides.gasLimit)
          })
      }
      return ethers.BigNumber.from(overrides.gasLimit)
    },
    [WETH, overrides.gasLimit],
  )

  const depositCallback = useCallback(
    (
      amount: ethers.BigNumberish,
      estimateGasLimit: ethers.BigNumberish = 0,
    ) => {
      if (!WETH) {
        message.error('Wrong Network!')
        return Promise.reject(0)
      }

      let gasLimit
      try {
        gasLimit = estimateGasLimit
        gasLimit = BigNumber.max(
          gasLimit.toString(),
          overrides.gasLimit,
        ).toString()
      } catch (error) {
        gasLimit = ethers.BigNumber.from(overrides.gasLimit)
      }
      console.log({
        ...overrides,
        gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100).toString(),
      })
      return WETH.deposit({
        value: ethers.BigNumber.from(amount),
        ...overrides,
        gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100).toString(),
      })
    },
    [WETH],
  )

  return {
    deposit: depositCallback,
    estimateGas,
  }
}

export const useWETHWithdrawCallback = () => {
  const WETH = useWETH()
  const overrides = useWETHDefaultOverrides()
  const estimateGas = useCallback(
    (amount: ethers.BigNumber = ethers.BigNumber.from(1)) => {
      if (WETH) {
        return WETH.estimateGas
          .withdraw(amount)
          .then((gas) => ethers.BigNumber.from(gas.toString()))
          .catch((error) => {
            console.warn('error estimate WETH withdraw', error)
            return ethers.BigNumber.from(overrides.gasLimit)
          })
      }
      return ethers.BigNumber.from(overrides.gasLimit)
    },
    [WETH, overrides.gasLimit],
  )

  const withdrawCallback = useCallback(
    (
      amount: ethers.BigNumberish,
      estimateGasLimit: ethers.BigNumberish = 0,
    ) => {
      if (!WETH) {
        message.error('Wrong Network!')
        return Promise.reject(0)
      }
      let gasLimit
      try {
        gasLimit = estimateGasLimit
        gasLimit = BigNumber.max(
          gasLimit.toString(),
          overrides.gasLimit,
        ).toString()
      } catch (error) {
        gasLimit = ethers.BigNumber.from(overrides.gasLimit)
      }
      console.log('deposit', {
        ...overrides,
        gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100),
      })
      return WETH.withdraw(amount, {
        ...overrides,
        gasLimit: ethers.BigNumber.from(gasLimit).mul(120).div(100),
      })
    },
    [WETH],
  )

  return {
    withdraw: withdrawCallback,
    estimateGas,
  }
}

export const useIsValidWETHNetwork = () => {
  const chainId = useCurrentChainId()

  return useMemo(() => {
    const targetChainIds = getWETHSupportedNetworks()
    return targetChainIds.includes(chainId)
  }, [chainId])
}

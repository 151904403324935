import { useWeb3React } from '@web3-react/core'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AddressZero } from '@ethersproject/constants'
import { useModalOpened, useModalToggle } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import IconCopy from '~/assets/images/copy-bordered-black.svg'
import { message } from '../Message'
import Modal from '../Modal'
import { Column, Box, RowBetween } from '../Layout'
import { Type } from '~/theme'
import { TokenInfo } from './TokenInfo'
import { MenuItem } from './MenuItem'
import IconLON from '~/assets/images/lon.svg'
import IconMenuRecords from '~/assets/images/menu-clock.svg'
import IconMenuSettings from '~/assets/images/menu-settings.svg'
import IconMenuDisconnections from '~/assets/images/menu-disconnect.svg'
import { useChain } from '~/hooks/useContract'
import {
  getExplorerAddressLink,
  getCommonUpdaterChainId,
  isMainnetOrSepolia,
} from '~/utils/chain'
import { useFeeFactor } from '~/state/user/hooks'
import { useAppDispatch } from '~/state/hooks'
import {
  updateSelectedConnectionType,
  updateWalletType,
} from '~/state/user/actions'
import {
  useNativeCurrencySymbol,
  useNativeCurrencyLogo,
} from '~/state/wallet/hooks'
import IconArrow from '~/assets/images/arrow-right-purple-light.svg'
import { LOCALES } from '~/locales'
import { useGA, useUpdaterChainId } from '~/hooks'
import { useTokenlonV6NetworkConfig } from '~/state/dex/hooks'

const Icon = styled.img`
  width: 28px;
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;
`

const AddressInfo = () => {
  const { account } = useWeb3React()
  const { t, i18n } = useTranslation()
  const { trackElClickCurring, trackElClick } = useGA()
  const expectedChainId = useUpdaterChainId()
  const isMainnetOrSepoliaFlag = isMainnetOrSepolia(expectedChainId)
  const defaultL1ChainId = getCommonUpdaterChainId()
  const l1FeeFactor = useFeeFactor(defaultL1ChainId)
  const { feeFactor: configFeeFactor = 15 } = useTokenlonV6NetworkConfig()
  let l2FeeFactor = isMainnetOrSepoliaFlag ? 15 : configFeeFactor

  const mainnetFeeLabel =
    l1FeeFactor === 30 ? t('default') : t('discounted_trade_fee')
  const mainnetFeeLiteral = `${t('mainnet')} ${
    l1FeeFactor / 100
  }% (${mainnetFeeLabel})`
  const multiFeeLiteral = `${t('multi_network')} ${l2FeeFactor / 100}%`

  const lang = i18n.resolvedLanguage === LOCALES.ZH ? 'zh-cn' : 'en-us'
  const FeeIntroLink = `https://support.tokenlon.im/hc/${lang}/articles/360037260272`

  const copyAddr = () => {
    copy(account)
    message.success(t('copy_success'))
  }

  return (
    <Box
      mb="18px"
      borderRadius="16px"
      padding="20px 16px"
      backgroundColor="#36305B"
    >
      <Column style={{ width: '100%' }}>
        <Type.Label>{t('wallet_address')}</Type.Label>
        <RowBetween
          data-trackid="webtl_mywallet_copy"
          onClick={(e) => trackElClickCurring(e)(copyAddr)}
          borderRadius="8px"
          padding="8px"
          margin="8px 0 12px"
          backgroundColor="#3F3965"
        >
          <Box pointer width="80%" overflowWrap="anywhere">
            <Type.Address
              style={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14px',
                color: '#fff',
                wordBreak: 'break-all',
              }}
            >
              {account}
            </Type.Address>
          </Box>
          <Icon src={IconCopy} />
        </RowBetween>

        <RowBetween margin="5px 0 0">
          <Type.Label>{t('info_swap_trade_fee')}</Type.Label>
          <a
            href={FeeIntroLink}
            target="_blank"
            data-trackid="webtl_mywallet_txfee"
            data-trackdata={JSON.stringify({ url: FeeIntroLink })}
            onClick={trackElClick}
          >
            <Type.Label
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
                fontSize: '11px',
                lineHeight: '13px',
                color: '#C0B4FF',
                cursor: 'pointer',
              }}
            >
              {t('info_trade_fee_intro')}
              <img src={IconArrow} />
            </Type.Label>
          </a>
        </RowBetween>
        <Type.Label
          style={{
            marginTop: '5px',
            fontWeight: 500,
            lineHeight: '16px',
            color: '#fff',
          }}
        >
          {mainnetFeeLiteral}
        </Type.Label>
        <Type.Label
          style={{
            marginTop: '5px',
            fontWeight: 500,
            lineHeight: '16px',
            color: '#fff',
          }}
        >
          {multiFeeLiteral}
        </Type.Label>
      </Column>
    </Box>
  )
}

const TokenInfos = () => {
  const { lonAddress } = useChain()
  const nativeSymbol = useNativeCurrencySymbol()
  const nativeCurrencyLogo = useNativeCurrencyLogo()
  const { chainId } = useWeb3React()

  return (
    <Column>
      <TokenInfo
        icon={nativeCurrencyLogo}
        symbol={nativeSymbol}
        addr={AddressZero}
      />
      {isMainnetOrSepolia(chainId) ? (
        <TokenInfo icon={IconLON} symbol="LON" addr={lonAddress} />
      ) : null}
    </Column>
  )
}

const StyledControls = styled.div`
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 0px inset;
  margin: -20px;
  margin-top: 20px;
  padding: 5px 20px;
`

enum ControlsMenu {
  TX,
  ALLOWANCE,
  DISCONNECT,
}

const ControlsTrackId = {
  [ControlsMenu.TX]: 'webtl_mywallet_record',
  [ControlsMenu.ALLOWANCE]: 'webtl_mywallet_approve',
  [ControlsMenu.DISCONNECT]: 'webtl_mywallet_disconnect',
}

const Controls = () => {
  const { t } = useTranslation()
  const { chainId, account, connector } = useWeb3React()
  const allowanceManagingUrl = `https://app.scamsniffer.io/permits/${account}`
  const dispatch = useAppDispatch()
  const { trackClick } = useGA()
  const toggleInfoModal = useModalToggle(ApplicationModal.WALLET_INFO, {
    stack: true,
  })
  const toggleAllowanceListModal = useModalToggle(
    ApplicationModal.ALLOWANCE_LIST,
    {
      stack: true,
    },
  )

  const gotoTxRecords = () => {
    window.open(getExplorerAddressLink(account, chainId), '_blank')
  }

  const onDisconnect = () => {
    if (connector?.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
    dispatch(updateSelectedConnectionType(null))
    dispatch(updateWalletType(null))
    toggleInfoModal()
  }

  const onMenuClick = (menu: ControlsMenu) => {
    trackClick(ControlsTrackId[menu])
    switch (menu) {
      case ControlsMenu.TX:
        gotoTxRecords()
        break
      case ControlsMenu.ALLOWANCE:
        toggleAllowanceListModal()
        break
      case ControlsMenu.DISCONNECT:
        onDisconnect()
        break
    }
  }

  return (
    <>
      <StyledControls>
        <RowBetween>
          <MenuItem
            icon={IconMenuRecords}
            title={t('tokenlon_records')}
            onClick={() => onMenuClick(ControlsMenu.TX)}
          />
          <a href={allowanceManagingUrl}>
            <MenuItem
              icon={IconMenuSettings}
              title={t('allowance_manage')}
              onClick={() => {}}
            />
          </a>
          <MenuItem
            icon={IconMenuDisconnections}
            title={t('disconnect')}
            onClick={() => onMenuClick(ControlsMenu.DISCONNECT)}
          />
        </RowBetween>
      </StyledControls>
    </>
  )
}

export const InfoModal = () => {
  const { t } = useTranslation()
  const isOpen = useModalOpened(ApplicationModal.WALLET_INFO)
  const toggleModal = useModalToggle(ApplicationModal.WALLET_INFO, {
    stack: true,
  })

  return (
    <Modal isOpen={isOpen} title={t('my_wallet')} onDismiss={toggleModal}>
      <AddressInfo />
      <TokenInfos />
      <Controls />
    </Modal>
  )
}

export default InfoModal

import { AddressZero } from '@ethersproject/constants'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { getAddress } from 'ethers/lib/utils'
import { get } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ENV } from '~/constants/env'
import { marketRpc } from '~/services/rpc/tokenlon'
import { useBlockNumber } from '~/state/application/hooks'
import { updateTokensPrice, updateTokensPriceList } from '~/state/dex/actions'
import { useAppDispatch, useAppSelector } from '~/state/hooks'
import { Currency, FixedPointNumber } from '~/utils/fixedPoint'
import { NETWORK_POLLING_INTERVALS } from '~/utils/getLibrary'
import useUpdaterByBlock from './useUpdaterByBlock'
import { useUpdaterChainId } from './web3'

export const useTokensPriceStandalone = (
  tokenAddresses: string[],
  currency: 'USD' | 'CNY' = 'USD',
  blocksPerFetch: number = 5,
) => {
  const [prices, setPrices] = useState<{
    [address: string]: string
  }>({})
  const { chainId } = useWeb3React()
  const blockNumber = useBlockNumber()
  const blocksPerFetchRef = useRef(0)

  useEffect(() => {
    if (blocksPerFetch === 0) return
    if (!blocksPerFetchRef.current) {
      blocksPerFetchRef.current = blockNumber

      return
    }
    if (blockNumber - blocksPerFetchRef.current > blocksPerFetch) {
      blocksPerFetchRef.current = blockNumber
    }
  }, [blockNumber])

  useEffect(() => {
    marketRpc
      .getMarketPrice(tokenAddresses, chainId, currency)
      .then((res) => {
        const p = {}
        res.map((t) => {
          p[t.address.toLowerCase()] = t?.price
          if (!t?.price) {
            p[t.address.toLowerCase()] = prices[t.address.toLowerCase()] || 0
          }
          if (ENV.development || ENV.local) {
            p[t.address.toLowerCase()] = (t.address % 100) + 1
          }
        })
        setPrices(p)
      })
      .catch((err) => {
        console.error(err)
        setPrices({})
      })
  }, [chainId, JSON.stringify(tokenAddresses), blocksPerFetchRef.current])

  return prices
}

export const useTokenPriceStandalone = (
  tokenAddress: string,
  currency: 'USD' | 'CNY' = 'USD',
  blocksPerFetch: number = 10,
) => {
  const prices = useTokensPriceStandalone(
    [tokenAddress],
    currency,
    blocksPerFetch,
  )
  return prices[tokenAddress?.toLowerCase()] || 0
}

export const useTokenUSDPriceWithAmount = (
  tokenAddress: string,
  amount: string | number = '0',
  currency: Currency | false = Currency.USD,
  fixedAt: number = 2,
  chainId?: number,
) => {
  const price = useTokenPriceWithUseAPI({
    tokenAddress,
    currency: 'USD',
    chainId,
  })

  if (currency) {
    return new FixedPointNumber(new BigNumber(amount).times(price)).toCurrency(
      currency,
      fixedAt,
    )
  }

  return new FixedPointNumber(new BigNumber(amount).times(price))
}

export const useTokenPriceCallback = () => {
  const expectChainId = useUpdaterChainId()
  const tokens = useAppSelector((state) => state.dex.prices)
  const updateTokens = useMemo(() => tokens[expectChainId], [
    expectChainId,
    tokens,
  ])
  const dispatch = useAppDispatch()
  const tokenAddresses = useMemo(() => Object.keys(updateTokens || {}), [
    updateTokens,
  ])

  return useCallback(
    (blockNumber: number, currency: 'CNY' | 'USD' = 'USD') => {
      if (!tokenAddresses) return

      marketRpc
        .getMarketPrice(tokenAddresses, expectChainId, currency)
        .then((res: MarketPrice[]) =>
          dispatch(
            updateTokensPrice({
              chainId: expectChainId,
              marketPrices: res,
              blockNumber,
              currency,
            }),
          ),
        )
    },
    [tokenAddresses.toString()],
  )
}

export const TokenPriceUpdater = () => {
  const updaterCallback = useTokenPriceCallback()
  const expectChainId = useUpdaterChainId()
  const blocksPerFetch = useMemo(() => {
    if (NETWORK_POLLING_INTERVALS[expectChainId]) {
      return (10 * NETWORK_POLLING_INTERVALS[expectChainId]) / 1000
    }
    return 10
  }, [expectChainId])

  useUpdaterByBlock(updaterCallback, blocksPerFetch)

  return null
}

export const useTokensPrice = (
  addresses: string[],
  currency: 'USD' | 'CNY' = 'USD',
  chainId?: number,
) => {
  const dispatch = useAppDispatch()
  const expectChainId = useUpdaterChainId()
  const targetChainId = chainId ?? expectChainId
  const prices = useAppSelector((state) => state.dex.prices)

  useEffect(() => {
    dispatch(
      updateTokensPriceList({
        addresses,
        currency,
        chainId: targetChainId,
      }),
    )
  }, [targetChainId, JSON.stringify(addresses)])

  return useMemo(() => {
    if (!prices || !prices[targetChainId]) return {}
    const p = {} as Record<string, string>

    addresses.forEach((addr) => {
      const address = getAddress(addr)
      const price = get(prices[targetChainId][address], `${currency}.price`, 0)
      p[address] = price
      if ((ENV.local || ENV.development) && address === AddressZero) {
        p[address] = '1250'
      }
    })
    return p
  }, [
    JSON.stringify(prices?.[targetChainId]),
    JSON.stringify(addresses),
    currency,
  ])
}

export const useTokenPrice = (
  tokenAddress: string,
  currency: 'USD' | 'CNY' = 'USD',
  chainId?: number,
) => {
  const prices = useTokensPrice(
    [tokenAddress].filter(Boolean),
    currency,
    chainId,
  )

  if (!tokenAddress) return 0

  return prices[getAddress(tokenAddress)] || 0
}

interface UseTokenPriceDirectlyByAPIProps {
  tokenAddress: string
  currency?: 'USD' | 'CNY'
  chainId?: number
}

export const useTokenPriceWithUseAPI = ({
  tokenAddress,
  currency = 'USD',
  chainId,
}: UseTokenPriceDirectlyByAPIProps) => {
  const updaterChainId = useUpdaterChainId()
  const dispatch = useAppDispatch()
  const prices = useAppSelector((state) => state.dex.prices)

  const targetChainId = chainId ?? updaterChainId
  const isUpdaterChain = targetChainId === updaterChainId

  const [price, setPrice] = useState(0)

  useEffect(() => {
    if (!tokenAddress) {
      setPrice(0)
      return
    }

    const address = getAddress(tokenAddress)

    dispatch(
      updateTokensPriceList({
        chainId: targetChainId,
        addresses: [address],
        currency,
      }),
    )

    if ((ENV.development || ENV.local) && address === AddressZero) {
      setPrice(1250)
      return
    }

    // 如果 token 在 updater 网络上，直接读取缓存，TokenPriceUpdater 会定期更新缓存。否则直接调用 API 获取价格
    if (isUpdaterChain) {
      const cachePrice = get(
        prices?.[targetChainId]?.[address],
        `${currency}.price`,
        0,
      )
      setPrice(cachePrice)
      return
    }

    let isMounted = true

    marketRpc
      .getMarketPrice([address], targetChainId, currency)
      .then((res: MarketPrice[]) => {
        if (isMounted) {
          const fetchedPrice = res[0]?.price || 0
          setPrice(fetchedPrice)
        }
      })
      .catch(() => {
        if (isMounted) {
          setPrice(0)
        }
      })

    return () => {
      isMounted = false
    }
  }, [tokenAddress, currency, targetChainId, isUpdaterChain, prices])

  return price
}

import { useEffect } from 'react'
import { useUpdaterChainId, useLimitOrderRouter } from '~/hooks'
import { useAppDispatch } from '../hooks'
import {
  updateConfig,
  updateV6Config,
  updateLimitSwapPair,
  updateSpotSwapPair,
  updateIsDisableTrade,
} from './actions'
import exchangeRpc from '~/services/rpc/tokenlon'
import {
  useLimitSwapPair,
  useLimitSwapTokens,
  useLimitSwapTokensUpdater,
  useSpotSwapPair,
  useSpotSwapTokens,
  useSpotSwapTokensUpdater,
  useTokenlonV6ConfigUpdater,
  useSpotSwapRecommendTokensUpdater,
  useTokenlonV6NetworkConfig,
  useTokenlonV6NetworkConfigs,
} from './hooks'
import { WETH, ETH, USDT } from '~/constants/addresses'
import { compareAddress } from '~/utils/token'
import { getDefaultToken } from '~/components/Exchange/components/TokenListModal/helpers'
import { getIsDisableTradeByIP } from '~/apis'

const findTokenByAddress = (address: string, tokens) => {
  const lowercaseAddress = address.toLowerCase()
  for (const key in tokens) {
    if (key.toLowerCase() === lowercaseAddress) {
      return tokens[key]
    }
  }
  return undefined
}

export const SpotSwapPairResetUpdater = () => {
  const dispatch = useAppDispatch()
  const chainId = useUpdaterChainId()
  const [inputToken, outputToken] = useSpotSwapPair()
  const tokens = useSpotSwapTokens(chainId)
  const outputTokens = useSpotSwapTokens(outputToken?.chainId)
  const { defaultPair: networkDefaultPair } = useTokenlonV6NetworkConfig()
  const networkConfigs = useTokenlonV6NetworkConfigs()
  const defaultPair = networkDefaultPair ?? [
    { address: ETH[chainId] },
    { address: USDT[chainId] },
  ]

  useEffect(() => {
    if (!tokens || !Object.keys(tokens).length) return

    const isNoInput =
      !inputToken ||
      inputToken.chainId !== chainId ||
      !tokens[inputToken.address]
    const isNoOutput = !outputToken || !outputTokens[outputToken.address]

    if (isNoInput || isNoOutput) {
      let defaultInputToken: SpotSwapToken
      let defaultOutputToken: SpotSwapToken

      if (isNoInput && isNoOutput) {
        defaultInputToken = findTokenByAddress(defaultPair[0].address, tokens)
        defaultOutputToken = findTokenByAddress(defaultPair[1].address, tokens)
      } else if (isNoInput) {
        defaultInputToken = findTokenByAddress(defaultPair[0].address, tokens)
        defaultOutputToken = findTokenByAddress(defaultPair[1].address, tokens)
      } else {
        defaultInputToken = inputToken
        defaultOutputToken = getDefaultToken({
          selectedChainId: chainId,
          baseToken: inputToken,
          networkConfigs,
          tokens: Object.values(tokens),
        })
      }

      dispatch(
        updateSpotSwapPair({
          inputToken: defaultInputToken,
          outputToken: defaultOutputToken,
        }),
      )
    }
  }, [
    chainId,
    tokens,
    inputToken?.chainId,
    outputToken?.chainId,
    inputToken?.address,
    outputToken?.address,
  ])

  return null
}

export const LimitSwapPairResetUpdater = () => {
  const dispatch = useAppDispatch()
  const chainId = useUpdaterChainId()
  const [inputToken, outputToken] = useLimitSwapPair()
  const tokens = useLimitSwapTokens(chainId)
  const defaultPair = [{ address: WETH[chainId] }, { address: USDT[chainId] }]

  useEffect(() => {
    if (!tokens || !Object.keys(tokens).length) return

    const isNoInput =
      !inputToken ||
      inputToken.chainId !== chainId ||
      !tokens[inputToken.address]
    const isNoOutput =
      !outputToken ||
      outputToken.chainId !== chainId ||
      !tokens[outputToken.address]

    if (isNoInput || isNoOutput) {
      let defaultInputToken: LimitSwapToken
      let defaultOutputToken: LimitSwapToken

      if (isNoInput && isNoOutput) {
        defaultInputToken = findTokenByAddress(defaultPair[0].address, tokens)
        defaultOutputToken = findTokenByAddress(defaultPair[1].address, tokens)
      } else if (isNoInput) {
        const targetAddress = compareAddress(
          defaultPair[0].address,
          outputToken.address,
        )
          ? defaultPair[1].address
          : defaultPair[0].address
        defaultInputToken = findTokenByAddress(targetAddress, tokens)
        defaultOutputToken = outputToken
      } else {
        const targetAddress = compareAddress(
          defaultPair[0].address,
          inputToken.address,
        )
          ? defaultPair[1].address
          : defaultPair[0].address
        defaultInputToken = inputToken
        defaultOutputToken = findTokenByAddress(targetAddress, tokens)
      }

      dispatch(
        updateLimitSwapPair({
          inputToken: defaultInputToken,
          outputToken: defaultOutputToken,
        }),
      )
    }
  }, [
    chainId,
    tokens,
    inputToken?.chainId,
    outputToken?.chainId,
    inputToken?.address,
    outputToken?.address,
    JSON.stringify(defaultPair),
  ])

  return null
}

export const DexUpdater = () => {
  const chainId = useUpdaterChainId()
  const dispatch = useAppDispatch()
  const isLimitOrderRouter = useLimitOrderRouter()
  const updateSpotSwapTokens = useSpotSwapTokensUpdater()
  const updateLimitSwapTokens = useLimitSwapTokensUpdater()
  const updateTokenlonV6Config = useTokenlonV6ConfigUpdater()
  const updateSpotSwapRecommendTokens = useSpotSwapRecommendTokensUpdater()

  useEffect(() => {
    async function checkIP() {
      const isDisable = await getIsDisableTradeByIP()
      dispatch(updateIsDisableTrade(!!isDisable))
    }
    checkIP()
  }, [])

  useEffect(() => {
    if (!chainId) return
    if (isLimitOrderRouter) {
      updateLimitSwapTokens()
    } else {
      updateSpotSwapTokens()
      updateSpotSwapRecommendTokens()
    }

    exchangeRpc.getMobileAppConfig().then((config) => {
      dispatch(updateConfig({ chainId, config }))
    })

    updateTokenlonV6Config().then((config) => {
      dispatch(updateV6Config({ v6Config: config }))
    })
  }, [
    chainId,
    updateSpotSwapTokens,
    updateSpotSwapRecommendTokens,
    updateLimitSwapTokens,
    isLimitOrderRouter,
  ])

  return (
    <>
      <SpotSwapPairResetUpdater />
      <LimitSwapPairResetUpdater />
    </>
  )
}

export default DexUpdater

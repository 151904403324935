import { useTranslation } from 'react-i18next'
import Modal from '~/components/Modal'
import { NetworkConnectOptions } from '~/components/WalletModal/Components/NetworkConnectOptions'
import { useModalOpened, useModalToggle } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import { useWeb3React } from '@web3-react/core'
import { switchChain } from '~/utils/switchChain'
import { useAppDispatch } from '~/state/hooks'
import { updatePendingChainId } from '~/state/user/actions'

const SwitchNetworkModal = () => {
  const isOpen = useModalOpened(ApplicationModal.SWITCH_NETWORK)
  const toggle = useModalToggle(ApplicationModal.SWITCH_NETWORK)
  const { t } = useTranslation()
  const { account, chainId, connector } = useWeb3React()
  const dispatch = useAppDispatch()

  const onSelect = async (chainId) => {
    try {
      if (!!account) {
        await switchChain(connector, chainId)
      }
      dispatch(updatePendingChainId(chainId))
      toggle()
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={toggle}
      title={t('switch_network')}
      contentClassName="switchNetworkContent"
      headerClassName="switchNetworkHeader"
    >
      <NetworkConnectOptions chainId={chainId} onSelect={onSelect} />
    </Modal>
  )
}

export default SwitchNetworkModal

import { createAction } from '@reduxjs/toolkit'
import { TradeDirection } from '~/components/Exchange/types'
import {
  LimitOrderSubmitStatus,
  LimitOrderUserConfig,
  SpotSwapUserConfig,
  SpotSwapUserTradeStatus,
} from './type'
import { AppConfig } from '@tokenlon/v6-sdk'

export const addSpotTokens = createAction<{
  chainId: number
  tokens: SpotSwapToken[]
}>('dex/addSpotTokens')
export const addLimitTokens = createAction<{
  chainId: number
  tokens: LimitSwapToken[]
}>('dex/addLimitTokens')
export const addCustomSpotTokens = createAction<{
  chainId: number
  tokens: SpotSwapToken[]
}>('dex/addCustomSpotTokens')
export const addCustomLimitTokens = createAction<{
  chainId: number
  tokens: LimitSwapToken[]
}>('dex/addCustomLimitTokens')

export const updateConfig = createAction<{
  chainId: number
  config: TokenlonConfig
}>('dex/updateConfig')

export const updateV6Config = createAction<{
  v6Config: AppConfig
}>('dex/updateV6Config')

export const updateLimitSwapAmount = createAction<{
  direction: TradeDirection
  amount: string
}>('dex/updateLimitSwapAmount')

export const updateLimitSwapLimitPrice = createAction<string>(
  'dex/updateLimitSwapLimitPrice',
)

export const updateSpotSwapAmount = createAction<{
  direction: TradeDirection
  amount: string
}>('dex/updateSpotSwapAmount')

export const updateSpotSwapPairAmount = createAction<{
  inputAmount: string
  outputAmount: string
}>('dex/updateSpotSwapPairAmount')
export const updateLimitSwapPairAmount = createAction<{
  inputAmount: string
  outputAmount: string
}>('dex/updateLimitSwapPairAmount')

export const updateSpotSwapPairToken = createAction<{
  direction: TradeDirection
  token: SpotSwapToken
}>('dex/updateSpotSwapPairToken')

export const updateLimitSwapPairToken = createAction<{
  direction: TradeDirection
  token: LimitSwapToken
}>('dex/updateLimitSwapPairToken')

export const updateSpotSwapPair = createAction<{
  inputToken: SpotSwapToken
  outputToken: SpotSwapToken
}>('dex/updateSpotSwapPair')

export const updateLimitSwapPair = createAction<{
  inputToken: LimitSwapToken
  outputToken: LimitSwapToken
}>('dex/updateLimitSwapPair')

export const updateLimitOrderSubmitStatus = createAction<
  LimitOrderSubmitStatus
>('dex/updateLimitOrderSubmitStatus')

export const updateLimitOrderUserConfig = createAction<LimitOrderUserConfig>(
  'dex/updateLimitOrderUserConfig',
)

export const updateSpotSwapUserConfig = createAction<SpotSwapUserConfig>(
  'dex/updateSpotSwapUserConfig',
)

export const updateTokensPrice = createAction<{
  chainId: number
  marketPrices: MarketPrice[]
  blockNumber: number
  currency: 'CNY' | 'USD'
}>('dex/updateTokensPrice')

export const updateTokensPriceList = createAction<{
  chainId: number
  addresses: string[]
  currency: 'USD' | 'CNY'
}>('dex/updateTokensPriceList')

export const removeTokensPriceList = createAction<{
  chainId: number
  addresses: string[]
  currency: 'USD' | 'CNY'
}>('dex/removeTokensPriceList')

export const updateSpotSwapUserTradeStatus = createAction<
  SpotSwapUserTradeStatus
>('dex/updateSpotSwapUserTradeStatus')

export const updateSpotSwapRecommendTokens = createAction<SpotSwapToken[]>(
  'dex/updateSpotSwapRecommendTokens',
)

export const updateIsDisableTrade = createAction<boolean>(
  'dex/updateIsDisableTrade',
)

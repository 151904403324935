import {
  Web3ReactHooks,
  Web3ReactProvider,
  useWeb3React,
} from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { useEffect, useMemo } from 'react'
import { DEFAULT_RPC_PROVIDERS } from '~/constants/chain'
import { useEagerlyConnect } from '~/hooks/useEagerlyConnect'
import { useOrderedConnections } from '~/hooks/useOrderedConnections'
import { isSupportedChain } from '~/utils/chain'

export const Web3Provider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useEagerlyConnect()

  const connections = useOrderedConnections()
  const connectors: [
    Connector,
    Web3ReactHooks,
  ][] = connections.map((connection) => [
    connection.connector,
    connection.hooks,
  ])
  const key = useMemo(
    () => connections.map((connection) => connection.name).join('-'),
    [connections],
  )

  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      <Updater />
      {children}
    </Web3ReactProvider>
  )
}

const Updater = () => {
  const { chainId, provider } = useWeb3React()

  const networkProvider = isSupportedChain(chainId)
    ? DEFAULT_RPC_PROVIDERS[chainId]
    : undefined

  useEffect(() => {
    provider?.on('debug', trace)
    if (provider !== networkProvider) {
      networkProvider?.on('debug', trace)
    }
    return () => {
      provider?.off('debug', trace)
      networkProvider?.off('debug', trace)
    }
  }, [networkProvider, provider])

  return null
}

const USE_TRACE = process.env.USE_TRACE

const trace = (event: any) => {
  if (!USE_TRACE) return
  if (!event.request || !event.response) return
  const { method, params, id } = event.request
  console.groupCollapsed(method, id)
  console.debug(params, event.response)
  console.groupEnd()
}

import { AddressZero } from '@ethersproject/constants'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { ContractReceipt, ethers } from 'ethers'
import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { AnyAction } from 'redux'
import {
  useWETH,
  useWETHDepositCallback,
  useWETHWithdrawCallback,
} from '~/hooks'
import { useTokenPrice } from '~/hooks/useTokenPrice'
import useUpdaterByBlock from '~/hooks/useUpdaterByBlock'
import {
  useFeeData,
  useModalOpened,
  useModalSwitch,
} from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import { useNativeTokenBalance, useTokenBalance } from '~/state/wallet/hooks'
import { gasSpeedup } from '~/utils/gasSpeedup'
import { getBlocksPerFetchForChainId } from '~/utils/getBlocksPerFetchForChainId'
import { message } from '../Message'
import iconETH from '~/assets/images/icon-eth.png'
import iconWETH from '~/assets/images/icon-weth.png'

export enum WETHWrapDirection {
  Wrap = 'ETH => WETH',
  Unwrap = 'WETH => ETH',
}
export interface WETHWrapState {
  direction: WETHWrapDirection
  value: string
  receipt?: ContractReceipt
}

const initialState: WETHWrapState = {
  direction: WETHWrapDirection.Wrap,
  value: '0',
  receipt: null,
}

export const toggleDirection = createAction<WETHWrapDirection>(
  'weth/toggleDirection',
)
export const updateValue = createAction<string>('weth/updateValue')
export const updateReceipt = createAction<ContractReceipt>('weth/updateReceipt')

const wethReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(toggleDirection, (state) => {
      state.direction =
        state.direction === WETHWrapDirection.Unwrap
          ? WETHWrapDirection.Wrap
          : WETHWrapDirection.Unwrap
    })
    .addCase(updateValue, (state, { payload }) => {
      state.value = payload
    })
    .addCase(updateReceipt, (state, { payload }) => {
      state.receipt = payload
    }),
)

export const WETHContext = createContext<{
  state: WETHWrapState
  dispatch: Dispatch<AnyAction>
}>(undefined)

export const WETHProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(wethReducer, initialState)

  useNetworkChangeNotice()
  useWETHVisible()
  return (
    <WETHContext.Provider value={{ state, dispatch }}>
      {children}
    </WETHContext.Provider>
  )
}

// TODO: 把内部逻辑移到 Provider 里，避免多次执行
export const useWETHContext = () => {
  const context = useContext(WETHContext)
  if (context === undefined) {
    throw new Error('useWETHContext must be called in WETHProvider')
  }
  const WETHContract = useWETH()
  const { state, dispatch } = context
  const { direction, value } = state
  const isWrap = direction === WETHWrapDirection.Wrap
  const WETH: BaseTokenlonToken = {
    address: WETHContract?.address,
    decimals: 18,
    symbol: 'WETH',
    name: 'WETH',
    logo: iconWETH.src,
  }
  const ETH: BaseTokenlonToken = {
    address: AddressZero,
    decimals: 18,
    symbol: 'ETH',
    name: 'ETH',
    logo: iconETH.src,
  }
  const inputToken = isWrap ? ETH : WETH
  const outputToken = isWrap ? WETH : ETH
  const ethPrice = useTokenPrice(AddressZero)
  const { chainId, account } = useWeb3React()
  const blocksPerFetch = useMemo(() => getBlocksPerFetchForChainId(chainId), [
    chainId,
  ])

  const { estimateGas: depositGasEstimate } = useWETHDepositCallback()
  const { estimateGas: withdrawGasEstimate } = useWETHWithdrawCallback()
  const [gas, setGas] = useState(ethers.BigNumber.from(0))
  const estimateGas = useCallback(async () => {
    if (!value) return setGas(ethers.BigNumber.from(0))

    const v = ethers.utils.parseEther(value)
    try {
      const gasLimit = isWrap
        ? await depositGasEstimate(v)
        : await withdrawGasEstimate(v)
      setGas(gasSpeedup(gasLimit))
    } catch (error) {
      console.error('estimate error', error)
    }
  }, [value, depositGasEstimate, withdrawGasEstimate])
  useUpdaterByBlock(estimateGas, blocksPerFetch)
  const feeData = useFeeData()

  const gasInfo = useMemo(() => {
    const price = ethers.BigNumber.from(feeData?.gasPrice || 0)
    const ether = new BigNumber(ethers.utils.formatEther(price.mul(gas)))
    return {
      ether: ether.sd(4).toFormat(),
      useValue: ether.times(ethPrice).sd(4).toFormat(1),
    }
  }, [feeData?.gasPrice, ethPrice, gas])

  const inputTokenBalance = useTokenBalance(account, inputToken)
  const ethBalance = useNativeTokenBalance()
  const balance = isWrap ? ethBalance : inputTokenBalance

  return {
    state: {
      ...state,
      inputToken,
      outputToken,
      isWrap,
      gasInfo,
      balance,
    },
    dispatch,
  }
}

const useNetworkChangeNotice = () => {
  const { chainId } = useWeb3React()
  const isOpen = useModalOpened(ApplicationModal.WETH_PREVIEW)
  const chainIdRef = useRef<number>()
  const { t } = useTranslation()
  useEffect(() => {
    if (!chainIdRef.current) chainIdRef.current = chainId
    if (chainIdRef.current !== chainId) {
      chainIdRef.current = chainId
      isOpen && message.error(t('network_changed'))
    }
  }, [chainIdRef.current, chainId, isOpen])
}

const useWETHVisible = () => {
  const { pathname } = useRouter()
  const switchWrap = useModalSwitch(ApplicationModal.WETH_WRAP)
  const switchResult = useModalSwitch(ApplicationModal.WETH_RESULT)
  const switchReview = useModalSwitch(ApplicationModal.WETH_PREVIEW)

  useEffect(() => {
    if (pathname === '/weth') {
      switchWrap(true)
    } else {
      switchWrap(false)
      switchReview(false)
      switchResult(false)
    }
  }, [pathname])
}

import { useCallback, useMemo } from 'react'
import { TransactionResponse } from '@ethersproject/providers'
import { AppState } from '..'
import { useAppDispatch, useAppSelector } from '../hooks'
import { addTransaction, TransactionInfo, TransactionType } from './actions'
import { TransactionDetail } from './reducer'
import { useWeb3React } from '@web3-react/core'

export const useAllTransactions = (): {
  [txHash: string]: TransactionDetail
} => {
  const { chainId } = useWeb3React()

  return useAppSelector((state: AppState) => state.transactions[chainId] || {})
}

export const useTransaction = (txHash?: string): TransactionDetail => {
  const allTransactions = useAllTransactions()

  if (!txHash) return undefined

  return allTransactions[txHash]
}

export const useTransactionIsPending = (txHash?: string): boolean => {
  const allTransactions = useAllTransactions()

  if (!txHash || !allTransactions[txHash]) return undefined

  return !allTransactions[txHash].receipt
}

export const useTransactionIsConfirmed = (txHash?: string): boolean => {
  const allTransactions = useAllTransactions()

  if (!txHash || !allTransactions[txHash]) return undefined

  return Boolean(allTransactions[txHash].receipt)
}

export const useHasPendingApproval = (
  tokenAddress: string,
  spender?: string,
): boolean => {
  const allTransactions = useAllTransactions()

  return useMemo(() => {
    return (
      typeof tokenAddress === 'string' &&
      typeof spender === 'string' &&
      Object.values(allTransactions).some((tx) => {
        if (tx.receipt) return false
        if (tx.info.type !== TransactionType.APPROVAL) return false

        return (
          tx.info.tokenAddress === tokenAddress && tx.info.spender === spender
        )
      })
    )
  }, [allTransactions, tokenAddress, spender])
}

export const useTransactionAdder = () => {
  const { chainId, account } = useWeb3React()
  const dispatch = useAppDispatch()

  return useCallback(
    (response: TransactionResponse, info: TransactionInfo) => {
      if (!account || !chainId) return

      const { hash } = response
      if (!hash) throw new Error('No transaction hash found.')

      dispatch(addTransaction({ hash, from: account, info, chainId }))
    },
    [chainId, account, dispatch],
  )
}

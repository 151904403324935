import {
  ArbitrumOne,
  ArbitrumRinkeby,
  Goerli,
  Mainnet,
  Sepolia,
  Base,
  BSC,
  Optimism,
  Polygon,
  zkSync,
} from '~/model/chain/ethereum'
import { AddressZero } from '@ethersproject/constants'

type AddressMap = { [chainId: number]: string }

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [Mainnet.chainId]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [Sepolia.chainId]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const WETH = {
  [Mainnet.chainId]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [Goerli.chainId]: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
  [Sepolia.chainId]: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
  [ArbitrumOne.chainId]: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  [ArbitrumRinkeby.chainId]: '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9',
  [Base.chainId]: '0x4200000000000000000000000000000000000006',
  [Optimism.chainId]: '0x4200000000000000000000000000000000000006',
  [Polygon.chainId]: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  [zkSync.chainId]: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
}

export const USDC = {
  [Mainnet.chainId]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  [Goerli.chainId]: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
  [Sepolia.chainId]: '0xa07DD8cb5A5c4254B5da0456AFD597A60a92b633',
  [ArbitrumOne.chainId]: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  [ArbitrumRinkeby.chainId]: '0x8dA45cf09A24B8fc94C522a2e7e09EE3426B3212',
}

export const ARB = {
  [ArbitrumOne.chainId]: '0x912CE59144191C1204E64559FE8253a0e49E6548',
  [Mainnet.chainId]: '0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1',
}

export const ETH = {
  [Mainnet.chainId]: AddressZero,
  [Goerli.chainId]: AddressZero,
  [Sepolia.chainId]: AddressZero,
  [ArbitrumOne.chainId]: AddressZero,
  [Base.chainId]: AddressZero,
  [BSC.chainId]: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  [Polygon.chainId]: AddressZero,
  [Optimism.chainId]: AddressZero,
  [zkSync.chainId]: AddressZero,
}

export const USDT = {
  [Mainnet.chainId]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  [Goerli.chainId]: '0xa93ef9215b907c19e739e2214e1aa5412a0401b5',
  [Sepolia.chainId]: '0x63b26b83c6f38433B2b6a7214fC2c569a4F4069A',
  [ArbitrumOne.chainId]: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
}

export const LON = {
  [Mainnet.chainId]: '0x0000000000095413afC295d19EDeb1Ad7B71c952',
  [Goerli.chainId]: '0x6dA0e6ABd44175f50C563cd8b860DD988A7C3433',
  [Sepolia.chainId]: '0x6C1851b852F05bdc7c0BE1A088532E4999fD94Fa',
  [ArbitrumOne.chainId]: '0x55678cd083fcDC2947a0Df635c93C838C89454A3',
  [ArbitrumRinkeby.chainId]: '0x3828E007B7413Be83550AB520f706cC1773464DB',
}

// https://docs.across.to/reference/contract-addresses
export const spokePoolAddress: Record<number, string> = {
  [Mainnet.chainId]: '0x5c7BCd6E7De5423a257D81B442095A1a6ced35C5',
  [ArbitrumOne.chainId]: '0xe35e9842fceaCA96570B734083f4a58e8F7C5f2A',
  [Base.chainId]: '0x09aea4b2242abC8bb4BB78D537A67a245A7bEC64',
  [Optimism.chainId]: '0x6f26Bf09B1C792e3228e5467807a900A503c0281',
  [Polygon.chainId]: '0x9295ee1d8C5b022Be115A2AD3c30C72E34e7F096',
  [zkSync.chainId]: '0xE0B015E54d54fc84a6cB9B666099c46adE9335FF',
}

import {
  WalletConnect,
  WalletConnectConstructorArgs,
} from '@web3-react/walletconnect'
import { ALL_SUPPORTED_RPC_URLS } from '~/constants/chain'

export class WalletConnectV1 extends WalletConnect {
  constructor({
    actions,
    onError,
    qrcode = true,
  }: Omit<WalletConnectConstructorArgs, 'options'> & {
    qrcode?: boolean
  }) {
    super({
      actions,
      options: { qrcode, rpc: ALL_SUPPORTED_RPC_URLS },
      onError,
    })
  }

  activate(chainId?: number) {
    return super.activate(chainId)
  }
}

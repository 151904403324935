import { captureException } from '@sentry/nextjs'
import { ErrorCode } from '@ethersproject/logger'
import i18n from '~/i18n'
import { message } from '~/components/Message'
import { isClient } from './userAgent'

export class TxError extends Error {
  constructor(public code: ErrorCode, public message: string = '') {
    super(message)
  }
}

export const handleTxError = (error: Error | unknown) => {
  const t = i18n.t
  if (error instanceof Error) {
    const e: any = error
    const errorCode = e.code

    switch (errorCode) {
      case ErrorCode.UNKNOWN_ERROR:
      case ErrorCode.NOT_IMPLEMENTED:
      case ErrorCode.UNSUPPORTED_OPERATION:
      case ErrorCode.NETWORK_ERROR:
      case ErrorCode.SERVER_ERROR:
      case ErrorCode.TIMEOUT:
      case ErrorCode.BUFFER_OVERRUN:
      case ErrorCode.NUMERIC_FAULT:
      case ErrorCode.MISSING_NEW:
      case ErrorCode.INVALID_ARGUMENT:
      case ErrorCode.MISSING_ARGUMENT:
      case ErrorCode.UNEXPECTED_ARGUMENT:
      case ErrorCode.CALL_EXCEPTION:
      case ErrorCode.INSUFFICIENT_FUNDS:
      case ErrorCode.NONCE_EXPIRED:
      case ErrorCode.REPLACEMENT_UNDERPRICED:
      case ErrorCode.UNPREDICTABLE_GAS_LIMIT:
      case ErrorCode.TRANSACTION_REPLACED:
        captureException('Tx Error', {
          extra: {
            errorCode,
            error: e,
          },
        })
        message.error(t(`provider_error_code_${errorCode}`))
        break
      default:
        if (!errorCode && !!e.details) {
          // handle v6 sdk error
          // error: user_balance_insufficient -> i18n: USER_BALANCE_INSUFFICIENT
          captureException('Tx Error', {
            extra: {
              error: e,
            },
          })
          message.error(
            t(e.details.replace(/^["'](.*)["']$/, '$1').toUpperCase()),
          )
          return
        } else if (!errorCode && !!e.message) {
          captureException('Tx Error', {
            extra: {
              error: e,
            },
          })
          message.error(t(e.message))
          return
        }
        message.error(t(`provider_error_code_${ErrorCode.UNKNOWN_ERROR}`))
        console.error(`unexpected error code: ${errorCode}`, e)
        return
    }
  } else if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error
  ) {
    // catch WalletConnect imToken matchEthSendMsg error which won't be caught by error instanceof Error
    // https://github.com/consenlabs/token-v2/blob/85cd5eea1a9e7b72ce738fb806f4ad9c7e402d8f/src/global/TokenPay/helper.ts#L165-L172
    const e: any = error
    captureException('Tx Error', {
      extra: {
        error: e.message,
      },
    })
    message.error(e.message)
  }

  if (typeof error === 'string') {
    captureException('Tx Error', {
      extra: {
        error,
      },
    })
    message.error(error)
  }
}

export const globalTxErrorHandler = () => {
  if (isClient) {
    window.addEventListener('error', (event: ErrorEvent) => {
      if (event.error instanceof Error) {
        handleTxError(event.error)
      }
    })
  }
}

import { createReducer } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ConnectionType } from '~/connection/type'
import { WalletType } from '~/constants/walletType'
import {
  PoolSharesDetail,
  updatePendingChainId,
  updatePoolShares,
  updateSelectedConnectionType,
  updateUnstoppableDomainCallbackUri,
  updateWalletType,
} from './actions'
import { ENV } from '~/constants/env'

export interface UserState {
  poolShares: {
    [chainId: string]: PoolSharesDetail
  }
  connectWallet: WalletType | null
  pendingChainId: number | null
  selectedConnectionType: ConnectionType | null
  unstoppableDomainCallbackUri: string | null
}

export const initialState: UserState = {
  poolShares: {},
  connectWallet: null,
  unstoppableDomainCallbackUri: null,
  selectedConnectionType: null,
  pendingChainId: ENV.development || ENV.local ? 11155111 : 1,
}

export const userReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      updatePoolShares,
      (state, { payload: { chainId, poolShares } }) => {
        if (!state.poolShares) state.poolShares = {}
        state.poolShares[chainId] = poolShares
      },
    )
    .addCase(updateWalletType, (state, { payload }) => {
      state.connectWallet = payload
    })
    .addCase(updatePendingChainId, (state, { payload }) => {
      state.pendingChainId = payload
    })
    .addCase(updateSelectedConnectionType, (state, { payload }) => {
      state.selectedConnectionType = payload
    })
    .addCase(updateUnstoppableDomainCallbackUri, (state, { payload }) => {
      state.unstoppableDomainCallbackUri = payload
    }),
)

export const userPersistConfig: PersistConfig<UserState> = {
  key: 'user',
  storage: storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: [],
}

export default persistReducer(userPersistConfig, userReducer)

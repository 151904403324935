import { useMemo } from 'react'
import MD5 from 'md5.js'
import { useWeb3React } from '@web3-react/core'

// Google Analytics callback hook
// when to useGA hook: the submit info contain chainInfo(address/chainId)
export const useGA = () => {
  const { chainId, account } = useWeb3React()

  const ga = useMemo(() => {
    const createGAFn = (type: 'click' | 'view' | 'status') => (
      eventName: string,
      eventParams: Record<string, any> = {},
    ) => {
      window.gtag('event', eventName, {
        event_category: type,
        actionType: type,
        caip2: chainId ? `eip155:${chainId}` : undefined,
        wallet_address: new MD5().update(`${account}`).digest('hex'),
        ...eventParams,
      })
    }
    const trackClick = createGAFn('click')
    const trackElClick: React.MouseEventHandler<HTMLOrSVGElement> = (event) => {
      const { currentTarget } = event
      if (
        currentTarget instanceof HTMLElement ||
        currentTarget instanceof SVGElement
      ) {
        const { dataset } = currentTarget
        if (dataset && dataset.trackid) {
          let params = {}
          try {
            params = JSON.parse(dataset.trackdata) || {}
          } catch (e) {}
          ga.trackClick(dataset.trackid, params)
        }
      }
    }

    const trackElClickCurring = (
      event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    ) => {
      trackElClick(event)
      return (fn: React.MouseEventHandler<HTMLOrSVGElement>) => fn(event)
    }

    return {
      trackClick,
      trackElClick,
      trackElClickCurring,
      trackView: createGAFn('view'),
      trackStatus: createGAFn('status'),
    }
  }, [account, chainId])

  return ga
}

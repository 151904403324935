import { useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import Modal from '~/components/Modal'
import { useWeb3React } from '@web3-react/core'
import { switchChain } from '~/utils/switchChain'
import { useCurrentChainId } from '~/hooks'
import { useAppDispatch } from '~/state/hooks'
import { updatePendingChainId } from '~/state/user/actions'
import { Mainnet, Sepolia } from '~/model/chain/ethereum'
import { ENV } from '~/constants/env'
import styled from 'styled-components'
import IconWarning from '~/assets/images/warning-black.svg'

const TARGET_PATHNAME_LIST = [
  '/lon/dashboard',
  '/lon/mining/trade',
  '/lon/staking',
]

const SwitchNetworkWarningModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()
  const { account, connector } = useWeb3React()
  const currentChainId = useCurrentChainId()
  const dispatch = useAppDispatch()
  const { pathname } = useRouter()

  const validChainId = useMemo(() => {
    const isDev = ENV.development || ENV.local
    return isDev ? Sepolia.chainId : Mainnet.chainId
  }, [])

  const handleSwitchNetwork = async () => {
    try {
      if (!!account) {
        await switchChain(connector, validChainId)
      }
      dispatch(updatePendingChainId(validChainId))
      setIsVisible(false)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    const isConnected = !!account
    const isInvalidChain = currentChainId !== validChainId
    const isTargetPath = TARGET_PATHNAME_LIST.some((p) =>
      pathname.startsWith(p),
    )
    if (isConnected && isInvalidChain && isTargetPath) {
      setIsVisible(true)
    }
  }, [currentChainId, pathname])

  return (
    <Modal
      title={t('switch_network_warning_modal.title')}
      left={() => null}
      isOpen={isVisible}
      onDismiss={() => null}
      animate={false}
      contentStyle={{ maxWidth: '500px' }}
    >
      <StyledContainer>
        <StyledContent>
          <StyledIcon src={IconWarning} alt="warning" />
          <StyledTitle>
            {t('switch_network_warning_modal.content_title')}
          </StyledTitle>
          <p>{t('switch_network_warning_modal.content_desc')}</p>
          <StyledBtn onClick={handleSwitchNetwork}>
            {t('switch_network_warning_modal.btn')}
          </StyledBtn>
        </StyledContent>
      </StyledContainer>
    </Modal>
  )
}

export default SwitchNetworkWarningModal

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px 16px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border-radius: 20px;
  background-color: #f7f7f9;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #81808d;
  }
`

const StyledTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #3c3c44;
`

const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`

const StyledBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 12px 0;
  border-radius: 48px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.btnPrimary};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
`

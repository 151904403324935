import React from 'react'
import { StyledContainer, ImageLogo, TextLogo, NetworkBadge } from './styled'
import { TokenLogoProps } from './types'

const TokenLogo: React.FC<TokenLogoProps> = ({
  logo,
  symbol,
  networkLogo,
  showNetworkBadge,
  ...logoProps
}) => {
  return (
    <StyledContainer>
      {logo ? (
        <ImageLogo {...logoProps} src={logo} />
      ) : (
        <TextLogo {...logoProps}>{(symbol || '').substr(0, 4)}</TextLogo>
      )}
      {showNetworkBadge && networkLogo && (
        <NetworkBadge src={networkLogo} {...logoProps} />
      )}
    </StyledContainer>
  )
}

export default TokenLogo

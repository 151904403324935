import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import axios from 'axios'
import { ASSET_PREFIX } from '~/constants/env'
import striptags from 'striptags'
import dayjs from 'dayjs'

const fetcher = (url) => axios.get(url).then((res) => res.data)

export const parseArticle = (article) => {
  if (!article) return
  const matches = article?.body?.match(/(?:img[^>]*src=")([^"]*)/)

  article.thumbnail = matches
    ? matches[1]
    : `${ASSET_PREFIX}/images/blog-article-cover.png`
  article.digest = striptags(article?.body)
  article.created_at = dayjs(article.created_at).format('YYYY-MM-DD')
  article.updated_at = dayjs(article.updated_at).format('YYYY-MM-DD')
  return article
}

export const useNews = () => {
  const { i18n } = useTranslation()
  const lang = i18n.resolvedLanguage === 'zh' ? 'zh-cn' : 'en-us'

  // 数据源调整为公告中心
  const { data, error } = useSWR(
    `https://tokenlon.zendesk.com/api/v2/help_center/${lang}/categories/360002021512/articles.json?sort_by=position`,
    fetcher,
  )

  if (error) {
    console.error(error)
    return []
  }

  if (!data) return []

  const news = data.articles.map(parseArticle)

  return news.splice(0, 3)
}

import { useTranslation } from 'react-i18next'
import Head from 'next/head'
import { HOST } from '~/constants/host'

export type OpenGraph = {
  property: string
  content: string
  key?: string
}

export type MetaProps = {
  openGraphData: OpenGraph[]
}

export const Metas: React.FC<MetaProps> = ({ openGraphData }) => {
  const { t } = useTranslation()
  return (
    <>
      <Head>
        <title>{t('meta_common_title')}</title>
        <meta name="description" content={t('meta_common_description')} />
        <meta name="keywords" content={t('meta_common_keywords')} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no, email=no" />
        <meta name="renderer" content="webkit" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="MobileOptimized" content="320" />
        <meta name="screen-orientation" content="portrait" />
        <meta name="x5-orientation" content="portrait" />
        <meta name="full-screen" content="yes" />
        <meta name="x5-fullscreen" content="true" />
        <meta name="browsermode" content="application" />
        <meta name="x5-page-mode" content="app" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no,maximum-scale=1,minimum-scale=1,viewport-fit=cover"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta property="og:site_name" content="Tokenlon" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={HOST} key="ogurl" />
        <meta
          property="og:title"
          content={t('meta_common_title')}
          key="ogtitle"
        />
        <meta
          property="og:description"
          content={t('meta_common_description')}
          key="ogdesc"
        />
        <meta
          property="og:image"
          content={HOST + '/images/tokenlon-og-banner.jpg'}
          key="ogimage"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tokenlon" />
        <meta name="twitter:creator" content="@tokenlon" />
        <meta
          name="twitter:image"
          content={HOST + '/images/tokenlon-og-banner.jpg'}
          key="twimage"
        />
        {/* 页面单独设置的 og tag 会被 _app.tsx 里设置的给覆盖
        https://github.com/vercel/next.js/issues/35172#issuecomment-1169362010 */}
        {openGraphData.map((og) => (
          <meta {...og} />
        ))}
        {/* verify from randy.lien@token.im */}
        <meta
          name="google-site-verification"
          content="IfC5Dz1e3PES5nemP0p6Ypqo5mdtAMNrK-RoHUCKRo8"
        />
        {/* verify from zhoufan@token.im */}
        <meta
          name="google-site-verification"
          content="4qI7VAtA9S0LS7eD6zSpjR23yRtHe-ySYon5gEw9Ap4"
        />
      </Head>
    </>
  )
}

export default Metas

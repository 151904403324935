import { StaticJsonRpcProvider } from '@ethersproject/providers'
import {
  ArbitrumOne,
  ArbitrumRinkeby,
  Mainnet,
  Sepolia,
  Base,
  BSC,
  Avalanche,
  Polygon,
  Cronos,
  Fantom,
  Optimism,
  zkSync,
  Klaytn,
  Moonbeam,
  Gnosis,
  Aurora,
  WCMainnet,
  WCSepolia,
  WCArbitrumOne,
  WCArbitrumRinkeby,
} from '~/model/chain/ethereum'

export const ALL_SUPPORTED_CHAINS = [
  Mainnet,
  Sepolia,
  ArbitrumOne,
  ArbitrumRinkeby,
  Base,
  BSC,
  Optimism,
  Polygon,
]

export const ALL_SUPPORTED_WC_CHAINS = [
  WCMainnet,
  WCSepolia,
  WCArbitrumOne,
  WCArbitrumRinkeby,
  Base,
  BSC,
  Optimism,
  Polygon,
]

export const EXTRA_CONNECT_CHAINS = [
  zkSync,
  Avalanche,
  Cronos,
  Fantom,
  Klaytn,
  Moonbeam,
  Gnosis,
  Aurora,
]

export const ALL_SUPPORTED_CHAIN_IDS = ALL_SUPPORTED_CHAINS.map(
  (chain) => chain.chainId,
)

export const EXTRA_CONNECT_CHAIN_IDS = EXTRA_CONNECT_CHAINS.map(
  (chain) => chain.chainId,
)

export const ALL_SUPPORTED_RPC_URLS: Record<
  number,
  string
> = ALL_SUPPORTED_CHAINS.reduce(
  (prev, next) => ({ ...prev, [next.chainId]: next.rpcUrl }),
  {},
)

export enum SupportedChainId {
  Mainnet = 1,
  Sepolia = 11155111,
  ArbitrumOne = 42161,
  ArbitrumRinkeby = 421611,
  Base = 8453,
  BSC = 56,
  Optimism = 10,
  Polygon = 137,
  zkSync = 324,
}
export const ALL_SUPPORTED_WC_RPC_URLS = ALL_SUPPORTED_WC_CHAINS.reduce(
  (prev, next) => ({ ...prev, [next.chainId]: next.rpcUrl }),
  {},
)

export const DEFAULT_RPC_PROVIDERS: {
  [key in SupportedChainId]: StaticJsonRpcProvider
} = {
  [SupportedChainId.Mainnet]: new StaticJsonRpcProvider(Mainnet.rpcUrl),
  [SupportedChainId.Sepolia]: new StaticJsonRpcProvider(Sepolia.rpcUrl),
  [SupportedChainId.ArbitrumOne]: new StaticJsonRpcProvider(ArbitrumOne.rpcUrl),
  [SupportedChainId.ArbitrumRinkeby]: new StaticJsonRpcProvider(
    ArbitrumRinkeby.rpcUrl,
  ),
  [SupportedChainId.Base]: new StaticJsonRpcProvider(Base.rpcUrl),
  [SupportedChainId.BSC]: new StaticJsonRpcProvider(BSC.rpcUrl),
  [SupportedChainId.Optimism]: new StaticJsonRpcProvider(Optimism.rpcUrl),
  [SupportedChainId.Polygon]: new StaticJsonRpcProvider(Polygon.rpcUrl),
  [SupportedChainId.zkSync]: new StaticJsonRpcProvider(zkSync.rpcUrl),
}

import ImKeyProvider from '@imkey/web3-provider'

export class ImKeyConnect extends ImKeyProvider {
  removeListener(type: string, listener: any) {
    super.off(type, listener)
    return this
  }
  override on(type: string, listener: any) {
    if (type === 'accountsChanged') {
      super.on('accountsChanged', (accounts: { accounts: string[] }) => {
        listener(accounts.accounts)
      })
    } else {
      super.on(type, listener)
    }
    return this
  }
}

import styled, { css } from 'styled-components'

interface FlexContainerProps {
  gap?: number | string
  rowGap?: number | string
  columnGap?: number | string
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
}

const parseGapValue = (gap: number | string) => {
  if (typeof gap === 'number') {
    return `${gap}px`
  }
  return gap
}

const parseHalfGapValue = (gap: number | string) => {
  return typeof gap === 'number' ? `${gap / 2}px` : `calc(${gap} / 2)`
}

// 用 @supports 检测 flex gap 属性不准确，所以用这个方法检测
// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/css/flexgap.js
const checkFlexGap = () => {
  // create flex container with row-gap set
  const flex = document.createElement('div')
  flex.style.display = 'flex'
  flex.style.flexDirection = 'column'
  flex.style.rowGap = '1px'

  // create two, elements inside it
  flex.appendChild(document.createElement('div'))
  flex.appendChild(document.createElement('div'))

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex)

  const flexHeight = flex.scrollHeight
  // determine if flex-gap is supported, accounting for Safari's bug
  const isSupported = flexHeight === 1 || flexHeight === 2
  flex.parentNode.removeChild(flex)

  return isSupported
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  align-items: ${({ align = 'stretch' }) => align};

  ${({ gap, rowGap, columnGap, direction = 'row' }) => {
    const isSupportFlexGap = checkFlexGap()

    if (isSupportFlexGap) {
      return css`
        gap: ${gap ? parseGapValue(gap) : undefined};
        row-gap: ${rowGap ? parseGapValue(rowGap) : undefined};
        column-gap: ${columnGap ? parseGapValue(columnGap) : undefined};
      `
    }

    // Fallback for browsers that don't support gap
    const gapValue = gap ? parseHalfGapValue(gap) : '0'
    const rowGapValue = rowGap ? parseHalfGapValue(rowGap) : gapValue
    const columnGapValue = columnGap ? parseHalfGapValue(columnGap) : gapValue

    const negativeMargin = (value: string) =>
      value === '0' ? '0' : `-${value}`

    const isColumn = direction.includes('column')
    return css`
      margin: ${
        isColumn
          ? `${negativeMargin(rowGapValue)} 0`
          : `0 ${negativeMargin(columnGapValue)}`
      };

      > * {
        ${
          isColumn
            ? css`
                margin: ${rowGapValue} 0;
                &:first-child {
                  margin-top: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              `
            : css`
                margin: 0 ${columnGapValue};
                &:first-child {
                  margin-left: 0;
                }
                &:last-child {
                  margin-right: 0;
                }
              `
        }
    `
  }}
`

export interface RetryOptions {
  n: number
  minWait: number
  maxWait: number
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const waitRandom = (min: number, max: number): Promise<void> => {
  return wait(min + Math.round(Math.random() * Math.max(0, max - min)))
}

export const retry = <T>(
  fn: () => Promise<T>,
  { n, minWait, maxWait }: RetryOptions,
) => {
  let completed = false
  let rejectCancelled: (error: Error) => void

  const promise = new Promise<T>(async (resolve, reject) => {
    rejectCancelled = reject
    while (true) {
      let result: T
      try {
        result = await fn()
        if (!completed) {
          resolve(result)
          completed = true
        }
        break
      } catch (error) {
        if (completed) {
          break
        }
        if (n <= 0 || !error.isRetryableError) {
          reject(error)
          completed = true
          break
        }
        n--
      }

      await waitRandom(minWait, maxWait)
    }
  })

  return {
    promise,
    cancel: () => {
      if (completed) return
      completed = true
      rejectCancelled(new CancelledError())
    },
  }
}

class CancelledError extends Error {
  public isCancelledError: true = true
  constructor() {
    super('Cancelled')
  }
}

/**
 * Throw this error if the function should retry
 */
export class RetryableError extends Error {
  public isRetryableError: true = true
}

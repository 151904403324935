import styled from 'styled-components'

export const Row = styled.div<{
  width?: string
  wrap?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  margin?: string
  borderRadius?: string
  backgroundColor?: string
  gap?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  padding: 0;
  flex-wrap: ${({ wrap }) => wrap ?? 'nowrap'};
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  gap: ${({ gap }) => gap ?? 0};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  margin: ${({ margin }) => margin ?? 0};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
`

export const RowBetween = styled(Row)`
  justify-content: space-between;
`

export const RowGap = styled(Row)<{ gap?: string; justify?: string }>`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`

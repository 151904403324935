import {
  Profile,
  Signer,
  Staking,
  StakingDashboard as SDKStakingDashboard,
  StakeRecord as SDKStakeRecord,
  AllStakedDayAmounts as SDKAllStakedDayAmounts,
} from '@tokenlon/sdk'
import { axios } from '../common'
import {
  LonCirculation,
  LonDailyTotalStakedAmount,
  LonStakingApyAndScale,
} from './types'

const ONE_DAY_SECONDS = 24 * 60 * 60

export const getStakingDashboard = async (
  chainId,
): Promise<SDKStakingDashboard> => {
  if (!chainId) return
  const staking = new Staking(chainId)
  return staking.fetchStakingDashboard()
}

// 质押 Lon
export const postStake = async (provider, amount: string, callback) => {
  if (!provider || !amount) return
  const signer = new Signer(provider, 'web', { afterProcessEthTx: callback })
  const txRaw = await signer.sendStakeWithPermit(amount)
  return signer.sendTransaction(txRaw)
}

// 解锁 xLon，解锁后进入赎回冷静期
export const postUnstake = async (provider, callback) => {
  if (!provider) return
  const signer = new Signer(provider, 'web', { afterProcessEthTx: callback })
  const txRaw = await signer.sendUnstake()
  return signer.sendTransaction(txRaw)
}

// 判断赎回是否有冷静期
export const getStakingCoolDown = async (
  provider,
  address,
): Promise<number> => {
  if (!provider || !address) return
  const profile = new Profile(provider)
  return profile.stakersCooldowns(address).then(Number)
}

// 获取赎回冷静期时间（in seconds）
export const getStakingCoolDownRemainSeconds = async (
  provider,
  address,
): Promise<number> => {
  if (!provider || !address) return
  const profile = new Profile(provider)
  return profile.cooldownRemainSeconds(address).then(Number)
}

// 赎回冷静期结束后，进行赎回 Lon
export const postRedeem = async (provider, amount: string, callback) => {
  if (!provider || !amount) return
  const signer = new Signer(provider, 'web', { afterProcessEthTx: callback })
  const txRaw = await signer.sendRedeem(amount)
  return signer.sendTransaction(txRaw)
}

// 赎回冷静期未结束时，获得强制赎回 Lon 的罚金预览
export const getRageExitPreview = async (
  provider,
  address,
): Promise<{ receiveAmount: string; penaltyAmount: string }> => {
  if (!provider || !address) return
  const profile = new Profile(provider)
  return profile.previewRageExit(address)
}

// 赎回冷静期未结束时，进行强制赎回 Lon
export const postRageExit = async (provider, callback) => {
  if (!provider) return
  const signer = new Signer(provider, 'web', { afterProcessEthTx: callback })
  const txRaw = await signer.sendRageExit()
  return signer.sendTransaction(txRaw)
}

// 获取质押赎回历史
export const getStakingList = async (
  chainId,
  address,
  page,
  pageSize,
): Promise<Array<SDKStakeRecord>> => {
  if (!chainId || !address) return
  const staking = new Staking(chainId)
  return staking.fetchStakingList(address, page, pageSize)
}

export const getStakingListTotal = async (
  chainId,
  address,
): Promise<number> => {
  if (!chainId || !address) return
  const staking = new Staking(chainId)
  const list = await staking.fetchStakingList(address, 1, 1000)
  return list?.length ?? 0
}

export const getAllStakedDayAmounts = async (
  chainId,
): Promise<SDKAllStakedDayAmounts[]> => {
  if (!chainId) return
  const now = Math.floor(new Date().getTime() / 1000)
  const start = 0
  const end = now + ONE_DAY_SECONDS

  const staking = new Staking(chainId)
  return staking.fetchAllStakedDayAmounts(start, end)
}

export const getLonCirculation = async (): Promise<LonCirculation> => {
  return axios.get('/referral/lon/staking/circulation')
}

export const getLonStakedAmounts = async (): Promise<
  LonDailyTotalStakedAmount[]
> => {
  return axios.get('/referral/lon/staked_amounts')
}

export const getStakingApyAndScale = async (): Promise<
  LonStakingApyAndScale
> => {
  return axios.get('/referral/lon/staking/apy')
}

import { TokenlonV6NetworkConfig } from '@tokenlon/v6-sdk'
import { compareAddress } from '~/utils/token'
import { ETH, USDT } from '~/constants/addresses'

type GetDefaultTokenParams = {
  selectedChainId: number
  /**
   * Token in the other direction
   */
  baseToken: SpotSwapToken
  networkConfigs: TokenlonV6NetworkConfig[]
  tokens: SpotSwapToken[]
}

export const getDefaultToken = ({
  selectedChainId,
  baseToken,
  networkConfigs,
  tokens,
}: GetDefaultTokenParams): SpotSwapToken => {
  const networkConfig = networkConfigs.find(
    ({ chainId }) => chainId === selectedChainId,
  )
  const defaultPair = networkConfig?.defaultPair ?? [
    { address: ETH[selectedChainId] },
    { address: USDT[selectedChainId] },
  ]

  const address = compareAddress(defaultPair[0].address, baseToken.address)
    ? defaultPair[1].address
    : defaultPair[0].address

  return tokens.find(
    (token) =>
      token.chainId === selectedChainId &&
      compareAddress(token.address, address),
  )
}

import { SELECTABLE_CONNECTIONS } from '~/connection'
import { detectMetaMaskWallet, detectImTokenApp } from '~/connection/detect'
import { useBreakpoint } from './useMediaQuery'

export const useConnectionOptions = () => {
  const breakpoints = useBreakpoint()
  const { isMobile } = breakpoints
  const isImTokenApp = detectImTokenApp()
  const isMetaMaskWallet = detectMetaMaskWallet()
  const isDesktop = !isMobile && !isImTokenApp

  const options = SELECTABLE_CONNECTIONS.filter((connection) => {
    if (typeof connection.shouldDisplay === 'function') {
      return connection.shouldDisplay(breakpoints, {
        isDesktop,
        isImTokenApp,
        isMetaMaskWallet,
      })
    }
  })

  return options
}

import { RUNTIME_ENV, TokenlonEnv } from './env'

export const API_ROOT_DOMAIN = {
  [TokenlonEnv.development]: 'imdev.works',
  [TokenlonEnv.local]: 'imdev.works',
  [TokenlonEnv.staging]: 'imstaging.works',
  [TokenlonEnv.production]: 'tokenlon.im',
}[RUNTIME_ENV]

export const BIZ_API_HOST = {
  [TokenlonEnv.development]: 'api.imdev.works',
  [TokenlonEnv.local]: 'api.imdev.works',
  [TokenlonEnv.staging]: 'api.imstaging.works',
  [TokenlonEnv.production]: 'biz.token.im',
}[RUNTIME_ENV]

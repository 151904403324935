import { createMulticall, MulticallState } from '@uniswap/redux-multicall'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { RUNTIME_ENV } from '~/constants/env'

// Create a multicall instance with default settings
export const multicall = createMulticall()

export const multicallReducer = multicall.reducer

export const multicallPersistConfig: PersistConfig<MulticallState> = {
  key: `sub-multicall-${RUNTIME_ENV}`,
  storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: [],
}

export default persistReducer(multicallPersistConfig, multicallReducer)

import { Subscription } from 'stompjs'
import {
  OrderResponse as SDKOrderResponse,
  RateResponse as SDKRateResponse,
} from '@tokenlon/sdk'

export enum INPUT_MODE {
  MAKER = 'maker',
  TAKER = 'taker',
}

export enum TradeDirection {
  Input = 'input',
  Output = 'output',
}

export enum RateState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  NetworkReason = 'NetworkReason',
  OK = 'OK',
  REFRESH_LOADING = 'REFRESH_LOADING',
}

export enum PROTOCOL_TYPE {
  AMM = 'AMM',
  PMM = 'PMM',
}

export interface RateResponse {
  error?: Error
  topic?: string
  message?: SDKRateResponse
  subscription?: Subscription
}

export interface OrderResponse {
  error?: Error
  topic?: string
  message?: SDKOrderResponse
  subscription?: Subscription
}

export interface GasLimitByProtocols {
  bufferFactor?: number
  pmm?: string
  sushiswap?: string
  uniswap?: string
  'rfq v1'?: string
  rfqv2?: string
}

export enum PermitSource {
  TokenlonAllowanceTarget = 0,
  Token = 1,
  TokenPermit = 2,
  Permit2AllowanceTransfer = 3,
  Permit2SignatureTransfer = 4,
}

export type QueryParams = {
  caip2?: string
  inputAddress?: string
  outputAddress?: string
}

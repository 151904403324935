import type { AddEthereumChainParameter } from '@web3-react/types'
import { EIP1193 } from '@web3-react/eip1193'

export class HomeOfToken extends EIP1193 {
  protected desiredChainId?: number
  protected desiredChainParameter?: AddEthereumChainParameter

  public async activate(
    desiredChainIdOrChainParameters?: number | AddEthereumChainParameter,
  ): Promise<void> {
    if (typeof desiredChainIdOrChainParameters === 'number') {
      this.desiredChainId = desiredChainIdOrChainParameters
    } else if (desiredChainIdOrChainParameters) {
      this.desiredChainParameter = desiredChainIdOrChainParameters
      this.desiredChainId = Number(desiredChainIdOrChainParameters.chainId)
    }

    const cancelActivation = this.actions.startActivation()

    try {
      const accounts = (await this.provider.request({
        method: 'eth_requestAccounts',
        params: {
          chainId: this.desiredChainId,
        },
      })) as string[]

      const chainId = parseChainId(
        (await this.provider.request({ method: 'eth_chainId' })) as string,
      )

      if (this.desiredChainId && chainId !== this.desiredChainId) {
        try {
          await this.provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${this.desiredChainId.toString(16)}` }],
          })
        } catch (switchError) {
          if (switchError.code === 4902 && this.desiredChainParameter) {
            try {
              await this.provider.request({
                method: 'wallet_addEthereumChain',
                params: [this.desiredChainParameter],
              })
            } catch (addError) {
              cancelActivation()
              throw addError
            }
          } else {
            cancelActivation()
            throw switchError
          }
        }
      }

      const updatedChainId = parseChainId(
        (await this.provider.request({ method: 'eth_chainId' })) as string,
      )
      this.actions.update({ chainId: updatedChainId, accounts })
    } catch (error) {
      cancelActivation()
      throw error
    }
  }
}

function parseChainId(chainId: string | number) {
  return typeof chainId === 'string' ? Number.parseInt(chainId, 16) : chainId
}

import { axios } from '../common'
import {
  LiquidityPools,
  MiningRewards,
  MyMiningData,
  RoundTradeMiningRewards,
  RoundTradeMiningReport,
} from './types'

export * from './types'

export const getCodeDetail = async (
  code: string,
): Promise<{
  address: string
  code: string
  holdTFT: boolean
}> => {
  if (!code || code?.length !== 5) return
  return axios.get(`/referral/code/${code}`)
}

export const postInvitationCode = async (code: string, params) => {
  if (!code) return
  return axios.post(`/referral/code/${code}`, params)
}

export const getInvitationCode = async (
  address: string,
): Promise<{
  address: string
  code: string
}> => {
  if (!address) return
  return axios.post(`/referral/code`, { address })
}

export const getMyInvitations = async (
  address: string,
  sort = 'invited_at',
  offset = 0,
  limit = 10,
): Promise<{
  invitations: Array<{
    invitedAt: number
    state: string
    wallet: string
  }>
  total: number
}> => {
  if (!address) return
  return axios.get(
    `/referral/mining/invitations/${address}?sort=${sort}&offset=${offset}&limit=${limit}`,
  )
}

export const getMiningRewards = async (): Promise<MiningRewards> => {
  return axios.get(`/referral/mining/pools`)
}

export const getMyMiningData = async (
  address: string,
): Promise<MyMiningData> => {
  if (!address) return
  return axios.get(`/referral/mining/powers/${address}`)
}

export const getRoundTradeMining = async (
  address: string,
): Promise<RoundTradeMiningRewards> => {
  if (!address) return
  return axios.get(`/referral/mining/rewards/${address}`)
}

export const getLPs = async (): Promise<LiquidityPools> => {
  return axios.get(`/lon-edge/staking/profile`)
}

export const getRoundTradeMiningReport = async (
  round: number,
): Promise<RoundTradeMiningReport> => {
  return axios.get(`/referral/lon/trade_mining_report?round=${round}`)
}

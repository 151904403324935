import en from './en_US'
import zh from './zh_CN'

// @todo: @izayl remove unused locale string
// @todo: @izayl builds check action to validate missing locale string
const locales = {
  en: { translation: { ...en } },
  zh: { translation: { ...zh } },
}

export const LOCALES: Record<string, keyof typeof locales> = {
  EN: 'en',
  ZH: 'zh',
}

export type LOCALES = keyof typeof locales

export default locales

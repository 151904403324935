import { useWeb3React } from '@web3-react/core'

export const useIsHoT = () => {
  const { provider } = useWeb3React()
  if (!provider) {
    return false
  }
  // @ts-ignore
  return !!provider?.provider?.isHoT
}

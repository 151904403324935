import { FeeData } from '@ethersproject/providers'
import { createSlice } from '@reduxjs/toolkit'
import { PersistConfig, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { RUNTIME_ENV } from '~/constants/env'

export enum ApplicationModal {
  ALLOWANCE_LIST,
  ALLOWANCE_TOKEN,
  APPROVAL_STATUS,
  CLAIMING,
  TRADE_ORDER,
  LON_STAKING,
  LON_REDEMPTION,
  MOBILE_SIDEBAR,
  SPOT_SWAP_SETTINGS,
  WALLET_CONNECT,
  WALLET_INFO,
  LIMIT_ORDER_PREVIEW,
  WETH_WRAP,
  WETH_PREVIEW,
  WETH_RESULT,
  TOOL,
  FEE_INTRO,
  SWAP_ORDER_PREVIEW,
  LON_REWARD,
  PAY_WITH_TOKENS_INTRO,
  MINI_SET_PAY_MODE,
  LO_ADD_CUSTOM_TOKEN,
  PERMIT2_INTRO,
  SWAP_PROTECTION_INTRO,
  SMART_ROUTING_DETAIL,
  SWITCH_NETWORK,
  IMKEY_UNSUPPORTED,
  IP_BLOCKED,
}

export interface ApplicationState {
  readonly blockNumber: { readonly [chanId: number]: number }
  readonly chainId: number | null
  readonly feeData: Record<number, FeeData>
  readonly openModal: ApplicationModal[]
  readonly modalState: {
    [key in keyof typeof ApplicationModal]?: any
  }
  /**
   * when any onchain operation or api call that
   * cause data need fresh, use this key as refresh token
   */
  readonly refreshToken: number
}

const initialState: ApplicationState = {
  blockNumber: {},
  chainId: null,
  openModal: [],
  modalState: {},
  refreshToken: 0,
  feeData: {},
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId: (state, action) => {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    updateBlockNumber: (state, action) => {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = Math.max(
          state.blockNumber[chainId],
          blockNumber,
        )
      }
    },
    updateFeeData: (state, action) => {
      const { chainId, feeData } = action.payload
      if (!state.feeData) state.feeData = {}

      state.feeData[chainId] = feeData
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload
    },
    setModalState: (state, action) => {
      const { modal, state: modalState } = action.payload
      state.modalState[modal] = state.modalState[modal] || {}
      state.modalState[modal] = { ...state.modalState[modal], ...modalState }
    },
    updateRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
  },
})

export const {
  updateChainId,
  updateBlockNumber,
  setOpenModal,
  setModalState,
  updateRefreshToken,
  updateFeeData,
} = applicationSlice.actions

export const applicationReducer = applicationSlice.reducer

export const applicationPersistConfig: PersistConfig<ApplicationState> = {
  key: `sub-application-${RUNTIME_ENV}`,
  storage,
  // @note: if need to black nested keys, we should use `transforms` api instead
  // @link: https://stackoverflow.com/a/63845127/7865048 use_transforms_to_remove_nested_keys
  blacklist: ['openModal'],
}

export default persistReducer(applicationPersistConfig, applicationReducer)

/**
 * Checks if two strings are equal, ignoring case.
 * @param str1 The first string to compare.
 * @param str2 The second string to compare.
 * @returns True if the strings are equal (ignoring case), false otherwise.
 */
export const isEqualIgnoreCase = (
  str1: string | undefined,
  str2: string | undefined,
): boolean => {
  return str1 && str2 && str1.toLowerCase() === str2.toLowerCase()
}

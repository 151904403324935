import { ethers } from 'ethers'
import { useMemo, useEffect, useState } from 'react'
import { FixedPointNumber } from '~/utils/fixedPoint'
import {
  useSingleCallResult,
  useSingleContractMultipleData,
} from '../state/multicall/hooks'
import { useTokenContract } from './useContract'
import { useWeb3React } from '@web3-react/core'
import { useUpdaterChainId } from '~/hooks/web3'
import { useV6SDK } from './useV6SDK'
import {
  PERMIT2_ABI,
  TokenlonSDK,
  genericSwapAddress,
  permit2Address,
} from '@tokenlon/v6-sdk'
import { Address } from 'viem'
import { getBlocksPerFetchForChainId } from '~/utils/getBlocksPerFetchForChainId'

export const useTokenAllowance = (
  token?: ERC20Token,
  owner?: string,
  spender?: string,
) => {
  const { chainId } = useWeb3React()
  const contract = useTokenContract(token?.address)

  const inputs = useMemo(() => [owner, spender], [owner, spender])

  const listenerOptions = useMemo(
    () => ({ blocksPerFetch: getBlocksPerFetchForChainId(chainId) ?? 1 }),
    [chainId],
  )

  const allowance = useSingleCallResult(
    contract,
    'allowance',
    inputs,
    listenerOptions,
  ).result

  return useMemo(
    () =>
      token && allowance
        ? new FixedPointNumber(allowance.toString(), token.decimals)
        : undefined,
    [token, allowance],
  )
}

export const useTokensAllowance = (
  tokens: ERC20Token[],
  owner: string,
  spender: string,
) => {
  const expectChainId = useUpdaterChainId()
  const tokenAddresses = (tokens || []).map(({ address }) =>
    ethers.utils.getAddress(address),
  )
  const permit2 = new ethers.Contract(
    permit2Address[expectChainId],
    PERMIT2_ABI,
  )
  const calls = useMemo(() => {
    const calls = []
    tokenAddresses.forEach((tokenAddress: string) => {
      calls.push([owner, tokenAddress, spender])
    })
    return calls
  }, [expectChainId, tokenAddresses, owner, spender])

  const listenerOptions = useMemo(
    () => ({ blocksPerFetch: getBlocksPerFetchForChainId(expectChainId) ?? 1 }),
    [expectChainId],
  )

  const results = useSingleContractMultipleData(
    permit2,
    'allowance',
    calls,
    listenerOptions,
  )

  const anyLoading: boolean = useMemo(
    () => results.some((callState) => callState.loading),
    [results],
  )

  return useMemo(() => {
    return anyLoading
      ? false
      : results.reduce((acc, callState, i) => {
          const value = callState.result?.[0]
          const expiration = callState.result?.[1]
          return {
            ...acc,
            [tokens[i].address]: {
              value,
              expiration,
              loading: callState.loading,
            },
          }
        }, {})
  }, [tokens, anyLoading, results])
}

export const useGSTokenPermit = (token: Address, sdk: TokenlonSDK) => {
  const { account } = useWeb3React()
  const expectChainId = useUpdaterChainId()
  const [amount, setAmount] = useState(0n)
  const [expiration, setExpiration] = useState(0)

  const getTokenPermit = async () => {
    try {
      const { amount, expiration } = await sdk.permit.getTokenPermit({
        account: account as Address,
        token,
        spender: genericSwapAddress[expectChainId],
      })
      setAmount(amount)
      setExpiration(expiration)
    } catch (err) {
      console.log('failed to get token permit', err)
    }
  }

  useEffect(() => {
    if (token && sdk && account) {
      getTokenPermit()
    }
  }, [token, sdk, account, expectChainId])

  return {
    tokenPermitAmount: amount,
    tokenPermitExpiration: expiration,
  }
}

export const useTokenPermit = (token: Address, spender: Address) => {
  const { account } = useWeb3React()
  const sdk = useV6SDK()
  const [amount, setAmount] = useState(0n)
  const [expiration, setExpiration] = useState(0)

  const getTokenPermit = async () => {
    try {
      const { amount, expiration } = await sdk.permit.getTokenPermit({
        account: account as Address,
        token,
        spender,
      })
      setAmount(amount)
      setExpiration(expiration)
    } catch (err) {
      console.log('failed to get token permit', err)
    }
  }

  useEffect(() => {
    if (token && spender && account) {
      getTokenPermit()
    }
  }, [token, spender, account])

  return {
    tokenPermitAmount: amount,
    tokenPermitExpiration: expiration,
  }
}

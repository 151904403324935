import styled from 'styled-components'

export const StyledButton = styled.button`
  background: #5f4dbc;
  border-radius: 48px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;
`

export const StyledPrimaryButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  border-radius: 48px;
  border: none;
  outline: none;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.btnPrimaryDisabled : theme.colors.btnPrimary};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
`

export const StyledSecondaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  border-radius: 48px;
  border: 1px solid ${({ theme }) => theme.colors.btnTextSecondary};
  outline: none;
  background: ${({ theme }) => theme.colors.btnSecondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.btnTextSecondary};
  cursor: pointer;
`

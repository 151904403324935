import { TokenlonEnv } from './env'

export const CLIENT_ID = {
  [TokenlonEnv.local]: 'rFWlorAyERa7MtnIzyNJr8Pv5TZ4zc+G1jlshhSvzHE=',
  [TokenlonEnv.development]: 'fh3L9Dnlac1I0s19503Mtl2dVujaTchJsVFWUc+BAgU=',
  [TokenlonEnv.staging]: 'QE3Y/0YC3BH6oKjQ+Gw4DyJLrRT942OrVnovQvHs1zM=',
  [TokenlonEnv.production]: '8LwD3aetLRF+iG02qHoXP3Eg4/Lf0AGrOrPZY29skgw=',
}

export const ClIENT_SECRET = {
  [TokenlonEnv.local]: 'SaYMuSQCqidGsPN7hq86x5SiUdPcB4mNdVSYtXoRwXI=',
  [TokenlonEnv.development]: 'GK0UxgKnIXiTbI0rtMDsKCjCzMYYkvofrv5d9qqAN4U=',
  [TokenlonEnv.staging]: 'bDMlOnWMs0Db3s9fr0OAnvpUikB3bC2PnDk2e/p46Ug=',
  [TokenlonEnv.production]: 'DK8lR7d5Ur9DexjcqbOuHLWyssWL/UxUvKuftgVO1Hw=',
}

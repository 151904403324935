import IconWelcome from '~/assets/images/welcome.svg'
import styled from 'styled-components'

export const StyledAgreement = styled.div`
  border: 2px solid #735be9;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 0.2s;
  position: relative;
  margin-top: 18px;
  background: #f4f2ff;

  &::before {
    content: '';
    display: inline-block;
    height: 32px;
    width: 32px;
    background-image: url(${IconWelcome});
    background-size: cover;
    position: absolute;
    top: -18px;
    border: 2px solid black;
    border-radius: 50%;
    border-color: #735be9;
  }

  .agreement-info {
    color: #43454f;
    flex: 1;
  }
  a {
    color: #735be9;
    text-decoration: none;
  }
`

import { Env as SDKEnv } from '@tokenlon/sdk'

export enum TokenlonEnv {
  development = 'development',
  production = 'production',
  staging = 'staging',
  local = 'local',
}

export const RUNTIME_ENV =
  (process.env.NEXT_PUBLIC_ENV as TokenlonEnv) || TokenlonEnv.production

export const ENV = {
  production: RUNTIME_ENV === TokenlonEnv.production,
  development: RUNTIME_ENV === TokenlonEnv.development,
  staging: RUNTIME_ENV === TokenlonEnv.staging,
  local: RUNTIME_ENV === TokenlonEnv.local,
}

export const ASSET_PREFIX = process.env.ASSET_PREFIX || ''

if (ENV.staging) {
  SDKEnv.staging()
}
console.log('RUNTIME_ENV', RUNTIME_ENV)
console.log('SDK ENV', SDKEnv.env)

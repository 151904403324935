import { axios } from '~/apis/common'
import { API_ROOT_DOMAIN } from '~/constants/rootDomain'
import { LOMinTradeResult } from './types'

export const getLOMinTrade = async (
  chainId: number,
  makerToken: string,
  makerTokenAmount: string,
): Promise<LOMinTradeResult> => {
  return axios.post(
    `https://api.${API_ROOT_DOMAIN}/v5/limit-order/previeworder`,
    {
      chainId,
      makerToken,
      makerTokenAmount,
    },
  )
}

import { useEffect, useState } from 'react'
import { MediaBreakPoint, MediaBreakPointUp } from '~/theme'

const isClient = typeof window === 'object'
const isApiSupported = (api: string): boolean =>
  typeof window !== 'undefined' ? api in window : false

const errorMessage =
  'matchMedia is not supported, this could happen both because window.matchMedia is not supported by' +
  " your current browser or you're using the useMediaQuery hook whilst server side rendering."

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */
export const useMediaQuery = (breakPoint: MediaBreakPoint): boolean => {
  const mediaQuery = `(max-width: ${MediaBreakPointUp[breakPoint]})`
  if (!isClient || !isApiSupported('matchMedia')) {
    console.warn(errorMessage)
    return null
  }
  const [isVerified, setIsVerified] = useState(
    !!window.matchMedia(mediaQuery).matches,
  )
  const documentChangeHandler = (ev: MediaQueryListEvent) => {
    setIsVerified(!!ev.matches)
  }

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)

    try {
      mediaQueryList.addEventListener('change', documentChangeHandler)
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(documentChangeHandler)
    }

    return () => {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler)
      } catch (error) {
        mediaQueryList.removeListener(documentChangeHandler)
      }
    }
  }, [mediaQuery])

  return isVerified
}
export type BreakPoints = {
  isBreakPointLG: boolean
  isBreakPointMD: boolean
  isBreakPointSM: boolean
  isBreakPointXL: boolean
  isMobile: boolean
}
export const useBreakpoint = (): BreakPoints => {
  const isBreakPointXL = useMediaQuery(MediaBreakPoint.XL)
  const isBreakPointLG = useMediaQuery(MediaBreakPoint.LG)
  const isBreakPointMD = useMediaQuery(MediaBreakPoint.MD)
  const isBreakPointSM = useMediaQuery(MediaBreakPoint.SM)

  return {
    isBreakPointLG,
    isBreakPointMD,
    isBreakPointSM,
    isBreakPointXL,
    isMobile: isBreakPointSM,
  }
}

export default useMediaQuery

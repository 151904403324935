import {
  ALL_SUPPORTED_CHAINS,
  ALL_SUPPORTED_CHAIN_IDS,
  EXTRA_CONNECT_CHAINS,
} from '~/constants/chain'
import { ChainId } from '~/constants/chainId'
import { ENV, TokenlonEnv } from '~/constants/env'
import { LOCALES } from '~/locales'
import { Chain, ChainLite } from '~/typings/tokenlon'
import {
  Mainnet,
  Sepolia,
  Polygon,
  ArbitrumOne,
  ArbitrumRinkeby,
} from '~/model/chain/ethereum'

export const getChainById = (chainId: ChainId): Chain | undefined =>
  ALL_SUPPORTED_CHAINS.find((network) => network.chainId === chainId)

export const getExtraConnectChainById = (
  chainId: ChainId,
): ChainLite | undefined =>
  EXTRA_CONNECT_CHAINS.find((network) => network.chainId === chainId)

export const getExplorerAddressLink = (
  address: string,
  chainId: ChainId,
  locale?: LOCALES,
): string | '' =>
  getChainById(chainId)?.getExplorerAddressLink(address, locale) || ''

export const getExplorerTransactionLink = (
  transactionHash: string,
  chainId: ChainId,
  locale?: LOCALES,
): string | '' =>
  getChainById(chainId)?.getExplorerTransactionLink(transactionHash, locale) ||
  ''

export const getExplorerTokenLink = (
  address: string,
  chainId: ChainId,
  locale?: LOCALES,
): string | '' =>
  getChainById(chainId)?.getExplorerTokenLink(address, locale) || ''

export const getChainName = (chainId: ChainId) => {
  // When metamask adds a network, chainName uses Polygon Mainnet to avoid metamask warnings, but the Tokenlon website wants to be displayed only as Polygon.
  if (chainId === Polygon.chainId) {
    return 'Polygon'
  }

  return (
    getChainById(chainId)?.chainName ||
    getExtraConnectChainById(chainId)?.chainName ||
    'Unsupported Chain'
  )
}

export const isTestChain = (chainId: ChainId) =>
  ALL_SUPPORTED_CHAINS.some(
    (network) => network.isTestChain && network.chainId === chainId,
  )

export const isLocalChain = (chainId: ChainId) =>
  ALL_SUPPORTED_CHAINS.some(
    (network) => network.isLocalChain && network.chainId === chainId,
  )

export const isSupportedChain = (chainId: ChainId) =>
  ALL_SUPPORTED_CHAIN_IDS.includes(chainId)

export const getNetworkSupportedChain = (env: TokenlonEnv) =>
  ALL_SUPPORTED_CHAINS.filter((network) => network.matchedEnv.includes(env))

export const getNetworkLogo = (chainId: ChainId) =>
  getChainById(chainId)?.logoUrl ?? null

export const getCommonUpdaterChainId = () => {
  const isDev = ENV.development || ENV.local
  return isDev ? Sepolia.chainId : Mainnet.chainId
}

export const isMainnetOrSepolia = (chainId: ChainId) => {
  return chainId === Mainnet.chainId || chainId === Sepolia.chainId
}

export const getWETHSupportedNetworks = () => {
  const isDev = ENV.development || ENV.local
  return isDev
    ? [Sepolia.chainId, ArbitrumRinkeby.chainId]
    : [Mainnet.chainId, ArbitrumOne.chainId]
}

export const getSpotSwapSupportedNetworks = () => {
  const isDev = ENV.development || ENV.local

  return isDev
    ? ALL_SUPPORTED_CHAIN_IDS.filter(
        (chainId) =>
          chainId !== Mainnet.chainId && chainId !== ArbitrumOne.chainId,
      )
    : ALL_SUPPORTED_CHAIN_IDS.filter(
        (chainId) =>
          chainId !== Sepolia.chainId && chainId !== ArbitrumRinkeby.chainId,
      )
}

export const getLimitOrderSupportedNetworks = () => {
  const isDev = ENV.development || ENV.local
  return isDev
    ? [Sepolia.chainId, ArbitrumRinkeby.chainId]
    : [Mainnet.chainId, ArbitrumOne.chainId]
}

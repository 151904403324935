import TokenWebView from '@consenlabs-fe/webview'

export const detectMetaMaskWallet = () => {
  return window.ethereum?.isMetaMask ?? false
}

export const detectImTokenApp = () => TokenWebView.isTokenEnv()

export const isCoinbaseWallet = () => {
  return window.ethereum?.isCoinbaseWallet ?? false
}

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.includes('safari') && !userAgent.includes('chrome')
}

export const isFirefox = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.includes('firefox')
}

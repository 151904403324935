import styled, { css } from 'styled-components'
import { LogoStyleProps } from './types'

export const StyledContainer = styled.div`
  position: relative;
`

export const ImageLogo = styled.img<LogoStyleProps>`
  width: 28px;
  height: 28px;
  display: inline-block;
  border-radius: 50%;
  margin: ${({ space }) => (typeof space === 'undefined' ? '5' : space)}px;
  background: #fff;

  ${({ large }) =>
    large &&
    css`
      width: 36px;
      height: 36px;
    `}

  ${({ size }) =>
    typeof size !== 'undefined' &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}
`

export const TextLogo = styled.span<LogoStyleProps>`
  background: #c4c9d9;
  color: #ffffff;
  font-size: 10px;
  text-align: center;
  width: 28px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  border-radius: 50%;
  letter-spacing: -0.3px;
  margin: ${({ space }) => (typeof space === 'undefined' ? '5' : space)}px;
  ${({ large }) =>
    large &&
    css`
      width: 36px;
      height: 36px;
      line-height: 32px;
      border: 2px solid white;
    `}

  ${({ size }) =>
    typeof size !== 'undefined' &&
    css`
      width: ${size}px;
      height: ${size}px;
      line-height: ${size - 4}px;
      border: 2px solid white;
    `}
`

export const NetworkBadge = styled.img<LogoStyleProps>`
  position: absolute;
  top: 16px;
  left: 17px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: #fff;

  ${({ large }) =>
    large &&
    css`
      top: 23px;
      left: 22px;
      width: 18px;
      height: 18px;
    `}
`

import axios, { AxiosRequestConfig } from 'axios'
import { resolve } from 'path'
import { RUNTIME_ENV, TokenlonEnv } from '~/constants/env'
import { now } from '~/utils/now'

const api = axios

api.interceptors.request.use()

export class RPC {
  axios = axios.create({
    headers: { 'Content-Type': 'application/json' },
  })
  readonly _opts

  envEndpoints: Record<TokenlonEnv, string>
  endpoint: string

  constructor(opts: {
    envEndpoints?: Record<TokenlonEnv, string>
    endpoint?: string
  }) {
    if (!opts.envEndpoints && !opts.endpoint)
      throw new Error('At least set one endpoint')

    this._opts = opts
  }

  rpc<T>(
    path: string,
    method: string,
    params?: any,
    config?: AxiosRequestConfig,
  ) {
    const json = {
      jsonrpc: '2.0',
      id: now(),
      method,
      params: null,
    }

    if (params) {
      json.params = Array.isArray(params) ? params : [params]
    }
    const url = this.getUrl(path)
    return this.axios
      .post<{ result: T }>(url, json, config)
      .then((resp) => resp.data?.result)
  }

  getUrl(path: string) {
    const { envEndpoints, endpoint } = this._opts
    const url = new URL(endpoint || envEndpoints[RUNTIME_ENV])

    if (path === '/') {
      return url.href
    }

    url.pathname = resolve(url.pathname, path)

    return url.href
  }
}

export const baseRpc = async (
  endpoint: string,
  method: string,
  params: any,
  config?: AxiosRequestConfig,
) => {
  const json = {
    jsonrpc: '2.0',
    id: now(),
    method,
    params: null,
  }

  if (params) {
    json.params = Array.isArray(params) ? params : [params]
  }
  const url = new URL(endpoint)
  return axios
    .post<{ result: any }>(url.href, json, config)
    .then((resp) => resp.data?.result)
}

import { useTranslation } from 'react-i18next'
import { StyledPrimaryButton } from './styled'

interface ButtonProps {
  text?: string
  style?: React.CSSProperties
  disabled?: boolean
  handleClick: () => void
}

const PrimaryButton: React.FC<ButtonProps> = ({
  text,
  style,
  disabled,
  handleClick,
}) => {
  const { t } = useTranslation()
  const textLabel = text ?? t('ok')

  return (
    <StyledPrimaryButton
      style={style}
      disabled={disabled}
      onClick={handleClick}
    >
      {textLabel}
    </StyledPrimaryButton>
  )
}

export default PrimaryButton

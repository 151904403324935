import { ConnectionType } from './../connection/type'
import { useMemo } from 'react'
import { useSelectedConnectionType } from '~/state/user/hooks'
import { getConnection } from '~/connection/utils'

const MANUAL_CONNECT_WALLETS = [
  ConnectionType.Injected,
  ConnectionType.ImTokenWalletConnect,
  ConnectionType.ImTokenCallApp,
  ConnectionType.UnstoppableDomain,
  ConnectionType.Coinbase,
  ConnectionType.WalletConnectV2,
  ConnectionType.ImKey,
  ConnectionType.HoT,
]

export const useOrderedConnections = () => {
  const selectedConnectionType = useSelectedConnectionType()

  return useMemo(() => {
    const orderedConnectionTypes: ConnectionType[] = []

    // 优先自动连接 Gnosis Safe
    orderedConnectionTypes.push(ConnectionType.GnosisSafe)

    // 上次连接过钱包
    if (selectedConnectionType) {
      orderedConnectionTypes.push(selectedConnectionType)
    }

    orderedConnectionTypes.push(
      ...MANUAL_CONNECT_WALLETS.filter(
        (type) => type !== selectedConnectionType,
      ),
    )

    // fallback to network
    orderedConnectionTypes.push(ConnectionType.Network)

    return orderedConnectionTypes.map(getConnection).filter(Boolean)
  }, [selectedConnectionType])
}

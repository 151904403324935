import { useWeb3React } from '@web3-react/core'
import { useEffect, useMemo } from 'react'
import { AddressZero } from '@ethersproject/constants'
import { useTranslation } from 'react-i18next'
import { useTokensAllowance } from '~/hooks/useTokenAllowance'
import { useApprovalModalStateDispatch, useModalOpened, useModalState, useModalToggle } from '~/state/application/hooks'
import { ApplicationModal } from '~/state/application/reducer'
import {
  useLimitSwapTokens,
  useSpotSwapTokens,
} from '~/state/dex/hooks'
import { Loading } from '../Loading'
import Modal from '../Modal'
import Switch from '../Switch'
import TokenLogo from '../TokenLogo'
import {
  StyledAllowanceManageItemWrapper,
  StyledManageItemRight,
  StyledManageList,
} from './styled'
import { BigNumber } from 'ethers'
import { ApprovalProgress } from '../ApprovalModal/ApproveStatusLabel'
import {
  useGA,
  useUpdaterChainId,
  useLimitOrderRouter,
} from '~/hooks'
import isEmpty from 'lodash/isEmpty'
import { genericSwapAddress } from '@tokenlon/v6-sdk'
import { useV6SDK } from '~/hooks/useV6SDK'
import dayjs from 'dayjs'

export const AllowanceListModal: React.FC = () => {
  const { t } = useTranslation()
  const toggleAllowanceListModal = useModalToggle(
    ApplicationModal.ALLOWANCE_LIST,
  )
  const isAllowanceListModalOpen = useModalOpened(
    ApplicationModal.ALLOWANCE_LIST,
  )

  return (
    <Modal
      title={t('allowance_manage')}
      isOpen={isAllowanceListModalOpen}
      onDismiss={toggleAllowanceListModal}
      animate={false}
    >
      <AllowanceList />
    </Modal>
  )
}

const AllowanceList: React.FC = () => {
  const expectChainId = useUpdaterChainId()
  const spotSwapTokens = useSpotSwapTokens(expectChainId)
  const limitSwapTokens = useLimitSwapTokens(expectChainId)

  const isLimitOrder = useLimitOrderRouter()

  const tokens = useMemo(() => {
    return isLimitOrder && !isEmpty(limitSwapTokens)
      ? limitSwapTokens
      : spotSwapTokens
  }, [spotSwapTokens, limitSwapTokens, isLimitOrder])

  const { account } = useWeb3React()
  const allowanceAddress = genericSwapAddress[expectChainId]

  const allowanceResults = useTokensAllowance(
    Object.values(tokens),
    account,
    allowanceAddress,
  )

  if (!allowanceResults) {
    return <Loading />
  }

  // 排序规则：如无任何授权，则按名称 a-z 排序。若有授权代币，则授权代币在最前，按名称 a-z 排序。
  return (
    <StyledManageList>
      {Object.values(tokens)
        .filter(
          (token: LimitSwapToken | SpotSwapToken) =>
            token.address !== AddressZero,
        )
        .sort((t1, t2: LimitSwapToken | SpotSwapToken) => {
          const s1 = t1.symbol.toUpperCase()
          const s2 = t2.symbol.toUpperCase()
          return s1 > s2 ? 1 : s1 === s2 ? 0 : -1
        })
        .sort((t1, t2) => {
          const a1 = allowanceResults[t1.address] as {
            loading: boolean
            value: BigNumber | undefined
            expiration: number | undefined
          }
          const a2 = allowanceResults[t2.address] as {
            loading: boolean
            value: BigNumber | undefined
            expiration: number | undefined
          }
          const a1HaveAuthorized = !a1?.value?.isZero()
          const a2HaveAuthorized = !a2?.value?.isZero()
          if (a1HaveAuthorized && !a2HaveAuthorized) return -1
          else if (!a1HaveAuthorized && a2HaveAuthorized) return 1
          else return 0
        })
        .map((token: LimitSwapToken | SpotSwapToken) => (
          <AllowanceListItem
            key={token.address}
            token={token}
            allowance={allowanceResults[token.address]}
          />
        ))}
    </StyledManageList>
  )
}

const AllowanceListItem: React.FC<{
  token: LimitSwapToken | SpotSwapToken
  allowance: { loading: boolean | undefined; value: BigNumber | undefined; expiration: number | undefined }
}> = ({ token, allowance = { loading: false, value: undefined, expiration: undefined } }) => {
  const { t } = useTranslation()
  const { account: address } = useWeb3React()
  const account = address as `0x${string}`
  const expectChainId = useUpdaterChainId()
  const sdk = useV6SDK()
  const { logo, symbol } = token
  const { loading, value: allowanceValue, expiration } = allowance
  const checked = useMemo(() => {
    return (
      allowanceValue &&
      !allowanceValue.isZero() &&
      expiration > Math.floor(Date.now() / 1000)
    )
  }, [
    allowanceValue, expiration
  ])
  const { trackClick } = useGA()
  const updateApprovalState = useApprovalModalStateDispatch()
  const isApprovalModalOpen = useModalOpened(ApplicationModal.APPROVAL_STATUS)
  const { progress } = useModalState(ApplicationModal.APPROVAL_STATUS)
  const toggleApprovalModal = useModalToggle(ApplicationModal.APPROVAL_STATUS, {
    stack: true,
    modalState: {
      token: token,
      title: checked ? t('toggleAllowanceModal.lockdown_title', { symbol: symbol }) : t('toggleAllowanceModal.approve_title', { symbol: symbol }),
      description1: t('toggleAllowanceModal.description_1'),
      description2: t('toggleAllowanceModal.description_2'),
      showLogo: true,
      progress: ApprovalProgress.APPROVING
    }
  })

  const finalStates = [
    ApprovalProgress.CANCEL_SUCCESS,
    ApprovalProgress.CANCEL_REJECTED,
    ApprovalProgress.APPROVE_SUCCESS,
    ApprovalProgress.APPROVE_REJECTED
  ]

  const handleApprove = async () => {
    const receipt = await sdk.permit.approveSpenderContractWithReceipt({
      spender: genericSwapAddress[expectChainId]!,
      expiration: dayjs().add(1, 'month').unix(),
      token: token.address as `0x${string}`,
    }, account)
    console.log(`receipt: ${receipt}`)
  }

  const handleLockdown = async () => {
    const receipt = await sdk.permit.revokeSpenderContractWithReceipt({
      spender: genericSwapAddress[expectChainId]!,
      token: token.address as `0x${string}`,
    }, account)
    console.log(`receipt: ${receipt}`)
  }

  const closeApprovalModal = () => {
    setTimeout(() => {
      if (isApprovalModalOpen) {
        updateApprovalState({
          progress: ApprovalProgress.APPROVING,
        })
        toggleApprovalModal()
      }
    }, 2000)
  }

  const triggerApprove = () => {
    if (!isApprovalModalOpen) {
      toggleApprovalModal()
    }
    let actionPromise
    let progressSuccess
    let progressFailed
    if (checked) {
      actionPromise = handleLockdown()
      progressSuccess = ApprovalProgress.CANCEL_SUCCESS
      progressFailed = ApprovalProgress.CANCEL_REJECTED
    } else {
      actionPromise = handleApprove()
      progressSuccess = ApprovalProgress.APPROVE_SUCCESS
      progressFailed = ApprovalProgress.APPROVE_REJECTED
    }
    actionPromise
    .then(() => {
      updateApprovalState({
        progress: progressSuccess,
      })
      trackClick('webtl_mywallet_appove_confirm', {
        is_success: true,
        symbol: token.symbol,
        token: token.address,
      })
    })
    .catch((error) => {
      console.error(error)
      updateApprovalState({
        progress: progressFailed,
      })
      trackClick('webtl_mywallet_appove_confirm', {
        is_success: false,
        symbol: token.symbol,
        token: token.address,
        message: error?.message,
      })
    })
  }

  useEffect(() => {
    if (finalStates.includes(progress)) {
      closeApprovalModal()
    }
  }, [progress])

  return (
    <StyledAllowanceManageItemWrapper className="allowance-manage-item">
      <div className="manage-item-left">
        <TokenLogo logo={logo} symbol={symbol} size={50} />
      </div>
      <StyledManageItemRight className="manage-item-right">
        <span className="manage-item-symbol">{symbol}</span>
        <div className="manage-item-switch">
          <Switch
            checked={loading ? !checked : checked}
            loading={loading}
            onChange={triggerApprove}
          />
        </div>
      </StyledManageItemRight>
    </StyledAllowanceManageItemWrapper>
  )
}

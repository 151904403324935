import { captureException } from '@sentry/nextjs'

export const captureExceptionWithLabel = (
  label: string,
  error: Error,
  extra?: Object,
) => {
  captureException(label, {
    extra: {
      error,
      ...extra,
    },
  })
}

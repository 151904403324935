import { ENV } from '~/constants/env'

export const PROD_URL = 'https://tokenlon.im'
export const STAGING_URL = 'https://tokenlon.imstaging.works'
export const DEV_URL = 'https://tokenlon.imdev.works'

export const HOST = ENV.production
  ? PROD_URL
  : ENV.staging
  ? STAGING_URL
  : DEV_URL

import { useWeb3React } from '@web3-react/core'
import { isValidAddress } from './../utils/validate'
import { getChainById } from './../utils/chain'
import { Contract } from '@ethersproject/contracts'
import { AddressZero } from '@ethersproject/constants'
import { useMemo } from 'react'
import { captureException } from '@sentry/nextjs'
import { ABI, LONStaking } from '@tokenlon/sdk'
// import { useActiveWeb3React } from './web3'
import ERC20Instrument from '~/constants/abi/ERC20.json'
import MulticallInstrument from '~/constants/abi/MultiCall.json'
import ERC721_ABI from '~/constants/abi//erc721.json'
import ERC1155_ABI from '~/constants/abi/erc1155.json'
import MerkleRedeemAbi from '~/constants/abi/MerkleRedeem.json'
import StakingRewards from '~/constants/abi/StakingRewards.json'
import { RUNTIME_ENV, TokenlonEnv } from '~/constants/env'
import { getAddress } from 'ethers/lib/utils'
import { MultiCall, ERC20 } from '~/constants/abi/types'
import { Mainnet } from '~/model/chain/ethereum'
import { Chain } from '~/typings/tokenlon'
import { Erc721 } from '~/constants/abi/types/Erc721'
import { Erc1155 } from '~/constants/abi/types/Erc1155'

const ERC20_ABI = ERC20Instrument.abi
const MULTICALL_ABI = MulticallInstrument.abi

export const useContract = <T extends Contract = Contract>(
  addressOrAddressesMap: string | Record<number, string> | undefined,
  ABI: any,
  useSinger = true,
): T | null => {
  const { chainId, account, provider } = useWeb3React()

  return useMemo(() => {
    // chainId check
    if (!provider || !chainId) return null
    if (useSinger && !account) return null

    let address
    if (typeof addressOrAddressesMap === 'string')
      address = addressOrAddressesMap
    else address = addressOrAddressesMap?.[chainId]

    if (!address || address === AddressZero) return null

    if (!isValidAddress(address)) {
      console.error('useContract error: Invalid address')
      return null
    }
    address = getAddress(address)

    try {
      const contract = new Contract(
        address,
        ABI,
        useSinger ? provider.getSigner(account) : provider,
      )

      return contract
    } catch (error) {
      console.error('Failed to get contract', error)
      captureException(error)

      return null
    }
  }, [chainId, addressOrAddressesMap, ABI, useSinger, account, provider]) as T
}

export const useChain = (): Chain => {
  const { chainId } = useWeb3React()

  return useMemo(() => {
    return getChainById(chainId) || ({} as Chain)
  }, [chainId])
}

export const useTokenContract = (address?: string, useSinger = true) => {
  return useContract<ERC20>(address, ERC20_ABI, useSinger)
}

export const useLONContract = () => {
  const { lonAddress } = useChain()
  return useTokenContract(lonAddress)
}

export const useLONToken = () => {
  const LONContract = useLONContract()

  return useMemo(
    () =>
      LONContract
        ? {
            symbol: LONContract.symbol(),
            decimals: LONContract.decimals(),
            address: LONContract.address,
          }
        : undefined,
    [LONContract],
  )
}

export const useXLONContract = () => {
  const { xLonAddress } = useChain()
  return useTokenContract(xLonAddress)
}

export const useMultiCallAddress = () => {
  const { chainId } = useWeb3React()

  return useMemo(() => {
    const chain = getChainById(chainId) || Mainnet
    return chain.multicallAddress
  }, [chainId])
}

export const useInterfaceMulticall = () => {
  const MULTICALL_ADDRESS = useMultiCallAddress()
  return useContract<MultiCall>(
    MULTICALL_ADDRESS,
    MULTICALL_ABI,
    false,
  ) as MultiCall
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

export const useMerkleRedeemContract = () => {
  const MERKLE_REDEEM_CONTRACT =
    RUNTIME_ENV === TokenlonEnv.development || RUNTIME_ENV === TokenlonEnv.local
      ? '0xCD4252102AC626c27aCF0d839A7Ae015650BAa95'
      : '0x0000000006a0403952389B70d8EE4E45479023db'

  return useContract(MERKLE_REDEEM_CONTRACT, MerkleRedeemAbi)
}

export const useStakingRewardsContract = (contract: string) => {
  return useContract(contract, StakingRewards)
}

export const useLONStakingContract = () => {
  return useContract<any>(LONStaking, ABI.LonStaking)
}

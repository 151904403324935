import styled, { css } from 'styled-components'
import { TokenlonMessageType } from './types'

export const StyledMessage = styled.div<{ type: TokenlonMessageType }>`
  border-radius: 12px;
  text-align: center;
  line-height: 24px;
  color: #fff;
  padding: 10px 16px;
  width: 353px;
  max-width: 85vw;
  margin: 0 auto;
  transition: all ease-in 0.2s;
  animation: 0.2s linear slideDown;

  ${({ type }) =>
    type === 'success' &&
    css`
      background-color: #4dbc9b;
    `}
  ${({ type }) =>
    type === 'error' &&
    css`
      background-color: #d05353;
    `}

         @keyframes slideDown {
    from {
      margin-top: -10px;
      transform: scale(0.8);
      opacity: 0.2;
    }
    to {
      margin-top: 0px;
      transform: scale(1);
      opacity: 0.98;
    }
  }
`

import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import QRCodeFactory from 'qrcode'
import IconImToken from '~/assets/images/imtoken.svg'
import Modal from '~/components/Modal'
import { isClient } from '~/utils/userAgent'
import { imTokenWalletConnectConnection } from '~/connection'
import copy from 'copy-to-clipboard'
import { message } from '~/components/Message'

const StyledQRConnect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 385px;
  margin-top: -32px;

  .text {
    background: #fafafc;
    margin: 24px auto;
    padding: 12px 25px;
    border: 1px solid #eaecf6;
    box-sizing: border-box;
    border-radius: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #9598ab;
    display: inline-flex;
    align-items: center;
  }

  .copy {
    cursor: pointer;
    color: #898d97;
    font-size: 15px;
    margin: 20px auto 0;
  }
`

const StyledQRCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  img {
    width: 300px;
    height: 300px;
    display: inline-block;
    margin: 0 auto;
    cursor: 'none';
  }

  &::after {
    content: '';
    background-image: url(${IconImToken});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 45px;
    height: 45px;
    position: absolute;
  }
`

const ConnectQRCodeModal: React.FC<{
  qrcode: string
  onDismiss: () => void
}> = ({ qrcode, onDismiss }) => {
  const { t } = useTranslation()
  const [qrCodeUri, setQrCodeUri] = useState('')
  const copyWcUri = () => {
    copy(qrcode)
    message.success(t('copy_wc_uri_success'))
  }
  const getQRCodeUri = useCallback(async () => {
    if (qrcode) {
      return await QRCodeFactory.toDataURL(qrcode, {
        quality: 1,
        margin: 0,
        width: 600, // 2 times of dom width
      })
    }
    return ''
  }, [qrcode])

  useEffect(() => {
    getQRCodeUri().then((uri) => {
      setQrCodeUri(uri)
    })
  }, [qrcode])

  return (
    <Modal
      title={t('use_imtoken_scan')}
      isOpen={true}
      onDismiss={onDismiss}
      animate={false}
      contentStyle={{ maxWidth: '500px' }}
    >
      <StyledQRConnect>
        <StyledQRCode>
          <img src={qrCodeUri} alt="qrcode" />
        </StyledQRCode>
        <div className="copy" onClick={copyWcUri}>
          {t('copy_wc_uri')}
        </div>
      </StyledQRConnect>
    </Modal>
  )
}

const QRCODE_MODAL_WRAPPER_ID = 'QRCODE_MODAL_WRAPPER_ID'
const getModalWrapper = () => {
  if (!isClient) return null
  const dom = document.getElementById(QRCODE_MODAL_WRAPPER_ID)
  if (dom) return dom

  const wrapper = document.createElement('div')
  wrapper.setAttribute('id', QRCODE_MODAL_WRAPPER_ID)
  document.body.appendChild(wrapper)
  return wrapper
}

export const hideImTokenQRCodeModal = (cb?: Function) => {
  if (!isClient) return

  const modalWrapper = getModalWrapper()
  if (modalWrapper) {
    ReactDOM.unmountComponentAtNode(modalWrapper)
    if (cb) cb()
  }
}

export const showCustomQRCodeModal = (uri: string) => {
  console.log('open', uri)
  const modalWrapper = getModalWrapper()
  if (!modalWrapper) return

  ReactDOM.render(
    <ConnectQRCodeModal
      qrcode={uri}
      onDismiss={() => {
        if (imTokenWalletConnectConnection.connector) {
          imTokenWalletConnectConnection.connector.deactivate()
        }
        hideImTokenQRCodeModal()
      }}
    />,
    modalWrapper,
  )
}

const CustomQRCodeModal = {
  open: showCustomQRCodeModal,
  close: hideImTokenQRCodeModal,
}

export default CustomQRCodeModal

import {
  OrderState,
  Profile,
  Protocol,
  TokenlonToken as SDKTokenlonToken,
} from '@tokenlon/sdk'
import {
  MARKET_API_BASE_URL,
  RAILS_API_BASE_URL,
  AUTH_TOKEN,
  axios,
  rpc,
} from '../common'
import {
  AddressShare,
  FeeGears,
  GenesisRewards,
  LonPoolOverview,
  MarketPriceHistoryChartLine,
  MarketPriceHistoryChartCandle,
  GlobalConfig,
} from './types'

export * from './types'

export const PROTOCOLS = [Protocol.AMMV1, Protocol.AMMV2]

export const getGasPrice = async (): Promise<FeeGears> => {
  return rpc.post(
    `/ethereum`,
    {
      id: 1,
      jsonrpc: '2.0',
      method: 'wallet.getFeeGears',
      params: [],
    },
    {
      headers: {
        Authorization: `JWT ${AUTH_TOKEN}`,
        'X-DEVICE-TOKEN': 'tokenlon-web',
      },
    },
  )
}

// 传递进 getTradeTokenList 和 getRateOrOrder 的 quoter 必须是同一个对象
export const getTradeTokenList = async (
  quoter,
): Promise<SDKTokenlonToken[]> => {
  if (!quoter) return
  return quoter.getTradeTokenList(PROTOCOLS)
}

export const getOrderState = async (
  provider,
  executeTxHash,
): Promise<OrderState> => {
  if (!provider || !executeTxHash) return
  const profile = new Profile(provider)
  return profile.getOrderState(executeTxHash)
}

export const getLonPoolOverview = async (): Promise<LonPoolOverview> => {
  return axios.get(`/referral/pool`)
}

export const getAddressShare = async (
  address: string,
): Promise<AddressShare> => {
  if (!address) return
  return axios.get(`/referral/pool/shares?address=${address}`)
}

export const getGenesisReward = async (
  address: string,
): Promise<GenesisRewards> => {
  if (!address) return
  return axios.get(`lon-edge/accounts/${address}/claims?status=unclaim`)
}

export const getLonBalance = async (provider, address): Promise<string> => {
  if (!provider || !address) return
  const profile = new Profile(provider)
  return profile.getLonBalance(address)
}

export const getXLonBalance = async (provider, address): Promise<string> => {
  if (!provider || !address) return
  const profile = new Profile(provider)
  return profile.getXLonBalanceByAddr(address)
}

export const getTokenBalance = async (
  provider,
  address,
  tokenAddress,
): Promise<string> => {
  if (!provider || !address || !tokenAddress) return
  const profile = new Profile(provider)
  return profile.getTokenBalance(tokenAddress, address)
}

export const getSushiLonPriceRate = async (): Promise<{
  marketName: string
  price: number
}> => {
  return rpc.post(
    ``,
    {
      id: 1,
      jsonrpc: '2.0',
      method: 'api.getMarketPrice',
      params: {
        base: 'SUSHI',
        quote: 'LON',
        baseAmount: 0.000001,
      },
    },
    { baseURL: MARKET_API_BASE_URL },
  )
}

export const getMarketPriceHistoryChartLine = async (
  days: string,
  baseSymbol: string,
  quoteSymbol: string,
): Promise<MarketPriceHistoryChartLine> => {
  return rpc.post(
    ``,
    {
      id: 1,
      jsonrpc: '2.0',
      method: 'api.getMarketPriceHistoryChartLine',
      params: {
        market: 'tokenlon',
        baseSymbol,
        quoteSymbol,
        days,
      },
    },
    { baseURL: MARKET_API_BASE_URL },
  )
}

export const getMarketPriceHistoryChartCandle = async (
  days: string,
  baseSymbol: string,
  quoteSymbol: string,
): Promise<MarketPriceHistoryChartCandle> => {
  return rpc.post(
    ``,
    {
      id: 1,
      jsonrpc: '2.0',
      method: 'api.getMarketPriceHistoryChartCandle',
      params: {
        market: 'tokenlon',
        baseSymbol,
        quoteSymbol,
        days,
      },
    },
    { baseURL: MARKET_API_BASE_URL },
  )
}

export const getIsDisableTradeByIP = async (): Promise<any> => {
  try {
    const res = (await rpc.post(
      ``,
      {
        id: 1,
        jsonrpc: '2.0',
        method: 'configuration.getGlobal',
      },
      {
        baseURL: RAILS_API_BASE_URL,
        headers: {
          'X-CLIENT-VERSION': 'tokenlonweb:1.0.0',
        },
      },
    )) as GlobalConfig
    const isDisableTrade = res?.features?.MarketTab?.Trade?.disabled
    return isDisableTrade
  } catch {
    return false
  }
}

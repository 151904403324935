import styled from 'styled-components'

export interface BoxProps {
  width?: string
  maxWidth?: string
  maxHeight?: string
  minHeight?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  borderRadius?: string
  whiteSpace?: string
  overflow?: string
  overflowWrap?: string
  pointer?: boolean
  backgroundColor?: string
  mb?: string
  mt?: string
  ml?: string
  mr?: string
  mx?: string
  my?: string
  pb?: string
  pt?: string
  pl?: string
  pr?: string
  px?: string
  py?: string
}

export const Box = styled.div<BoxProps>`
  width: ${({ width }) => width ?? '100%'};
  max-width: ${({ maxWidth }) => maxWidth ?? 'unset'};
  max-height: ${({ maxHeight }) => maxHeight ?? 'unset'};
  min-height: ${({ minHeight }) => minHeight ?? 'unset'};
  display: flex;
  padding: 0;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  white-space: ${({ whiteSpace }) => whiteSpace ?? 'normal'};
  overflow-wrap: ${({ overflowWrap }) => overflowWrap ?? 'break-word'};
  overflow: ${({ overflow }) => overflow ?? 'hidden'};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? 'transparent'};
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
  ${({ mt }) => mt && `margin-top: ${mt}`};
  ${({ ml }) => ml && `margin-left: ${ml}`};
  ${({ mr }) => mr && `margin-right: ${mr}`};
  ${({ mx }) => mx && `margin-left: ${mx}; margin-right: ${mx}`};
  ${({ my }) => my && `margin-top: ${my}; margin-bottom: ${my}`};
  ${({ pb }) => pb && `padding-bottom: ${pb}`};
  ${({ pt }) => pt && `padding-top: ${pt}`};
  ${({ pl }) => pl && `padding-left: ${pl}`};
  ${({ pr }) => pr && `padding-right: ${pr}`};
  ${({ px }) => px && `padding-left: ${px}; padding-right: ${px}`};
  ${({ py }) => py && `padding-top: ${py}; padding-bottom: ${py}`};
`

export const ExchangeBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 68px auto 12px auto;

  &.hide {
    display: none;
  }

  &.spotswap {
    margin: 0;
  }

  ${({ theme }) => theme.media.sm`
    margin: 20px auto;
    padding: 0 15px;

    &.spotswap {
      margin: 0;
      padding: 0;
    }

    &.limitswap {
      margin-bottom: 15px;
    }
  `}

  &.withChart {
    margin-bottom: 0;
  }
`

import IconMetaMask from '~/assets/images/metamask.svg'
import IconSafe from '~/assets/images/safe.svg'
import IconWalletConnect from '~/assets/images/WalletConnect.svg'
import IconCoinBase from '~/assets/images/coinbase.svg'
import IconImToken from '~/assets/images/imtoken.svg'
import IconImKey from '~/assets/images/imKey-icon.svg'
import IconUnstoppableDomain from '~/assets/images/unstoppable-domain.svg'
import { initializeConnector } from '@web3-react/core'
import { Network } from '@web3-react/network'
import { MetaMask } from '@web3-react/metamask'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { WalletConnect } from '@web3-react/walletconnect-v2'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { EIP1193 } from '@web3-react/eip1193'
import {
  ALL_SUPPORTED_RPC_URLS,
  DEFAULT_RPC_PROVIDERS,
} from '~/constants/chain'
import { host } from '~/utils/host'
import { Connection, ConnectionType } from './type'
import CustomQRCodeModal from '~/components/WalletModal/Components/CustomQRCodeModal'
import { Dispatch } from 'redux'
import { updateSelectedConnectionType } from '~/state/user/actions'
import { Mainnet, Sepolia } from '~/model/chain/ethereum'
import { UAuthConnector } from '@uauth/web3-react'
import { CLIENT_ID, ClIENT_SECRET } from '~/constants/unstoppabledomain'
import { ENV, RUNTIME_ENV } from '~/constants/env'
import { WalletConnectV2 } from './WalletConnectV2'
import { WalletConnectV1 } from './WalletConnectV1'
import { URI_AVAILABLE } from '@web3-react/walletconnect-v2'
import { ImKeyConnect } from './ImKey'
import { detectImTokenApp } from './detect'
import { HomeOfToken } from './HomeOfToken'
import { HoTProvider } from 'hot-provider'

const rpcMap = ALL_SUPPORTED_RPC_URLS

const defaultChainId =
  ENV.development || ENV.local ? Sepolia.chainId : Mainnet.chainId

function onError(error: Error) {
  console.debug('initializeConnector error:', error)
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions) =>
    new Network({
      actions,
      // urlMap: DEFAULT_RPC_PROVIDERS as any,
      urlMap: DEFAULT_RPC_PROVIDERS,
      defaultChainId: 1,
    }),
)
export const networkConnection: Connection = {
  name: 'Network',
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.Network,
  shouldDisplay: () => false,
}

// Injected Browser Wallet
const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions, onError }),
)
export const injectedConnection: Connection = {
  name: detectImTokenApp() ? 'imToken App' : 'MetaMask',
  icon: detectImTokenApp() ? IconImToken : IconMetaMask,
  hooks: web3InjectedHooks,
  connector: web3Injected,
  type: ConnectionType.Injected,
  shouldDisplay: (_bp, client) => {
    return (client.isDesktop && client.isMetaMaskWallet) || client.isImTokenApp
      ? true
      : false
  },
}

// Gnosis Safe Wallet
const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector<GnosisSafe>(
  (actions) => new GnosisSafe({ actions }),
)
export const gnosisSafeConnection: Connection = {
  name: 'Safe',
  icon: IconSafe,
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GnosisSafe,
  shouldDisplay: () => {
    if (typeof window === 'undefined') return false
    if (window !== window.parent) return true
    return false
  },
}

// WalletConnect V1
const [web3WalletConnectV1, web3WalletConnectV1Hooks] = initializeConnector<
  WalletConnectV1
>((actions) => new WalletConnectV1({ actions, onError }))
export const walletConnectV1Connection: Connection = {
  name: 'WalletConnect V1',
  icon: IconWalletConnect,
  connector: web3WalletConnectV1,
  hooks: web3WalletConnectV1Hooks,
  type: ConnectionType.WalletConnectV1,
  shouldDisplay: (bp, client) => {
    return client.isDesktop || bp.isMobile || client.isImTokenApp ? true : false
  },
}

const [web3WalletConnectV2, web3WalletConnectV2Hooks] = initializeConnector<
  WalletConnectV2
>((actions) => new WalletConnectV2({ actions, defaultChainId, onError }))
export const walletConnectV2Connection: Connection = {
  name: 'WalletConnect',
  icon: IconWalletConnect,
  connector: web3WalletConnectV2,
  hooks: web3WalletConnectV2Hooks,
  type: ConnectionType.WalletConnectV2,
  shouldDisplay: (bp, client) => {
    if (client.isImTokenApp) return false
    return client.isDesktop || bp.isMobile ? true : false
  },
  activate: async (
    connector: WalletConnect,
    dispatch: Dispatch,
    chainId: number,
  ) => {
    // A quick fix for dynamically configure requiredNamespaces based on user-selected chainId
    // https://github.com/Uniswap/web3-react/issues/867#issuecomment-1657126317
    // @ts-ignore
    connector.chains = [chainId]
    await connector.activate(chainId)
  },
}

// imToken WalletConnect V2 with qrcode
const [imTokenWalletConnect, imTokenWalletConnectHooks] = initializeConnector<
  WalletConnectV2
>(
  (actions) =>
    new WalletConnectV2({ actions, defaultChainId, onError, qrcode: false }),
)

export const imTokenWalletConnectConnection: Connection = {
  name: 'imToken App',
  icon: IconImToken,
  shouldDisplay: (_bp, client) => {
    return client.isDesktop
  },
  connector: imTokenWalletConnect,
  hooks: imTokenWalletConnectHooks,
  type: ConnectionType.ImTokenWalletConnect,
  activate: async (
    connector: WalletConnect,
    dispatch: Dispatch,
    chainId: number,
  ) => {
    // A quick fix for dynamically configure requiredNamespaces based on user-selected chainId
    // https://github.com/Uniswap/web3-react/issues/867#issuecomment-1657126317
    // @ts-ignore
    connector.chains = [chainId]
    connector.events.on(URI_AVAILABLE, (uri: string) => {
      CustomQRCodeModal.open(uri)
      connector.provider?.on('disconnect', () => {
        dispatch(updateSelectedConnectionType(null))
      })
      connector.provider?.on('connect', () => {
        CustomQRCodeModal.close()
      })
    })
    await connector.activate(chainId)
  },
}

// imToken WalletConnect V2 with call app
const [imTokenCallApp, imTokenCallAppHooks] = initializeConnector<
  WalletConnectV2
>(
  (actions) =>
    new WalletConnectV2({ actions, defaultChainId, onError, qrcode: false }),
)

export const imTokenCallAppConnection: Connection = {
  name: 'imToken App',
  icon: IconImToken,
  connector: imTokenCallApp,
  hooks: imTokenCallAppHooks,
  type: ConnectionType.ImTokenCallApp,
  shouldDisplay: (bp, client) => {
    return bp.isMobile && !client.isImTokenApp ? true : false
  },
  activate: async (
    connector: WalletConnect,
    dispatch: Dispatch,
    chainId: number,
  ) => {
    // A quick fix for dynamically configure requiredNamespaces based on user-selected chainId
    // https://github.com/Uniswap/web3-react/issues/867#issuecomment-1657126317
    // @ts-ignore
    connector.chains = [chainId]
    connector.events.on(URI_AVAILABLE, (uri: string) => {
      console.log('open imToken wc2 uri', uri)
      window.open(`imtokenv2://wc?uri=${encodeURIComponent(uri)}`, '_blank')
      connector.provider?.on('disconnect', () => {
        dispatch(updateSelectedConnectionType(null))
      })
      connector.provider?.on('connect', () => {
        CustomQRCodeModal.close()
      })
    })
    await connector.activate(chainId)
  },
}

// Coinbase Wallet
const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector<
  CoinbaseWallet
>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: rpcMap[1],
        appName: 'Tokenlon',
        appLogoUrl: `${host.href}images/favicon.png`,
      },
      onError,
    }),
)
export const coinbaseWalletConnection: Connection = {
  name: 'Coinbase Wallet',
  icon: IconCoinBase,
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.Coinbase,
  shouldDisplay: (bp, client) => {
    if (client.isImTokenApp) return false
    return client.isDesktop || bp.isMobile ? true : false
  },
}

// // Unstoppable Domains Connection
const [unstoppableDomain, unstoppableDomainHooks] = initializeConnector<
  // @ts-ignore @uauth/web3-react not upgrade to latest version of web3-react
  UAuthConnector
>(
  (actions) =>
    new UAuthConnector({
      actions,
      options: {
        clientID: CLIENT_ID[RUNTIME_ENV],
        clientSecret: ClIENT_SECRET[RUNTIME_ENV],
        redirectUri: `${host.origin}uauth/callback`,
        scope: 'openid wallet',
        connectors: {
          injected: injectedConnection[0],
          walletconnect: web3WalletConnectV1[0],
        },
        // shouldLoginWithRedirect: true,
      },
      onError,
    }),
)
export const unstoppableDomainConnection: Connection = {
  name: 'Unstoppable Domains',
  type: ConnectionType.UnstoppableDomain,
  connector: unstoppableDomain[0],
  hooks: unstoppableDomainHooks,
  icon: IconUnstoppableDomain,
  shouldDisplay: (bp, client) => {
    return client.isDesktop || bp.isMobile || client.isImTokenApp ? true : false
  },
}

// imKey
const imKeyProvider = new ImKeyConnect({
  headers: { agent: 'ios:2.9.11.1539:6', deviceToken: 'test1234' },
  language: typeof window === 'undefined' ? 'en' : navigator.languages[0],
  chainId: ENV.production || ENV.staging ? Mainnet.chainId : Sepolia.chainId,
  rpcUrl: Mainnet.rpcUrl,
})

const [imKey, imKeyHooks] = initializeConnector(
  (actions) =>
    new EIP1193({
      actions,
      provider: imKeyProvider,
    }),
)
export const imKeyConnection: Connection = {
  name: 'imKey',
  icon: IconImKey,
  connector: imKey,
  hooks: imKeyHooks,
  type: ConnectionType.ImKey,
  shouldDisplay: (_bp, client) => {
    return client.isDesktop ? true : false
  },
}

const getReferrer = () => {
  if (typeof document !== 'undefined') {
    return document.referrer
  }
  return null
}

const hotProvider = new HoTProvider({ url: getReferrer() })

const [hot, hotHooks] = initializeConnector(
  (actions) =>
    new HomeOfToken({
      actions,
      provider: hotProvider,
    }),
)
export const hotConnection: Connection = {
  name: 'imToken Web (Beta)',
  icon: IconImToken,
  connector: hot,
  hooks: hotHooks,
  type: ConnectionType.HoT,
  shouldDisplay: () => true,
}

export const SELECTABLE_CONNECTIONS = [
  imTokenWalletConnectConnection,
  imTokenCallAppConnection,
  injectedConnection,
  hotConnection,
  gnosisSafeConnection,
  // walletConnectV1Connection,
  walletConnectV2Connection,
  // unstoppableDomainConnection,
  coinbaseWalletConnection,
  imKeyConnection,
]

export const CONNECTIONS = [networkConnection, ...SELECTABLE_CONNECTIONS]

import dayjs from 'dayjs'
import { TokenlonEnv } from '~/constants/env'
import { API_ROOT_DOMAIN } from '~/constants/rootDomain'
import { RPC } from './rpc'

// @todo 需要讨论这里是用 env 还是 chainId
export const ExchangeEndpoint = {
  [TokenlonEnv.local]: 'https://exchange.imdev.works',
  [TokenlonEnv.development]: 'https://exchange.imdev.works',
  [TokenlonEnv.staging]: 'https://exchange.imstaging.works',
  [TokenlonEnv.production]: 'https://exchange.tokenlon.im',
}

export class ExchangeRPC extends RPC {
  constructor() {
    super({
      envEndpoints: ExchangeEndpoint,
    })
  }

  getMobileAppConfig() {
    return this.rpc<TokenlonConfig>('/rpc', 'tokenlon.getMobileAppConfig')
  }
}

export class MarketRPC extends RPC {
  constructor() {
    super({
      endpoint: `https://api.${API_ROOT_DOMAIN}/v1/market`,
    })
  }

  requestPool = new Map()

  getMarketPrice(
    tokensAddress: string[] = [],
    chainId?: string | number,
    currency: 'USD' | 'CNY' = 'USD',
  ) {
    if (!tokensAddress || !tokensAddress.length) return Promise.resolve([])

    const key = [...tokensAddress, chainId, currency].join()
    const requestInPool = this.requestPool.get(key)

    if (requestInPool) {
      if (requestInPool.isPending) {
        return requestInPool.p
      }
      if (dayjs().diff(requestInPool.timestamp) < 500) {
        return requestInPool.p
      }
      this.requestPool.delete(key)
    }

    const params = tokensAddress.map((address) => {
      return chainId
        ? {
            caip2: `eip155:${chainId}`,
            address,
            showOption: 'always',
          }
        : {
            chainType: 'ETHEREUM',
            showOption: 'always',
            address,
          }
    })
    if (!params.length) return Promise.resolve([])
    const p = this.rpc<MarketPrice[]>('/', 'market.getPrices', params, {
      headers: {
        'X-CURRENCY': currency,
      },
    })
      .then((res) => {
        try {
          const r = this.requestPool.get(key)
          if (r) r.isPending = false
        } catch (error) {
          // ignore
        }
        return res
      })
      .catch((error) => {
        try {
          const r = this.requestPool.get(key)
          if (r) r.isPending = false
        } catch (error) {
          // ignore
        }
        throw error
      })
    this.requestPool.set(key, { isPending: true, p, timestamp: +new Date() })

    return p
  }
}

export const exchangeRpc = new ExchangeRPC()
export const marketRpc = new MarketRPC()

export default exchangeRpc

import { useWeb3React } from '@web3-react/core'
import TokenWebView from '@consenlabs-fe/webview'
import { ImKeyConnect } from '~/connection/ImKey'

export function useIsIMWallet() {
  const { provider } = useWeb3React()
  if (!provider) {
    return false
  }
  const isIMWebView = TokenWebView.isTokenEnv()
  const innerProvider = provider.provider
  // @ts-ignore
  const isIMWalletConnect = innerProvider?.session?.peer?.metadata?.name.includes(
    'imToken',
  )
  // @ts-ignore
  const isImKeyProvider =
    innerProvider instanceof ImKeyConnect &&
    innerProvider?.hasOwnProperty('imKeyResponsiveEmitter') &&
    typeof innerProvider?.imKeyResponsiveEmitter === 'function'
  return isIMWebView || isIMWalletConnect || isImKeyProvider
}
